import React from "react";
import { Button } from "react-bootstrap";

export default function ButtonComponent(props) {
  const style = {
    background: "#222226",
    color: "#FFFFFF",
    fontSize: "10px",
    border: "none",
    height: "25px",
    paddingLeft: "25px",
    paddingRight: "25px",
  };

  const styleLight = {
    background: "#FFFFFF",
    color: "#222226",
    fontSize: "10px",
    border: "none",
    height: "25px",
    paddingLeft: "25px",
    paddingRight: "25px",
    border: "1px solid #222226",
  };
  

  return (
    <Button
      style={props.buttonJson.style !== undefined ? styleLight : style}
      value={props.buttonJson.value}
      onClick={(event) => props?.buttonJson?.handleClick(event)}
      title={props.buttonJson.title || ""}
    >
      {props.buttonJson.label}
    </Button>
  );
}
