import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UserSystemList from './modules/UserSystem/List';
import UserSystemDetail from './modules/UserSystem/Detail';
import UserSystemEdit from './modules/UserSystem/Edit';
import UserList from './modules/User/List';
import UserDetail from './modules/User/Detail';
import UserEdit from './modules/User/Edit';
import UserAddressCreate from './modules/User/Create';
import UserSetting from './modules/User/Setting';
import UserSystemSetting from './modules/UserSystem/Setting';
import BiddingCreate from './modules/Bidding/Create';
import BiddingList from './modules/Bidding/List';
import BiddingDetail from './modules/Bidding/Detail';
import RefundList from './modules/Refund/List';
import RefundQrDetail from './modules/Refund/QrDetail';
import RefundCreditDetail from './modules/Refund/CreditDetail';
import RefundQrView from './modules/Refund/QrView';
import BannerList from './modules/Banner';
import HeroBannerCreate from './modules/Banner/SubModule/Create';
import HeroBannerDetail from './modules/Banner/SubModule/Detail';
import HighlightList from './modules/Highlight/List';
import HighlightCreate from './modules/Highlight/Create';
import HighlightDetail from './modules/Highlight/Detail';
import OrderList from './modules/Order/List';
import OrderDetail from './modules/Order/Detail';
import OrderView from './modules/Order/View';
import MediaList from './modules/MediaLibrary/List'
import MediaDetail from './modules/MediaLibrary/Detail'
import MediaRevise from './modules/MediaLibrary/Revise'
import AccessList from './modules/AccessHistory/List'


import {ProtectedRoute} from './hooks/authDetail';
import SignIn from './modules/Auth/SignIn';
import Dashboard from './modules/Dashboard';
function App() {
  
  return (
    <Router>
      <div className="App">
        <Routes> 
            <Route path="/login" element={
                  <SignIn/> 
              } /> 
            
              <Route path="/" element={
                <ProtectedRoute>
                  <UserSystemList/> 
                </ProtectedRoute>
              } /> 
              <Route path="/user/system/list" element={
                <ProtectedRoute> 
                  <UserSystemList/> 
                </ProtectedRoute>} /> 
              <Route path="/user/system/detail/:id" element={
              <ProtectedRoute>
                <UserSystemDetail/>
              </ProtectedRoute> } /> 
              <Route path="/user/system/edit/:id" element={
              <ProtectedRoute>
                <UserSystemEdit/> 
              </ProtectedRoute>} /> 
              <Route path="/user/list" element={
              <ProtectedRoute>
                <UserList/> 
              </ProtectedRoute>} /> 
              <Route path="/dashboard" element={<ProtectedRoute><Dashboard/></ProtectedRoute>} /> 
              <Route path="/user/detail/:id/:action" element={<ProtectedRoute><UserDetail/></ProtectedRoute>} /> 
              <Route path="/user/edit/:id/" element={<ProtectedRoute><UserEdit/></ProtectedRoute> } />
              <Route path="/user/createAddress/:id" element={<ProtectedRoute><UserAddressCreate/></ProtectedRoute> } />
              <Route path="/user/editAddress/:id/" element={<ProtectedRoute><UserAddressCreate/></ProtectedRoute> } />
              <Route path="/user/setting/:id" element={<ProtectedRoute><UserSetting/></ProtectedRoute> } />  
              <Route path="/user/system/setting/:id" element={<ProtectedRoute><UserSystemSetting/></ProtectedRoute> } /> 
              <Route path="/bidding/create" element={<ProtectedRoute><BiddingCreate/></ProtectedRoute> } /> 
              <Route path="/bidding/list" element={<ProtectedRoute><BiddingList/> </ProtectedRoute>} /> 
              <Route path="/bidding/detail/:productId" element={<ProtectedRoute><BiddingDetail/></ProtectedRoute> } /> 
              <Route path="/bidding/edit/:productId" element={<ProtectedRoute><BiddingCreate/></ProtectedRoute> } /> 
              <Route path="/refund/list" element={<ProtectedRoute><RefundList/> </ProtectedRoute>} />
              <Route path="/refund/qrdetail/:productId" element={<ProtectedRoute><RefundQrDetail/> </ProtectedRoute>} /> 
              <Route path="/refund/creditdetail/:productId" element={<ProtectedRoute><RefundCreditDetail/> </ProtectedRoute>} /> 
              <Route path="/refund/qrview/:productId" element={<ProtectedRoute><RefundQrView/> </ProtectedRoute>} /> 
              <Route path="/banner/list/:action" element={<ProtectedRoute><BannerList/></ProtectedRoute>} /> 
              <Route path="/banner/create/:action" element={<ProtectedRoute><HeroBannerCreate/></ProtectedRoute>} /> 
              <Route path="/banner/create/:action/:bannerId" element={<ProtectedRoute><HeroBannerCreate/></ProtectedRoute>} /> 
              <Route path="/banner/detail/:action/:bannerId" element={<ProtectedRoute><HeroBannerDetail/></ProtectedRoute>} />
              <Route path="/highlight/list" element={<ProtectedRoute><HighlightList/></ProtectedRoute>} />               
              <Route path="/highlight/create" element={<ProtectedRoute><HighlightCreate/></ProtectedRoute>} />      
              <Route path="/highlight/create/:bannerId" element={<ProtectedRoute><HighlightCreate/></ProtectedRoute>} />      
              <Route path="/highlight/detail/:bannerId" element={<ProtectedRoute><HighlightDetail/></ProtectedRoute>} />      
              <Route path="/order/list" element={<ProtectedRoute><OrderList/> </ProtectedRoute>} />    
              <Route path="/order/detail/:orderId" element={<ProtectedRoute><OrderDetail/> </ProtectedRoute>} />   
              <Route path="/order/view/:orderId" element={<ProtectedRoute><OrderView/> </ProtectedRoute>} /> 
              <Route path="/medialibrary/list" element={<ProtectedRoute><MediaList/> </ProtectedRoute>} /> 
              <Route path="/medialibrary/detail/:productId" element={<ProtectedRoute><MediaDetail/> </ProtectedRoute>} /> 
              <Route path="/medialibrary/Revise/:productId" element={<ProtectedRoute><MediaRevise/> </ProtectedRoute>} /> 
              <Route path="/accesshistory/list" element={<ProtectedRoute><AccessList/> </ProtectedRoute>} /> 
        </Routes>
      </div>
    </Router>
  );
}

export default App;
