import React, {useState} from 'react';
import { Form, InputGroup} from 'react-bootstrap';
import ButtonComponent from '../../../components/Button';
import {
    doSignIn
} from '../Services/api';
import {useNavigate} from "react-router-dom";

export default function SignIn() {
    const navigate = useNavigate();

    const [userSignInJson, setUserSignInJson] = useState({
        email : null,
        password : null,
        issystemuser : "Y",
        dataError : null
    });

    function handleInputChange(event)
    {
        const key = event.target.name;
        let value = event.target.value;
        const type = event.target.type;
        if(type === 'checkbox')
        {
            value = event.target.checked;
        }

        setUserSignInJson(
            {
                ...userSignInJson,
                [key] : value
            }
        );
    }

    function handleButtonClick()
    {
        const reqParam = userSignInJson;

        doSignIn(reqParam)
            .then((res) => 
            { 
                if(res.data.status === 'SUCCESS')
                {
                   
                    sessionStorage.setItem('isLoggedIn', true);
                    sessionStorage.setItem('userDetail', JSON.stringify({userId : res.data.response.userId}));
                    sessionStorage.setItem('userName', JSON.stringify(res.data.response.name));
                    
                    navigate("/dashboard"); 
                } 
                else{
                    setUserSignInJson(
                        {
                            ...userSignInJson,
                            dataError : "Email or Password is incorrect"
                        }
                    );
                }        
            })
            .catch((err) => 
            {
                setUserSignInJson(
                    {
                        ...userSignInJson,
                        dataError : "Email or Password is incorrect"
                    }
                );
            });
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleButtonClick();
        }
    };
    return (
        <div className='container-fluid'>
            <div style={{margin: '0',
            padding: '0',
            width: '100%',
            height: '100vh',
            display: 'table'}}>
                <div style={{textAlign: 'center',
                verticalAlign: 'middle',
                display: 'table-cell'}}>
                    <div className='row justify-content-center padding10'>
                        <div className='col-lg-4 col-sm-12 boxBorder padding20'>
                            <div className='row textAlignLeft paddingTop20'>                    
                                <div>
                                    อีเมล
                                </div>
                                <div>
                                    <InputGroup>
                                        <Form.Control
                                        placeholder="กรอกอีเมล"
                                        aria-label="กรอกอีเมล"
                                        aria-describedby="basic-addon1"
                                        name='email'
                                        onKeyDown={handleKeyDown}
                                        onChange={handleInputChange}
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                            <div className='row textAlignLeft paddingTop20'>                    
                                <div>
                                    รหัสผ่าน
                                </div>
                                <div>
                                    <InputGroup>
                                        <Form.Control
                                        placeholder="ใส่รหัสผ่าน"
                                        aria-label="ใส่รหัสผ่าน"
                                        aria-describedby="basic-addon1"
                                        name='password'
                                        onKeyDown={handleKeyDown}
                                        onChange={handleInputChange}
                                        type='password'
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                            <div className='row textAlignLeft paddingTop20'>                    
                                <div style={{color: 'red'}}>
                                    {userSignInJson.dataError}
                                </div>
                            </div>
                            <div className='row paddingTop20'>                    
                                <div>
                                    <ButtonComponent buttonJson={{
                                            label : "เข้าสู่ระบบ",
                                            handleClick : handleButtonClick
                                    }}></ButtonComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>               
    );
}