import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideNavbar from "../../../layouts/SideNavbar";
import ButtonComponent from "../../../components/Button";
import { Form, InputGroup } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useParams } from "react-router";
import { Table } from "react-bootstrap";
import { getRefundDetail } from "../Services/api";
import { formatDate } from "../../../Utils/DateUtils";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../../../components/Modal";
import 
{
    getInputDate
} from '../../../Utils/DateUtils';

export default function Detail() {
  const style = {
    background: "#FFFFFF",
    color: "#222226",
    fontSize: "14px",
    border: "none",
    height: "40px",
    paddingLeft: "25px",
    paddingRight: "25px",
    border: "1px solid #222226",
    width: "190px",
    marginLeft: "20px",
    marginBottom: "10px",
  };
  const dropdownStyle = {
    border: "none",
    padding: "9px",
    width: "100%",
  };

  const svgStyle = {
    marginRight: "30px", // Add some space between SVG and text
  };
  const navigate = useNavigate();

  const { productId } = useParams();

  const [refunDetailJson, setRefundDetailJson] = useState({
    productDetail: {
      fileList: [],
    },
  });
  const [showModalOne, setShowModalOne] = useState(false);

  const handleButtonOneClick = () => {
    setShowModalOne(true);
  };

  const handleCloseModalOne = () => {
    setShowModalOne(false);
  };

  useEffect(() => {
    getRefundDetailWrapper(productId);
  }, [productId]);

  function getRefundDetailWrapper(id) {
    getRefundDetail(id)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setRefundDetailJson({
            ...refunDetailJson,
            productDetail: res.data.response,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleButtonClick() {
    navigate("/bidding/edit/" + productId);
  }
  let modalBodytracking = (
    <div className={"container-fluid"}>
      <div className={"row sidePadding0 paddingTop20"}>
        <div className={"col-lg paddingBottom5"}>
          <span>*</span>
          <span>วันที่</span>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-lg"}>
          <select className="col-2 me-5 rounded " style={dropdownStyle}>
            <option value="" disabled selected hidden>
              เลือกวันที่ทำการคืนเงิน
            </option>
            <option value="action-1">Action 1</option>
            <option value="action-2">Action 2</option>
            <option value="action-3">Action 3</option>
          </select>
        </div>
      </div>
      <div className={"row sidePadding0 paddingTop20"}>
        <div className={"col-lg paddingBottom5"}>
          <span>*</span>
          <span>Ref No.</span>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-lg"}>
          <InputGroup>
            <Form.Control
              type="number"
              placeholder="ระบุเลชอ้างอิง"
              aria-label="ระบุเลชอ้างอิง"
              aria-describedby="basic-addon1"
              name="newPassword"
              onChange=""
            />
          </InputGroup>
        </div>
      </div>
      <div className="d-flex col justify-content-center gap-3 mt-5 ">
        <ButtonComponent
          buttonJson={{
            label: "ยกเลิก",
            handleClick: handleButtonClick,
            style: "edit",
          }}
        ></ButtonComponent>
        <ButtonComponent
          buttonJson={{
            label: "บันทึก",
            handleClick: handleButtonClick,
          }}
        ></ButtonComponent>
      </div>

      <div className={"row paddingTop10"} style={{ color: "red" }}>
        {}
      </div>
      <div className={"row paddingTop10"}>{}</div>
    </div>
  );
  const handleBreadcrumbClick = () => {
    // Go back to the previous page
    window.history.back();
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 p-0"  style={{width: "240px"}} >
          <SideNavbar code="refundlist" />
        </div>
        <div className="col-9" style={{width:'1080px'}}>
          <Row>
            <Col style={{ padding: "15px" }}>
              <div className="headerTitleDiv headerFont">
                รายละเอียดการคืนเงิน
              </div>
              <div className="headerActionButtonDiv"></div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="row paddingBottom20">
                <div className="col-lg textAlignLeft font12Light cursor-pointer"  onClick={handleBreadcrumbClick}>
                <span style={{ opacity:'0.5'}}> รายชื่อสมาชิกผู้ใช้งานเว็บไซต์ {">"}</span>รายละเอียดการคืนเงิน
                </div>
              </div>
              <div
                className="container-fluid"
                style={{ border: "1px solid #B3B5BD" }}
              >
                <div
                  className={"row textAlignLeft paddingTopBottom10"}
                  style={{ borderBottom: "1px solid #B3B5BD" }}
                >
                  <div className={"col-lg font16Light"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M21.75 5.25V19.5C21.7499 19.7599 21.6152 20.0012 21.394 20.1378C21.1729 20.2743 20.8968 20.2866 20.6644 20.1703L18 18.8381L15.3356 20.1703C15.1244 20.276 14.8756 20.276 14.6644 20.1703L12 18.8381L9.33563 20.1703C9.12436 20.276 8.87564 20.276 8.66437 20.1703L6 18.8381L3.33563 20.1703C3.1032 20.2866 2.82712 20.2743 2.60597 20.1378C2.38483 20.0012 2.25014 19.7599 2.25 19.5V5.25C2.25 4.42157 2.92157 3.75 3.75 3.75H20.25C21.0784 3.75 21.75 4.42157 21.75 5.25ZM7.5 9C7.08579 9 6.75 9.33579 6.75 9.75C6.75 10.1642 7.08579 10.5 7.5 10.5H16.5C16.9142 10.5 17.25 10.1642 17.25 9.75C17.25 9.33579 16.9142 9 16.5 9H7.5ZM16.5 13.5H7.5C7.08579 13.5 6.75 13.1642 6.75 12.75C6.75 12.3358 7.08579 12 7.5 12H16.5C16.9142 12 17.25 12.3358 17.25 12.75C17.25 13.1642 16.9142 13.5 16.5 13.5ZM3.75 5.25H20.25V18.2869L18.3356 17.3287C18.1244 17.223 17.8756 17.223 17.6644 17.3287L15 18.6619L12.3356 17.3287C12.1244 17.223 11.8756 17.223 11.6644 17.3287L9 18.6619L6.33562 17.3287C6.12436 17.223 5.87564 17.223 5.66437 17.3287L3.75 18.2869V5.25Z"
                        fill="#222226"
                      />
                    </svg>
                    <span style={{ paddingLeft: "10px" }}>
                      รายการละเอียดการประมูล
                    </span>
                  </div>
                </div>
                <div
                  className={
                    "row font14Light paddingLeftRight10 paddingTopBottom20"
                  }
                >
                  <div className={"col-lg-2"}>
                    <div className={"row"}>
                      <div className={"mb-3"}>
                        <img
                          className={"p-0 m-0"}
                          style={{ width: "120px", height: "120px" }}
                          src={
                            
                            refunDetailJson?.productDetail?.[0]?.filepath
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className={"col-lg-2"} style={{ textAlign: "left" }}>
                    <div className={"row"}>
                      <div className="appFontLight">รหัสสินค้า</div>
                      <div className="subHeaderFont paddingTop5">
                        {refunDetailJson?.productDetail[0]?.product_id}
                      </div>
                    </div>

                    <div className={"row paddingTop15"}>
                      <div className="appFontLight">ยอดมัดจำ</div>
                      <div className={"subHeaderFont paddingTop5"}>
                        {
                          refunDetailJson?.productDetail[0]
                            ?.productdepositamount
                        }
                      </div>
                    </div>
                  </div>
                  <div className={"col-lg-2"} style={{ textAlign: "left" }}>
                    <div className={"row"}>
                      <div className="appFontLight">Running No</div>
                      <div className={"subHeaderFont paddingTop5"}>
                        {refunDetailJson?.productDetail[0]?.runningno}
                      </div>
                    </div>

                    <div className={"row paddingTop15"}>
                      <div className="appFontLight">ช่องทางการชำระเงิน</div>
                      <div className={"subHeaderFont paddingTop5"}>
                        {refunDetailJson?.productDetail[0]?.payment_mode}
                      </div>
                    </div>
                  </div>
                  <div className={"col-lg "} style={{ textAlign: "left" }}>
                    <div className={"row"}>
                      <div className="appFontLight">ชื่อสินค้า</div>
                      <div className={"subHeaderFont paddingTop5"}>
                        {refunDetailJson?.productDetail[0]?.productname}
                      </div>
                    </div>

                    <div className={"row paddingTop15"}>
                      <div className={"col"}>
                        <div className={"row"}>
                          <div className="appFontLight">Ref No.</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {refunDetailJson?.productDetail[0]?.reference_id}
                          </div>
                        </div>
                      </div>
                      <div className={"col"}>
                        <div className={"row"}>
                          <div className="appFontLight">วันที่</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {getInputDate (refunDetailJson?.productDetail[0]?.refund_date)}
                          </div>
                        </div>
                      </div>
                      <div className={"col"}>
                        <div className={"row"}>
                          <div className="appFontLight">การคืนเงิน</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            <div
                              className={"subHeaderFont paddingTop5"}
                              style={{ backgroundColor: "#F36D2733" }}
                            >
                              รอดำเนินการ OMISE
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={"row textAlignLeft paddingTopBottom10"}
                    style={{ borderTop: "3px solid #B3B5BD" }}
                  >
                    <div className={"row textAlignLeft paddingTopBottom10"}>
                      <div className={"col"} style={{ textAlign: "left" }}>
                        <div className={"row paddingTop15"}>
                          <div className="appFontLight">สถานะ</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {refunDetailJson?.productDetail[0]?.status}
                          </div>
                        </div>
                      </div>

                      <div className={"col"} style={{ textAlign: "left" }}>
                        <div className={"row paddingTop15"}>
                          <div className="appFontLight">
                            ชื่อ-นามสกุลผู้เข้าร่วมประมูล
                          </div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {refunDetailJson?.productDetail[0]?.lastname}{" "}
                            {refunDetailJson?.productDetail[0]?.lastname}
                          </div>
                        </div>
                      </div>

                      <div className={"col"} style={{ textAlign: "left" }}>
                        <div className={"row paddingTop15"}>
                          <div className="appFontLight">เบอร์ติดต่อ</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {refunDetailJson?.productDetail[0]?.contactnumber}
                          </div>
                        </div>
                      </div>

                      <div className={"col"} style={{ textAlign: "left" }}>
                        <div className={"row paddingTop15"}>
                          <div className="appFontLight">อีเมล์</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {refunDetailJson?.productDetail[0]?.email}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
