import apiInterceptor from "../../../hooks/apiInterceptor";

export function createProduct(reqParam) {
  return apiInterceptor
    .post("/bidding/product/create", reqParam)
}

export function updateProduct(reqParam) {
  return apiInterceptor
    .post("/bidding/product/update", reqParam)
}

export function deleteProductFile(reqParam) {
  return apiInterceptor
    .post("/media/file/info/delete", reqParam)
}
export function saveBannerFileInfo(reqParam) {
  return apiInterceptor
    .post("/media/file/info/create", reqParam)
}


export function getBiddingProductList() {
  return apiInterceptor
    .get("/bidding/product/list", {
      // params: queryParams
    })
}

export function getBiddingProductDetail(productId) {
  return apiInterceptor
    .get("/bidding/product/detail/"+productId, {
      // params: queryParams
    })
}

export function getMediaList() {
    
  return apiInterceptor
    .get("/media/list", {
    
    })
}
