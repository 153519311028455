import apiInterceptor from "../../../hooks/apiInterceptor";


export function getOrderProductList() {
  return apiInterceptor
    .get("order/list", {
    
    })
}

export function getOrderProductDetail(orderId) {
  return apiInterceptor
    .get("/order/detail/"+ orderId, {
    })
}

export function updateTrackingProduct(reqParam) {
  return apiInterceptor
    .put("/order/shipping/update", reqParam)
}

export function updateUserAddress(reqParam) {
  return apiInterceptor
    .put("/user/address/update", reqParam)
}
