import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Form, InputGroup} from 'react-bootstrap';
import SideNavbar from '../../../layouts/SideNavbar';
import ButtonComponent from '../../../components/Button';
import ModalComponent from '../../../components/Modal';
import { useParams } from 'react-router';
import {getUserById, updatePassword} from '../../UserSystem/Services/api';

export default function Setting() {
    const { id } = useParams();

    const [userSettingJson, setUserSettingJson] = useState({
        password : '',
        dataError : ''
    }); 

    useEffect(() => {
        getUserByIdWrapper(id);
    }, [id]);

    function getUserByIdWrapper(id)
    {
        getUserById(id)
        .then((res) => 
          { 
            if(res.data.status === 'SUCCESS')
            {
                setUserSettingJson({
                    ...res.data.response,
                    'dataError' : ''
                });
            }     
          })
          .catch((err) => 
          {
            console.log(err);
          });
    }

    const [buttonJson, setButtonJson] = useState({
        label : "เพิ่มผู้ใช้งาน",
        handleClick : handleButtonClick
    }); 

    let modalFooter = 
       (<div className={'container-fluid'}>
            <div style={{textAlign:'center'}} className={'row p-0'}>
                <div className={'col-lg paddingBottom5'} style={{marginTop:'34px',marginBottom:'51px'}}>
                    <button
                        onClick={handleButtonClose}
                            style={{
                            width:'136px',
                            height:'40px',
                            fontWeight:400,
                            fontSize: "16px",
                            padding: "8px 16px",
                            cursor: "pointer",
                            color: "#222226",
                            border:"1px solid #222226",
                            borderRadius:'4px',
                            background: "#FFFFFF",
                            }}
                        >
                        ยกเลิก
                    </button>
                    <span style={{paddingRight:'15px'}}>

                    </span>
                    <button
                            onClick={handleButtonSave}                            
                            style={{
                            width:'136px',
                            height:'40px',
                            fontWeight:400,
                            fontSize: "16px",
                            padding: "8px 16px",
                            cursor: "pointer",
                            color: "#FFFFFF",
                            border:"1px solid #222226",
                            borderRadius:'4px',
                            background: "#222226",
                            }}
                        >
                        บันทึก
                    </button>
                </div>
            
             {/*   <div className={'col-lg paddingBottom5'}>
                    <ButtonComponent buttonJson={{
                        label : "ยกเลิก",
                        handleClick : handleButtonClose
                    }}>
                    </ButtonComponent>
                    <span style={{paddingRight:'15px'}}>

                    </span>
                    <ButtonComponent buttonJson={{
                        label : "บันทึก",
                        handleClick : handleButtonSave
                    }}>

                    </ButtonComponent>
                </div>*/}
            </div>
        </div>
        );

    let modalBody = 
       (<div className={'container-fluid'}>
            <div className={'row sidePadding0 paddingTop20'}>
                <div className={'col-lg paddingBottom5'}>
                    <span style={{color:'red'}}>
                        *
                    </span>
                    <span>
                        Password เดิม
                    </span>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-lg'}>
                    <InputGroup>
                        <Form.Control
                        style={{fontSize:'12px'}}
                        placeholder="ระบุรหัสผ่านเข้าใช้งานเดิม"
                        aria-label="ระบุรหัสผ่านเข้าใช้งานเดิม"
                        aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                </div>
            </div>
            <div className={'row sidePadding0 paddingTop20'}>
                <div className={'col-lg paddingBottom5'}>
                    <span style={{color:'red'}}>
                        *
                    </span>
                    <span>
                        Password ใหม่
                    </span>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-lg'}>
                    <InputGroup>
                        <Form.Control
                        style={{fontSize:'12px'}}
                        placeholder="ระบุรหัสผ่านเข้าใช้งานใหม่"
                        aria-label="ระบุรหัสผ่านเข้าใช้งานใหม่"
                        aria-describedby="basic-addon1"
                        name='newPassword'
                        onChange={handleInputChange}
                        />
                    </InputGroup>
                </div>
            </div>
            <div className={'row sidePadding0 paddingTop20'}>
                <div className={'col-lg paddingBottom5'}>
                    <span style={{color:'red'}}>
                        *
                    </span>
                    <span>
                        Password ใหม่ซ้ำ
                    </span>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-lg'}>
                    <InputGroup>
                        <Form.Control
                        style={{fontSize:'12px'}}
                        placeholder="ระบุรหัสผ่านเข้าใช้งานเพื่อยืนยัน"
                        aria-label="ระบุรหัสผ่านเข้าใช้งานเพื่อยืนยัน"
                        aria-describedby="basic-addon1"
                        name='confirmPassword'
                        onChange={handleInputChange}
                        />
                    </InputGroup>
                </div>
            </div>
            <div className={'row paddingTop10'} style={{color:'red'}}>
                {userSettingJson.dataError}
            </div>
            <div className={'row paddingTop10'}>
                {modalFooter}
            </div>
        </div>
        );

        const [modalJson, setModalJson] = useState({
            show : false,
            handleClose : handleButtonClose,
            title : 'เปลี่ยนรหัสผ่าน',
        }); 

    function handleInputChange(event)
    {
        setUserSettingJson({
            ...userSettingJson,
            'password' : event.target.value
        });
    }

    function handleButtonClose()
    {
        setModalJson({
            ...modalJson,
            show : false
        });
        
    }

    function handleButtonClick()
    {
        setUserSettingJson({
            ...userSettingJson,
            'dataError' : '',
            password : ''
        });

        setModalJson({
            ...modalJson,
            show : true
        });
    }
    
    function handleButtonSave()
    {

        if(userSettingJson.password === undefined || userSettingJson.password === '')
        {
            setUserSettingJson({
                ...userSettingJson,
                dataError : 'Mandatory fields missing'
            });
            return;
        }

        const reqParam = {
            id : id,
            password : userSettingJson.password
        };

        updatePassword(reqParam)
        .then((res) => 
          { 
            console.log("Success");        
          })
          .catch((err) => 
          {
            console.log(err);
          });

        setModalJson({
            ...modalJson,
            show : false
        });
    }


    return (
        <React.Fragment>
            <div className='container-fluid'>
                <ModalComponent modalJson={modalJson}>
                   {modalBody}
                </ModalComponent>
                <div className='row'>
                <div className="col-2 p-0"  style={{width: "240px"}} >
                    <SideNavbar code="userSystemSetting" />
                </div> 
                <div className="col-9" style={{width:'1080px'}}>
                    <Row>
                        <Col style={{padding : '15px'}}>
                            <div className='headerTitleDiv headerFont'>
                                ข้อมูลบัญชีผู้ใช้งาน
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='container-fluid' style={{border : '1px solid #B3B5BD'}}>
                                <div className={'row textAlignLeft paddingTopBottom10'} style={{borderBottom:'1px solid #B3B5BD'}}>
                                    <div className={'col-lg font16Light'}>
                                        <div className='ms-3'>
                                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.75 2.25V15.75C19.75 16.5784 19.0784 17.25 18.25 17.25H1.75C0.921573 17.25 0.25 16.5784 0.25 15.75V2.25C0.25 1.42157 0.921573 0.75 1.75 0.75H18.25C19.0784 0.75 19.75 1.42157 19.75 2.25ZM16 8.25C16.4142 8.25 16.75 7.91421 16.75 7.5C16.75 7.08579 16.4142 6.75 16 6.75H12.25C11.8358 6.75 11.5 7.08579 11.5 7.5C11.5 7.91421 11.8358 8.25 12.25 8.25H16ZM12.25 9.75H16C16.4142 9.75 16.75 10.0858 16.75 10.5C16.75 10.9142 16.4142 11.25 16 11.25H12.25C11.8358 11.25 11.5 10.9142 11.5 10.5C11.5 10.0858 11.8358 9.75 12.25 9.75ZM18.25 2.25V15.75H1.75V2.25H18.25ZM10.7256 12.5625C10.8292 12.9638 10.5878 13.373 10.1866 13.4766C9.78529 13.5801 9.37605 13.3388 9.2725 12.9375C9.02594 11.9756 8.04812 11.25 6.99906 11.25C5.95 11.25 4.97312 11.9756 4.72563 12.9375C4.62207 13.3388 4.21283 13.5801 3.81156 13.4766C3.41029 13.373 3.16895 12.9638 3.2725 12.5625C3.51588 11.6566 4.09118 10.8755 4.88406 10.3744C4.02237 9.51779 3.76242 8.22604 4.22572 7.10283C4.68901 5.97961 5.78405 5.24675 6.99906 5.24675C8.21407 5.24675 9.30911 5.97961 9.77241 7.10283C10.2357 8.22604 9.97576 9.51779 9.11406 10.3744C9.90781 10.8748 10.4836 11.6562 10.7266 12.5625H10.7256ZM8.5 8.25C8.5 9.07843 7.82843 9.75 7 9.75C6.17157 9.75 5.5 9.07843 5.5 8.25C5.5 7.42157 6.17157 6.75 7 6.75C7.82843 6.75 8.5 7.42157 8.5 8.25Z" fill="#222226"/>
                                            </svg>
                                            <span style={{paddingLeft:'10px'}}>ข้อมูลบัญชี</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={'row textAlignLeft paddingLeftRight10 paddingTopBottom20 ms-3'}>
                                    <div className={'col-lg '}>
                                        <div className={'row font12Light'} style={{opacity:'0.5'}}>
                                            ชื่อ-นามสกุล
                                        </div>
                                        <div className={'row font16Light'}>
                                            {userSettingJson.name} {userSettingJson.lastname}
                                        </div>
                                    </div>
                                    <div className={'col-lg '}>
                                        <div className={'row font12Light'}  style={{opacity:'0.5'}}>
                                            Username
                                        </div>
                                        <div className={'row font16Light'}>
                                            {userSettingJson.username}
                                        </div>
                                    </div>
                                    <div className={'col-lg '}>
                                        <div className={'row font12Light'}  style={{opacity:'0.5'}}>
                                            กลุ่มผู้ใช้งาน
                                        </div>
                                        <div className={'row font16Light'}>
                                        {userSettingJson.usergroupname}
                                        </div>
                                    </div>
                                    <div className={'col-lg '}>
                                        <div className={'row font12Light'}>
                                          {/*  <ButtonComponent buttonJson={
                                                {
                                                    label : "เปลี่ยนรหัสผ่าน",
                                                    handleClick : handleButtonClick
                                                }
                                            } /> */}
                                            <button
                                                onClick={handleButtonClick}                            
                                                style={{
                                                width:'136px',
                                                height:'32px',
                                                fontWeight:400,
                                                fontSize: "16px",
                                                padding: "4px 16px",
                                                cursor: "pointer",
                                                color: "#FFFFFF",
                                                border:"1px solid #222226",
                                                borderRadius:'4px',
                                                background: "#222226",
                                                }}
                                            >
                                            เปลี่ยนรหัสผ่าน
                                            </button>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div> 
                </div>
            </div>  
        </React.Fragment>
             
    );
  }