import React, { useState, useEffect } from 'react';
import SideNavbar from '../../layouts/SideNavbar';
import {Row, Col} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import { useParams } from 'react-router';
import ButtonComponent from '../../components/Button';
import ListLayout from './SubModule/List';

export default function Detail() {
    const navigate = useNavigate();
    const { action } = useParams();

    const [bannerJson, setBannerJson] = useState({
        'activeSubMenu' : 'hero'
    });

    useEffect(() => {
        setBannerJson(
            {
                ...bannerJson,
                activeSubMenu : action
            }
        );
    }, [action]);

    const [subMenuList, setSubMenuList] = useState([
        {
            'title' : 'วิดีโอหน้าแรก',
            'itemCode' : 'hero'
        },
        {
            'title' : 'สไลด์โชว์หน้าแรก',
            'itemCode' : 'slide'
        },
        {
            'title' : 'ข้อความประกาศ',
            'itemCode' : 'announcement'
        }
    ]);


    function handleButtonClick()
    {
        navigate("/banner/create/"+action);
    }

    function navigateToLink(actionCode)
    {
        if(actionCode === '')
        {
            return;
        }

        setBannerJson(
            {
                ...bannerJson,
                activeSubMenu : actionCode
            }
        );

        navigate('/banner/list/'+actionCode);
    }

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className="col-2 p-0"  style={{width: "240px"}} >
                    <SideNavbar code="bannerList" />
                </div> 
                <div className="col-9" style={{width:'1080px'}}>
                    <Row>
                        <Col lg={9} sm={12} style={{padding : '15px'}}>
                            <div className='headerTitleDiv headerFont'>
                                    จัดการข้อมูลหน้าแรก
                            </div>
                        </Col>
                        <Col lg={3} sm={12} style={{padding : '15px'}}>
                        <button
                            style={{
                                float:'right',
                                width:'136px',
                                fontWeight:400,
                                height: '40px',
                                borderRadius:'4px', 
                                background: '#222226', 
                                color: '#FFFFFF', 
                                fontSize: '16px', 
                                border: 'none',
                                overflow:'hidden'
                            }}
                            onClick={handleButtonClick}
                        >
                            เพิ่มข้อมูลใหม่
                        </button>
                        </Col>
                    </Row>
                    <Row>
                    <Col lg={2} className='p-0' style={{ width: '169px',overflow:'hidden' }}>
                    <div style={{ height: '100vh', textAlign: 'left', background: '#EFF0F1', display: 'flex', flexDirection: 'column' }}>
                      {subMenuList.map((data, idx) => (
                        <div key={idx} className={'pointer'} style={{ marginTop:'20px',marginLeft:'20px',width: '131px'}} onClick={() => navigateToLink(data.itemCode)}>
                          <div 
                            style={{ 
                                height: "24px", 
                                flexWrap: 'nowrap', 
                                overflow: 'hidden', 
                                textOverflow: 'ellipsis', 
                                whiteSpace: 'nowrap',
                            }} 
                            className={(data.itemCode === bannerJson.activeSubMenu ? 'menuActive' : '')}>
                            <div 
                                style={{
                                fontSize: '12px', 
                                fontWeight: 600, 
                                marginTop:'4px',
                                marginLeft:'11px',
                                marginRight:'10px'
                                }}
                            >
                            {data.title}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    </Col>
                  
                        <Col lg={10}>
                            <ListLayout action={action}/>
                        {/* {action === 'address'  &&
                            <AddressLayout userId={id} />
                        }  
                        {action === 'history'  &&
                            <AuctionHistoryLayout userId={id} />
                        } 
                        {action === 'fav'  &&
                            <FavouriteLayout userId={id} />
                        }    */}
                        </Col>
                    </Row>
                </div> 
            </div>
        </div>               
    );
}