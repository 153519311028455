import apiInterceptor from "../../../hooks/apiInterceptor";

  export function getUserGroups() {
    return apiInterceptor
      .get("/user/group/list", {
        // params: queryParams
      })
  }

  export function getUserListByGroupId(id) {
    return apiInterceptor
      .get("/userGroup/user/get/"+id, {
        // params: queryParams
      })
  }

  
  export function createUser(reqParam) {
    return apiInterceptor
      .post("/user/createSystem", reqParam)
  }

  export function updatePassword(reqParam) {
    return apiInterceptor
      .post("/user/admin/password/reset", reqParam)
  }

  export function updateUserDetail(reqParam) {
    return apiInterceptor
      .post("/user/update/detail", reqParam)
  }

  export function getUserById(id) {
    return apiInterceptor
      .get("/user/get/"+id, {
        // params: queryParams
      })
  }