import React, {useEffect, useState, } from 'react';
import {useNavigate, useParams, } from "react-router-dom";
import SideNavbar from '../../../../layouts/SideNavbar';
import {Row, Col} from 'react-bootstrap';
import ButtonComponent from '../../../../components/Button';
import '../../index.css';
import {
    getBannerDetail
} from '../../Services/api';
import 
{
    getInputDate
} from '../../../../Utils/DateUtils';
import ReactPlayer from 'react-player';

export default function Detail() {
    const navigate = useNavigate();
    const { action, bannerId } = useParams();

    const [bannerDetailJson, setBannerDetailJson] = useState({
        dataError : 'Error.. Try again',
        bannerJson : {
            filelist : []
        }
    });

    useEffect(() => {
        getBannerDetailWrapper(bannerId);
    }, [bannerId]);

    function getBannerDetailWrapper(bannerId)
    {
        var reqParam = {};
        reqParam.bannerId = bannerId;
        getBannerDetail(reqParam)
        .then((res) => 
        {   
            if(res.data.status === 'SUCCESS')
            {
                setBannerDetailJson({
                    ...bannerDetailJson,
                    bannerJson : res.data.response
                });       
            }
        })
        .catch((err) => 
        {
            console.log("Error");               
        });
    }

    function handleButtonClick()
    {
        navigate("/banner/create/"+action+"/"+bannerId);
    }    

    function handleButtonClose()
    {

    } 
     
      
    const handleBreadcrumbClick = () => {
        // Go back to the previous page
      window.history.back();
    };

    return (
        <div className='container-fluid' style={{marginBottom : '20px'}}>
            <div className='row'>
                <div className="col-2 p-0"  style={{width: "240px"}} >
                    <SideNavbar code="bannerList" />
                </div> 
                <div className="col-9" style={{width:'1080px'}}>
                    <Row>
                        <Col lg={9} sm={12} style={{padding : '15px'}}>
                            <div className='headerTitleDiv headerFont'>
                            ข้อมูลวิดีโอหน้าแรก
                            </div>
                        </Col>
                        <Col lg={3} sm={12} style={{padding : '15px'}}>
                            <div className='headerActionButtonDiv'>
                            <button
                                style={{
                                    width:'136px',
                                    fontWeight:400,
                                    height: '40px',
                                    borderRadius:'4px', 
                                    background: '#222226', 
                                    color: '#FFFFFF', 
                                    fontSize: '16px', 
                                    border: 'none',
                                }}
                                onClick={handleButtonClick}
                            >
                            แก้ไขข้อมูล
                            </button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className='row paddingBottom20'>
                            <div className='textAlignLeft font12Light cursor-pointer'  onClick={handleBreadcrumbClick}>
                                <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>รายการวิดีโอหน้าแรก</span>  {'>'}  รายละเอียด                            
                            </div>
                        </div>
                    </Row>
                    <Row className='paddingTop20 textAlignLeft'>
                        <Col lg={10} className={'contentBox marginAuto'}>
                            <div className={'row borderBottomForm'}>
                                <div className='col appFont16Regular padding10'>
                                <svg
                                    
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M3.75 3.75H20.25C21.0784 3.75 21.75 4.42157 21.75 5.25V15.75C21.75 16.5784 21.0784 17.25 20.25 17.25H3.75C2.92157 17.25 2.25 16.5784 2.25 15.75V5.25C2.25 4.42157 2.92157 3.75 3.75 3.75ZM15.4163 9.87562L10.9163 6.87562C10.6861 6.72204 10.39 6.70769 10.146 6.83831C9.90204 6.96892 9.74982 7.22327 9.75 7.5V13.5C9.74982 13.7767 9.90204 14.0311 10.146 14.1617C10.39 14.2923 10.6861 14.278 10.9163 14.1244L15.4163 11.1244C15.6252 10.9853 15.7507 10.751 15.7507 10.5C15.7507 10.249 15.6252 10.0147 15.4163 9.87562ZM11.25 12.0984V8.90625L13.6481 10.5L11.25 12.0984ZM20.25 15.75H3.75V5.25H20.25V15.75ZM21 20.25C21.4142 20.25 21.75 19.9142 21.75 19.5C21.75 19.0858 21.4142 18.75 21 18.75H3C2.58579 18.75 2.25 19.0858 2.25 19.5C2.25 19.9142 2.58579 20.25 3 20.25H21Z"
                                    fill="#222226"
                                    />
                                </svg>
                                   <span className='p-2'>ข้อมูลมีเดีย</span> 
                                </div>
                            </div>
                            <div className={'row borderBottomForm'}>
                                <div className={'col padding10'}>
                                    <div style={{float: 'left',backgroundColor:'#EFF0F1',width:'66px',height:'32px',marginLeft:'29px',textAlign:'center',borderRadius:'4px'}}>
                                        <span style={{fontSize:'12px',marginTop:'6px',display:'block'}}>{bannerDetailJson.bannerJson.bannerstatus}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className='col-lg-12 appFontInputHeader font16 padding10'>
                                    <Row>
                                        <Col lg={6} sm={6} >
                                            <div 
                                                style={{
                                                    width : '371px', 
                                                    backgroundColor : '#EFF0F1',
                                                    height:'206px', 
                                                    textAlign : 'center',
                                                    lineHeight : '180px',
                                                    justifyContent:'center',
                                                    borderRadius:'4px',
                                                    margin: '26px 0px 26px 21px'  
                                                }}>

                                            {(!bannerDetailJson.bannerJson.hasOwnProperty('filelist') || bannerDetailJson.bannerJson.filelist.length === 0) ? (
                                                <div style={{width : '371px', height:'206px'}}>
                                                    {action === 'slide' ? (
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M27 5H9C7.89543 5 7 5.89543 7 7V9H5C3.89543 9 3 9.89543 3 11V25C3 26.1046 3.89543 27 5 27H23C24.1046 27 25 26.1046 25 25V23H27C28.1046 23 29 22.1046 29 21V7C29 5.89543 28.1046 5 27 5ZM9 7H27V14.8438L25.7412 13.5863C25.3662 13.2111 24.8574 13.0003 24.3269 13.0003C23.7964 13.0003 23.2876 13.2111 22.9125 13.5863L20.4125 16.0863L14.9125 10.5863C14.1316 9.80582 12.8659 9.80582 12.085 10.5863L9 13.6712V7ZM5 25H23V23H9C7.89543 23 7 22.1046 7 21V11H5V25ZM27 21H9V16.5L13.5 12L19.7075 18.2075C20.098 18.5977 20.7308 18.5977 21.1213 18.2075L24.3288 15L27 17.6725V21ZM21.5 9C20.6716 9 20 9.67157 20 10.5C20 11.3284 20.6716 12 21.5 12C22.3284 12 23 11.3284 23 10.5C23 9.67157 22.3284 9 21.5 9Z" fill="#222226"/>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9525 7.03224C10.5641 6.59448 9.9704 6.39933 9.39807 6.52135C8.82573 6.64337 8.36321 7.06371 8.18717 7.6218C8.01113 8.17989 8.14876 8.78954 8.5475 9.21787L10.5077 11.3751H6.5C4.70507 11.3751 3.25 12.8301 3.25 14.6251V37.3751C3.25 39.17 4.70507 40.6251 6.5 40.6251H37.0988L41.0475 44.9679C41.4359 45.4056 42.0296 45.6008 42.6019 45.4788C43.1743 45.3567 43.6368 44.9364 43.8128 44.3783C43.9889 43.8202 43.8512 43.2106 43.4525 42.7822L10.9525 7.03224ZM49.4731 14.9074C49.9719 14.5756 50.6128 14.5452 51.1408 14.8282C51.6661 15.1089 51.9958 15.6544 52 16.2501V35.7501C52 36.6475 51.2725 37.3751 50.375 37.3751C50.0551 37.3784 49.7414 37.2872 49.4731 37.113L42.25 32.2969V32.4249C42.25 33.3224 41.5225 34.0499 40.625 34.0499C39.7275 34.0499 39 33.3224 39 32.4249V14.6251H22.9653C22.0679 14.6251 21.3403 13.8975 21.3403 13.0001C21.3403 12.1026 22.0679 11.3751 22.9653 11.3751H39C40.7949 11.3751 42.25 12.8301 42.25 14.6251V19.7133L49.4731 14.9074ZM42.25 28.3807L48.75 32.7133V19.2969L42.25 23.6194V28.3807ZM6.5 14.6251V37.3751H34.1453L13.4631 14.6251H6.5Z" fill="#222226"/>
                                                        </svg>
                                                    )}
                                                </div>
                                            ) : (
                                                <div style={{width : '371px', height:'206px'}}>
                                                    {action === 'slide' ? (
                                                        <img style={{width:'100%', height:'100%',objectFit:'cover'}} 
                                                        className={'p-0 m-0'} 
                                                        src={bannerDetailJson.bannerJson.filelist[0].filepath}
                                                        type={bannerDetailJson.bannerJson.filelist[0].contenttype}
                                                        />
                                                    ) : (
                                                        <div style={{ position: "relative",height: "100%",width:'100%'}}>
                                                        <video style={{width:'100%', height:'100%'}} 
                                                        className={'p-0 m-0'} 
                                                        src={bannerDetailJson.bannerJson.filelist[0].filepath}
                                                        type={bannerDetailJson.bannerJson.filelist[0].contenttype}
                                                        controls
                                                        />
                                                        </div>                      
                                                    )}
                                                </div>
                                            )} 
                                            
                                             </div>
                                        </Col>
                                        <Col lg={6} sm={12} className='padding10 mt-3 font12'>
                                            <div className='appFontLight'>
                                                หัวข้อ
                                            </div>
                                            <div className='paddingTop10'>
                                                <span style={{fontWeight:400,fontSize:'16px'}}>{bannerDetailJson.bannerJson.bannertopic}</span>
                                            </div>
                                            <div className='appFontLight paddingTop20'>
                                                URL
                                            </div>
                                            <div className='paddingTop10'>
                                                <span style={{fontWeight:400,fontSize:'16px',wordBreak:'break-word'}}>{bannerDetailJson.bannerJson.bannerurl}</span>
                                            </div>
                                            <div className='row paddingTop20'>
                                                <div className='col-lg-6'>
                                                    <div className='appFontLight'>
                                                        ผู้ทำรายการ
                                                    </div>
                                                    <div className='paddingTop10'>
                                                        <span style={{fontWeight:400,fontSize:'16px'}}> Admin</span>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className='appFontLight'>
                                                        วันที่อัพเดต
                                                    </div>
                                                    <div className='paddingTop10'>
                                                    <span style={{fontWeight:400,fontSize:'16px'}}>{getInputDate(bannerDetailJson.bannerJson.createdon)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div> 
            </div>
        </div>               
    );
}
