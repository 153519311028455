import React, { useState, useEffect} from 'react';
import {Row, Col} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import './index.css';
import ModalComponent from '../../../../components/Modal';
import ButtonComponent from '../../../../components/Button';
import {Form, InputGroup, Table} from 'react-bootstrap';
import {
    createUserAddress,
    getUserAddress,
    updateUserAddress
} from "../../Services/api";

export default function Address(props) {   
    const navigate = useNavigate();

    useEffect(() => {
        getUserAddressWrapper(props.userId);
    }, [props.userId]);

    const [modalJson, setModalJson] = useState({
        show : false,
        handleClose : handleButtonClose,
        title : 'เพิ่มที่อยู่'
    });

    const [userAddressJson, setUserAddressJson] = useState({
        dataError : '',
        addressList : [],
        addressInfo : {},
        madatoryFormList : [
            {
                name : 'address1'
            },
            {
                name : 'city'
            },
            {
                name : 'country'
            },
            {
                name : 'contactnumber'
            },
            {
                name : 'firstname'
            },
            {
                name : 'lastname'
            },
            {
                name : 'pincode'
            }
        ]
    });

    function handleButtonClose()
    {        
        setModalJson({
            ...modalJson,
            show : false
        });
    }

    function handleButtonSave()
    {
        let isDataNotValid = false;

        for (let i = 0; i < userAddressJson.madatoryFormList.length; i++) {
            const formField = userAddressJson.madatoryFormList[i];
            if(userAddressJson.addressInfo[formField.name] === undefined || userAddressJson.addressInfo[formField.name] ===  '')
            {
                isDataNotValid = true;
                break;
            }
        }

        if(isDataNotValid)
        {
            
            setUserAddressJson({
                ...userAddressJson,
                dataError : 'Mandatory fields cannot be empty'
            });
            return;
        }

        const reqParam = {
            userId : props.userId,
            addressId : userAddressJson.addressInfo.id,
            address1 : userAddressJson.addressInfo.address1,
            address2 : userAddressJson.addressInfo.address2,
            city : userAddressJson.addressInfo.city,
            country : userAddressJson.addressInfo.country,
            contactNumber : userAddressJson.addressInfo.contactnumber,
            firstName : userAddressJson.addressInfo.firstname,
            lastName : userAddressJson.addressInfo.lastname,
            pinCode : userAddressJson.addressInfo.pincode,
            isDeliveryAddress : userAddressJson.addressInfo.isdeliveryaddress ? 'Y' : 'N',
            isReceiptAddress : userAddressJson.addressInfo.isreceiptaddress ? 'Y' : 'N'
        };

        if(userAddressJson.addressInfo.id === undefined || userAddressJson.addressInfo.id === '')
        {
            createUserAddress(reqParam)
            .then((res) => 
            { 
                getUserAddressWrapper(props.userId);        
            })
            .catch((err) => 
            {
                console.log(err);
            });
        }
        else{
            updateUserAddress(reqParam)
            .then((res) => 
            { 
                getUserAddressWrapper(props.userId);        
            })
            .catch((err) => 
            {
                console.log(err);
            });
        }
        

        setModalJson({
            ...modalJson,
            show : false
        });

    }

    function getUserAddressWrapper(userId)
    {
        getUserAddress(userId)
        .then((res) => 
        { 
            if(checkIfKeyExistInObj(res.data, 'response') && res.data.response.length > 0)
            {
                setUserAddressJson(
                    {
                        ...userAddressJson,
                        addressList : res.data.response
                    }
                );
            }
            
        })
        .catch((err) => 
        {
            console.log(err);
        });

        setModalJson({
            ...modalJson,
            show : false
        });
    }


    function addNewAddressModal()
    {
        navigate(`/user/createAddress/${props.userId}`); 
        // setUserAddressJson({
        //     ...userAddressJson,
        //     addressInfo : {},
        //     dataError : ''
        // });

        // setModalJson({
        //     ...modalJson,
        //     show : true
        // });
    }

    // function showEditAddressModal(data) {
    //     navigate(`/user/editAddress/${props.userId}`); 
    // }
    
    function showEditAddressModal(data) {
        const queryParams = new URLSearchParams(data).toString();
        navigate(`/user/editAddress/${props.userId}?${queryParams}`);
    }

    function handleInputChange(event)
    {
        const key = event.target.name;
        let value = event.target.value;
        const type = event.target.type;
        if(type === 'checkbox')
        {
            value = event.target.checked ? 'Y' : 'N';
        }
        let addressInfoObj = userAddressJson.addressInfo;
        addressInfoObj[key] = value;

        setUserAddressJson(
            {
                ...userAddressJson,
                addressInfo : addressInfoObj
            }
        );
    }

    function checkIfKeyExistInObj(obj, prop)
    {
        return obj.hasOwnProperty(prop);
    }
   function updateTag(isDeliveryAddress, isreceiptaddress){
    if( isDeliveryAddress === "Y"){
        return (<div
            className={"subHeaderFont paddingTop5"}
            style={{
              backgroundColor: "#1313131A",
              width: "fit-content",
              height: "24px",
              padding: "5px",
              borderRadius: "3px",
              color: "black",
              fontSize:'12px',
              fontWeight: 500,
            }}
          >
            ที่อยู่จัดส่งตั้งต้น
          </div>)
        
    }else if(isreceiptaddress === "Y"){ 
        return (<div
            className={"subHeaderFont paddingTop5"}
            style={{
              backgroundColor: "#1313131A",
              width: "fit-content",
              height: "24px",
              padding: "5px",
              borderRadius: "3px",
              color: "black",
              fontSize:'12px',
              fontWeight: 500,
            }}
          >
            ที่อยู่ใบเสร็จ
          </div>)

    }
   }
    let modalFooter = 
       (<div className={'container-fluid'}>
            <div style={{textAlign:'center'}} className={'row p-0'}>
                <div className={'col-lg paddingBottom5'}>
                    <ButtonComponent buttonJson={{
                        label : "ยกเลิก",
                        handleClick : handleButtonClose,
                        style : 'light'
                    }} />
                    <span style={{paddingRight:'15px'}}>

                    </span>
                    <ButtonComponent buttonJson={{
                        label : "บันทึก",
                        handleClick : handleButtonSave
                    }} />
                </div>
            </div>
        </div>
        );

    let modalBody = 
       (<div className={'container-fluid'}>
            <div className={'row sidePadding0 paddingTop10'}>
                <div className={'col-lg paddingBottom5'}>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                            <span>
                                *
                            </span>
                            <span>
                                Address 1
                            </span>
                        </div>
                    </div>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                        <InputGroup>
                            <Form.Control
                            placeholder="Address 1"
                            aria-label="Address 1"
                            aria-describedby="basic-addon1"
                            name='address1'
                            defaultValue={checkIfKeyExistInObj(userAddressJson.addressInfo, 'address1') ? userAddressJson.addressInfo.address1 : '' }
                            onChange={handleInputChange}
                            />
                        </InputGroup>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'row sidePadding0 paddingTop10'}>
                <div className={'col-lg paddingBottom5'}>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                            <span>
                                Address 2
                            </span>
                        </div>
                    </div>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                        <InputGroup>
                            <Form.Control
                            placeholder="Address 2"
                            aria-label="Address 2"
                            aria-describedby="basic-addon1"
                            name='address2'
                            defaultValue={checkIfKeyExistInObj(userAddressJson.addressInfo, 'address2') ? userAddressJson.addressInfo.address2 : '' }
                            onChange={handleInputChange}
                            />
                        </InputGroup>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'row sidePadding0 paddingTop10'}>
                <div className={'col-lg paddingBottom5'}>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                            <span>
                                *
                            </span>
                            <span>
                                City
                            </span>
                        </div>
                    </div>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                        <InputGroup>
                            <Form.Control
                            placeholder="City"
                            aria-label="City"
                            aria-describedby="basic-addon1"
                            name='city'
                            defaultValue={checkIfKeyExistInObj(userAddressJson.addressInfo, 'city') ? userAddressJson.addressInfo.city : '' }
                            onChange={handleInputChange}
                            />
                        </InputGroup>
                        </div>
                    </div>
                </div>
                <div className={'col-lg paddingBottom5'}>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                            <span>
                                *
                            </span>
                            <span>
                                Country
                            </span>
                        </div>
                    </div>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                        <InputGroup>
                            <Form.Control
                            placeholder="Country"
                            aria-label="Country"
                            aria-describedby="basic-addon1"
                            name='country'
                            defaultValue={checkIfKeyExistInObj(userAddressJson.addressInfo, 'country') ? userAddressJson.addressInfo.country : '' }
                            onChange={handleInputChange}
                            />
                        </InputGroup>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'row sidePadding0 paddingTop10'}>
                <div className={'col-lg paddingBottom5'}>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                            <span>
                                *
                            </span>
                            <span>
                                Pin Code
                            </span>
                        </div>
                    </div>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                        <InputGroup>
                            <Form.Control
                            placeholder="Pin Code"
                            aria-label="Pin Code"
                            aria-describedby="basic-addon1"
                            name='pincode'
                            defaultValue={checkIfKeyExistInObj(userAddressJson.addressInfo, 'pincode') ? userAddressJson.addressInfo.pincode : '' }
                            onChange={handleInputChange}
                            />
                        </InputGroup>
                        </div>
                    </div>
                </div>
                <div className={'col-lg paddingBottom5'}>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                            <span>
                                *
                            </span>
                            <span>
                                Contact Number
                            </span>
                        </div>
                    </div>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                        <InputGroup>
                            <Form.Control
                            placeholder="Contact Number"
                            aria-label="Contact Number"
                            aria-describedby="basic-addon1"
                            name='contactnumber'
                            defaultValue={checkIfKeyExistInObj(userAddressJson.addressInfo, 'contactnumber') ? userAddressJson.addressInfo.contactnumber : '' }
                            onChange={handleInputChange}
                            />
                        </InputGroup>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'row sidePadding0 paddingTop10'}>
                <div className={'col-lg paddingBottom5'}>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                            <span>
                                *
                            </span>
                            <span>
                                First Name
                            </span>
                        </div>
                    </div>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                        <InputGroup>
                            <Form.Control
                            placeholder="First Name"
                            aria-label="First Name"
                            aria-describedby="basic-addon1"
                            name='firstname'
                            defaultValue={checkIfKeyExistInObj(userAddressJson.addressInfo, 'firstname') ? userAddressJson.addressInfo.firstname : '' }
                            onChange={handleInputChange}
                            />
                        </InputGroup>
                        </div>
                    </div>
                </div>
                <div className={'col-lg paddingBottom5'}>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                            <span>
                                *
                            </span>
                            <span>
                                Last Name
                            </span>
                        </div>
                    </div>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                        <InputGroup>
                            <Form.Control
                            placeholder="Last Name"
                            aria-label="Last Name"
                            aria-describedby="basic-addon1"
                            name='lastname'
                            defaultValue={checkIfKeyExistInObj(userAddressJson.addressInfo, 'lastname') ? userAddressJson.addressInfo.lastname : '' }
                            onChange={handleInputChange}
                            />
                        </InputGroup>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'row sidePadding0 paddingTop10'}>
                <Form>
                    <Form.Check 
                        type='checkbox'
                        id={`isDeliveryAddress`}
                        label={'ที่อยู่จัดส่งตั้งต้น'}
                        name='isdeliveryaddress'
                        checked={userAddressJson.addressInfo.isdeliveryaddress === "Y"}
                        onChange={handleInputChange}
                    />                                            
                </Form>
                <Form>
                    <Form.Check 
                        type='checkbox'
                        id={`isReceiptAddress`}
                        label={'ที่อยู่ใบเสร็จ'}
                        name='isreceiptaddress'
                        checked={userAddressJson.addressInfo.isreceiptaddress === "Y"}
                        onChange={handleInputChange}
                    />                                            
                </Form>
            </div>

            <div className={'row paddingTop10'} style={{color:'red'}}>
                {userAddressJson.dataError}
            </div>
            <div className={'row sidePadding0 paddingTop10'}>
                {modalFooter}
            </div>
        </div>
        );

    return (
            <div className='col-12'>
                <ModalComponent modalJson={modalJson}>
                    {modalBody}
                </ModalComponent>
                <div className='col-12' >
                    <Row>
                    {userAddressJson.addressList.map((data, idx) => (
                        <Col key={idx} lg={6} style={{marginBottom: '20px'}}>
                            <div className='col-12 boxBorder' style={{textAlign : 'left'}}>
                                <div className={'subHeaderFont mt-1'} 
                                style={{ borderBottom: '1px solid #B3B5BD',height:'32px'}}>
                                    <span className='ms-2'>
                                        <img src={require('../../../../assets/images/addressBook.png')}/>
                                        <span className='ms-2'>ที่อยู่</span>
                                    </span>
                                </div>
                                <div className={'col-12 padding10'} >
                                    <div  className={'subHeaderFont'} style={{marginBottom: '15px'}}>
                                        <Row>
                                            <Col>
                                                <div className={'appFont12Light'}>
                                                    ชื่อ-นามสกุล
                                                </div>
                                                <div className={'appFont16Regular text-break'}>
                                                    {data.name}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={'subHeaderFont'} style={{marginBottom: '15px'}}>
                                        <Row>
                                            <Col>
                                                <div className={'appFont12Light'}>
                                                    ที่อยู่
                                                </div>
                                                <div className="appFont16Regular text-break" style={{ display: 'flex', flexDirection: 'row', gap: '5px'}}>
                                                    <div>
                                                        {data.address1 && ` ${data.address1},`}
                                                        {data.address2 && ` ${data.address2},`}
                                                        {data.province && ` ${data.province},`}
                                                        {data.city && ` ${data.city},`}
                                                        {data.country && ` ${data.country},`}
                                                        {data.pincode}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={'subHeaderFont'} style={{marginBottom: '15px'}}>
                                        <Row>
                                            <Col>
                                                <div className={'appFont12Light'}>
                                                    เบอร์ติดต่อ
                                                </div>
                                                <div className={'appFont16Regular'}>
                                                    {data.contactnumber}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={'subHeaderFont'} style={{marginBottom: '15px'}}>
                                        <Row>
                                            <Col>
                                                {updateTag(data.isdeliveryaddress, data.isreceiptaddress)}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className={'appFont16Regular'} style={{ borderTop: '1px solid #B3B5BD',height:'32px',whiteSpace:'nowrap',background: 'rgba(179, 181, 189, 0.1)'}}>
                                    <Row className='m-0 p-0 mt-2'>
                                        <Col lg={3}  className='pointer' onClick={() => showEditAddressModal(data)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M14.2075 4.58547L11.4144 1.79297C11.2268 1.60539 10.9725 1.5 10.7072 1.5C10.4419 1.5 10.1876 1.60539 10 1.79297L2.29314 9.49985C2.10475 9.68674 1.99916 9.94136 2.00001 10.2067V12.9998C2.00001 13.5521 2.44773 13.9998 3.00001 13.9998H13.5C13.7762 13.9998 14 13.776 14 13.4998C14 13.2237 13.7762 12.9998 13.5 12.9998H7.20751L14.2075 5.99985C14.3951 5.81231 14.5005 5.55792 14.5005 5.29266C14.5005 5.02741 14.3951 4.77302 14.2075 4.58547ZM5.79313 12.9998H3.00001V10.2067L8.50001 4.70672L11.2931 7.49985L5.79313 12.9998ZM9.20751 3.99985L12 6.79297L13.5 5.29297L10.7075 2.49985L9.20751 3.99985Z" fill="#131313"/>
                                            </svg>
                                            <span className='font12 ms-2'>ที่อยู่</span>
                                        </Col>
                                        <Col lg={5} className='m-0 p-0'>
                                            <Form>
                                                <Form.Check 
                                                    style={{fontSize:'12px',cursor:'pointer'}}
                                                    disabled
                                                    checked={data.isdeliveryaddress === "Y"}
                                                    type='radio'
                                                    id={`default-radio`}
                                                    label={'ที่อยู่จัดส่งตั้งต้น'}
                                                />                                            
                                            </Form>
                                        </Col>
                                        <Col lg={4} className='m-0 p-0'>
                                            <Form>
                                                <Form.Check 
                                                    style={{fontSize:'12px',cursor:'pointer'}}
                                                    disabled
                                                    checked={data.isreceiptaddress === "Y"}
                                                    type='radio'
                                                    id={`default-radio`}
                                                    label={'ที่อยู่ใบเสร็จ'}
                                                />                                            
                                            </Form>
                                        </Col>
                                    </Row>                                    
                                </div>
                            </div>
                        </Col>
                    ))}
                    </Row>
                </div>
                <div className='col-12' style={{marginTop:'20px'}}>
                    <Row>
                        <Col lg={6}>
                            <div className='col-12 boxBorder appFontLight font16 pointer text-center' 
                            style={{height: '80px',lineHeight : '80px'}}
                            onClick={() => addNewAddressModal()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.7443 6.61758 17.3824 2.25568 12 2.25ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C16.5563 3.75 20.25 7.44365 20.25 12C20.2448 16.5542 16.5542 20.2448 12 20.25ZM15.75 12.75C16.1642 12.75 16.5 12.4142 16.5 12C16.5 11.5858 16.1642 11.25 15.75 11.25H12.75V8.25C12.75 7.83579 12.4142 7.5 12 7.5C11.5858 7.5 11.25 7.83579 11.25 8.25V11.25H8.25C7.83579 11.25 7.5 11.5858 7.5 12C7.5 12.4142 7.83579 12.75 8.25 12.75H11.25V15.75C11.25 16.1642 11.5858 16.5 12 16.5C12.4142 16.5 12.75 16.1642 12.75 15.75V12.75H15.75Z" fill="#B3B5BD"/>
                                </svg>
                                <span className='ms-2'>เพิ่มที่อยู่</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
    );
}