import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useNavigate} from "react-router-dom"
import {Form, InputGroup} from 'react-bootstrap';
import SideNavbar from '../../../layouts/SideNavbar';
import ButtonComponent from '../../../components/Button';
import { useParams } from 'react-router';
import {getUserById, getUserGroups,
updateUserDetail} from '../Services/api';
import { toast } from "react-toastify";
import { ExclamationCircleFill, XCircleFill } from "react-bootstrap-icons";

export default function Edit() {
    const navigate = useNavigate();

    const { id } = useParams();

    const [userEditJson, setUserEditJson] = useState({
            usergroupid : '',
            name : '',
            username : '',
            lastname : '',
            status :'',
    }); 

    const [userGroupList, setUserGroupList] = useState([]); 
    const [status, setStatus] = useState(false);      

    useEffect(() => {
        getUserGroupsWrapper();
        getUserByIdWrapper(id);
    }, [id]);

    
    
    function getUserGroupsWrapper()
    {    
          getUserGroups()
          .then((res) => 
          { 
            setUserGroupList(res.data);
                const userGroup = res.data.find(group => group.id === userEditJson.usergroupid);
                if (userGroup && userGroup.status) {
                    setStatus(true);
                } else {
                    setStatus(false);
                }

          })
          .catch((err) => 
          {
            console.log(err);
          });
    }

    function getUserByIdWrapper(id)
    {
        getUserById(id)
        .then((res) => 
          { 
            setUserEditJson(res.data.response); 
            setStatus(res.data.response.status === 'true');      
          })
          .catch((err) => 
          {
            console.log(err);
          });
    }

    function handleButtonClick()
    {
        const reqParam = {
            userGroupId : userEditJson.usergroupid,
            name : userEditJson.name,
            lastName : userEditJson.lastname,
            email : userEditJson.email,
            status :userEditJson.status,
            id : id,
            isSystemUser : "Y"
        };
        updateUserDetail(reqParam)
        .then((res) => 
          { 
            navigate("/user/system/list");
            toast.success("อัปเดตรายละเอียดผู้ใช้", {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                style: {
                  width: "1030px",
                  minHeight: "68px",
                  backgroundColor: "#D4F9E7",
                  color: "#009E4B",
                  fontSize: "18px",
                  fontWeight: 600,
                  marginLeft: "250px",
                },
              });
            console.log("Successfully updated.");
          })
          .catch((err) => 
          {
            toast.error(err.response.data.error, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                icon: <XCircleFill />,
                style: {
                  width: "1030px",
                  minHeight: "68px",
                  backgroundColor: "#FEE5E4",
                  color: "#EA3348",
                  fontSize: "18px",
                  fontWeight: 600,
                  marginLeft: "250px",
                },
              });
            console.log(err);
          });
    }

    function handleInputChange(event) {
        const { name, value, checked } = event.target;
        setUserEditJson({
            ...userEditJson,
            [name]: name === 'status' ? (checked ? 'true' : 'false') : value,
        });
        setStatus(checked);
    }

    
    function navigateToAccountInfoEdit()
    {
        navigate("/user/system/setting/:id");
    }
    const handleBreadcrumbClick = () => {
        window.history.back();
      };

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row'>
                <div className="col-2 p-0"  style={{width: "240px"}} >
                    <SideNavbar code="userSystemList" />
                </div> 
                <div className="col-9" style={{width:'1080px'}}>
                    <Row>
                        <Col style={{padding : '15px'}}>
                        <div className='headerTitleDiv headerFont'>
                            รายละเอียดผู้ใช้งานในระบบ
                        </div>
                        <div className='headerActionButtonDiv'>
                            <button
                            onClick={handleButtonClick}
                                style={{
                                width:'136px',
                                height:'40px',
                                background: "#131313",
                                color: "#FFFFFF",
                                fontSize: "14px",
                                fontWeight: 400,
                                border: "none",
                                borderRadius: "4px",
                                padding: "8px 16px", 
                                cursor: "pointer",
                                }}
                            >
                            บันทึก
                            </button>
                            
                        </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='row paddingBottom20'>
                                <div className='col-lg textAlignLeft font12Light cursor-pointer'  onClick={handleBreadcrumbClick}>
                                <span style={{ opacity:'0.5'}}>รายชื่อผู้ใช้งานในระบบ {'>'}</span> แก้ไขข้อมูล
                                </div>

                                <div className='row textAlignLeft paddingTopBottom10' style={{borderTop : '1px solid #B3B5BD',marginTop:'20px'}} />
                                    <div className='headerActionButtonDiv' style={{textAlign:'center'}}>
                                        <button className='' style={{borderRadius:'12px',borderWidth:'1px',width:'125px',height:'26px',padding:'1px'}} onClick = {()=>navigateToAccountInfoEdit()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3538 5.14625L9.85375 1.64625C9.7599 1.55251 9.63265 1.4999 9.5 1.5H3.5C2.94772 1.5 2.5 1.94772 2.5 2.5V13.5C2.5 14.0523 2.94772 14.5 3.5 14.5H12.5C13.0523 14.5 13.5 14.0523 13.5 13.5V5.5C13.5001 5.36735 13.4475 5.2401 13.3538 5.14625ZM10 3.20688L11.7931 5H10V3.20688ZM3.5 13.5H12.5V6H9.5C9.22386 6 9 5.77614 9 5.5V2.5H3.5V13.5ZM10.5 8.5C10.5 8.77614 10.2761 9 10 9H6C5.72386 9 5.5 8.77614 5.5 8.5C5.5 8.22386 5.72386 8 6 8H10C10.2761 8 10.5 8.22386 10.5 8.5ZM10 11C10.2761 11 10.5 10.7761 10.5 10.5C10.5 10.2239 10.2761 10 10 10H6C5.72386 10 5.5 10.2239 5.5 10.5C5.5 10.7761 5.72386 11 6 11H10Z" fill="#222226"/>
                                            </svg>
                                            <span style={{fontSize:'12px',fontWeight:'normal', color:'#222226',padding:'4px'}}>ข้อมูลผู้ใช้งาน</span>
                                        </button>
                                    </div>
                                <div className='row textAlignLeft paddingTopBottom10' style={{borderBottom : '1px solid #B3B5BD'}} />
                                
                               
                            </div>
                            <div className='container-fluid' style={{border : '1px solid #B3B5BD',borderRadius:'4px'}}>
                                <div className={'row textAlignLeft paddingTopBottom10'} style={{borderBottom:'1px solid #B3B5BD'}}>
                                    <div className={'col-lg font16Light'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.75 5.25V18.75C21.75 19.5784 21.0784 20.25 20.25 20.25H3.75C2.92157 20.25 2.25 19.5784 2.25 18.75V5.25C2.25 4.42157 2.92157 3.75 3.75 3.75H20.25C21.0784 3.75 21.75 4.42157 21.75 5.25ZM18 11.25C18.4142 11.25 18.75 10.9142 18.75 10.5C18.75 10.0858 18.4142 9.75 18 9.75H14.25C13.8358 9.75 13.5 10.0858 13.5 10.5C13.5 10.9142 13.8358 11.25 14.25 11.25H18ZM14.25 12.75H18C18.4142 12.75 18.75 13.0858 18.75 13.5C18.75 13.9142 18.4142 14.25 18 14.25H14.25C13.8358 14.25 13.5 13.9142 13.5 13.5C13.5 13.0858 13.8358 12.75 14.25 12.75ZM20.25 5.25V18.75H3.75V5.25H20.25ZM12.7256 15.5625C12.8292 15.9638 12.5878 16.373 12.1866 16.4766C11.7853 16.5801 11.3761 16.3388 11.2725 15.9375C11.0259 14.9756 10.0481 14.25 8.99906 14.25C7.95 14.25 6.97312 14.9756 6.72563 15.9375C6.62207 16.3388 6.21283 16.5801 5.81156 16.4766C5.41029 16.373 5.16895 15.9638 5.2725 15.5625C5.51588 14.6566 6.09118 13.8755 6.88406 13.3744C6.02237 12.5178 5.76242 11.226 6.22572 10.1028C6.68901 8.97961 7.78405 8.24675 8.99906 8.24675C10.2141 8.24675 11.3091 8.97961 11.7724 10.1028C12.2357 11.226 11.9758 12.5178 11.1141 13.3744C11.9078 13.8748 12.4836 14.6562 12.7266 15.5625H12.7256ZM10.5 11.25C10.5 12.0784 9.82843 12.75 9 12.75C8.17157 12.75 7.5 12.0784 7.5 11.25C7.5 10.4216 8.17157 9.75 9 9.75C9.82843 9.75 10.5 10.4216 10.5 11.25Z" fill="#222226"/>
                                    </svg>
                                        <span style={{paddingLeft:'10px',fontSize:'16px',fontWeight:400,}}>แก้ไขข้อมูลบัญชี</span>
                                    </div>
                                </div>
                                <div className={'row textAlignLeft paddingTopBottom10'} style={{borderBottom:'1px solid #B3B5BD'}}>
                                    <div style={{display: 'inline-block'}}>

                                    <span style={{fontSize:'12px',fontWeight:500}}>เปิด/ปิดการใช้งานบัญชี</span>    

                                    <div style={{paddingLeft:'20px', display: 'inline-block',width:'40px',height:'20px'}}>
                                        <Form>
                                        <Form.Check
                                            name='status'
                                            type='switch'
                                            id='custom-switch'
                                            checked={userEditJson.status === "true"}
                                            onChange={handleInputChange}
                                        />
                                        </Form>
                                        
                                     </div>

                                    </div>
                                </div>

                            



                                <div className={'row textAlignLeft paddingLeftRight10 paddingTopBottom20'}>
                                    <div className={'col-lg '}>
                                        <div className={'row paddingLeft12 font12Light'}>
                                            First Name
                                        </div>
                                        <div className={'row font12Light'}>
                                            <InputGroup>
                                                <Form.Control
                                                placeholder="ระบุชื่อ"
                                                aria-label="ระบุชื่อ"
                                                aria-describedby="basic-addon1"
                                                name='name'
                                                defaultValue={userEditJson.name}
                                                onChange={handleInputChange}
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className={'col-lg '}>
                                        <div className={'row paddingLeft12 font12Light'}>
                                            Last Name
                                        </div>
                                        <div className={'row font12Light'}>
                                            <InputGroup>
                                                <Form.Control
                                                placeholder="ระบุชื่อ"
                                                aria-label="ระบุชื่อ"
                                                aria-describedby="basic-addon1"
                                                name='lastname'
                                                defaultValue={userEditJson.lastname}
                                                onChange={handleInputChange}
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>
                                </div>
                                <div className={'row textAlignLeft paddingLeftRight10 paddingTopBottom20'}>
                                    <div className={'col-lg '}>
                                        <div className={'row paddingLeft12 font12Light'}>
                                            Username
                                        </div>
                                        <div className={'row font12Light'}>
                                            <InputGroup>
                                                <Form.Control
                                                placeholder="ระบุชื่อ"
                                                aria-label="ระบุชื่อ"
                                                aria-describedby="basic-addon1"
                                                name='email'
                                                defaultValue={userEditJson.email}
                                                onChange={handleInputChange}
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className={'col-lg '}>
                                        <div className={'row paddingLeft12 font12Light'}>
                                            User Group
                                        </div>
                                        <div className={'row paddingLeft12 font12Light'}>
                                            <Form.Select 
                                            aria-label="Default select example"
                                            name='usergroupid'
                                            value={userEditJson.usergroupid}
                                            onChange={handleInputChange}>
                                                <option>เลือกกลุ่มผู้ใช้งาน</option>
                                                {userGroupList.map((data, idx) => {
                                                    return (
                                                        <option key={idx} value={data.id}>{data.usergroupname}</option>
                                                    );
                                                })}
                                                
                                            </Form.Select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div> 
                </div>
            </div>  
        </React.Fragment>
      
             
    );
  }