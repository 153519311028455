import React, { useEffect, useState, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideNavbar from "../../../layouts/SideNavbar";
import ButtonComponent from "../../../components/Button";
import { useParams } from "react-router";
import { Form, InputGroup, Table, Button } from "react-bootstrap";
import { getBiddingProductDetail } from "../Services/api";
import { formatDate } from "../../../Utils/DateUtils";
import 
{
    getInputDate
} from '../../../Utils/DateUtils';
import { useNavigate } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";

export default function Detail() {
  const style = {
    background: "transparent",
    color: "#222226",
    fontSize: "14px",
    border: "none",
    height: "40px",
    border: "1px solid #222226",
    width: "10%",
    marginLeft: "380px",
    marginBottom: "10px",
  };
  const dropdownStyle = {
    border: "none",
    padding: "2px",
    background: "transparent",
    border: "0.5px solid #B3B5BD",
    width: "200px",
    height: "32px",
    fontSize: "12px",
    borderRadius: "4px",
    opacity: "0.5",
    fontWeight: 400,
  };
  const navigate = useNavigate();

  const { productId } = useParams();

  const [biddingProductJson, setBiddingProductJson] = useState({
    productDetail: {
      fileList: [],
    },
  });

  useEffect(() => {
    getBiddingProductDetailWrapper(productId);
  }, [productId]);

  function getBiddingProductDetailWrapper(id) {
    getBiddingProductDetail(id)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setBiddingProductJson({
            ...biddingProductJson,
            productDetail: res.data.response,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleButtonClick() {
    navigate("/bidding/edit/" + productId);
  }
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const filteredResults = biddingProductJson?.productDetail?.userProductList?.filter(
      (item) => {
          const isStatusValid = selectedStatus ? item.payment_status == selectedStatus : true;
          const isNameMatch = item.name?.toLowerCase().includes(searchTerm?.toLowerCase());
          const isLastNameMatch = item.lastname?.toLowerCase().includes(searchTerm?.toLowerCase());
          
          // If payment_status is not null or empty data, consider it as false
          const isPaymentStatusFalse = !item.payment_status || item.payment_status.trim() === '';
          
          return (isNameMatch || isLastNameMatch) && (isStatusValid || isPaymentStatusFalse);
      }
  );
  

    setFilteredData(filteredResults);
  }, [searchTerm, selectedStatus, biddingProductJson]);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setSelectedStatus("")
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setSearchTerm("")
  };
  const csvLinkRef = useRef(null);

  const downloadCSV = () => {
    // Programmatically click the CSVLink component
    if (csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
  };
  const userProductLists =
    biddingProductJson?.productDetail?.userProductList || [];

  function convertISTtoICT(date) {
    const ISTOffset = 330; // Indian Standard Time offset in minutes (IST is UTC+5:30)
    const ICTOffset = 420; // Thailand (ICT) is UTC+7:00
    const dateInICT = new Date(
      date.getTime() + (ICTOffset - ISTOffset) * 60000
    );
    return dateInICT;
  }

  function statusUpdate(
    startdateTime,
    enddateTime,
    formattedDate = convertISTtoICT(new Date())
  ) {
  
    const lowercaseAuctionStatus = biddingProductJson.productDetail
      .auctionstatus
      ? biddingProductJson.productDetail.auctionstatus.toLowerCase()
      : null;
    if (biddingProductJson.productDetail.performancestatus !== "DRAFT") {
      if (lowercaseAuctionStatus === "started") {
        return (
          <div
            className={"subHeaderFont paddingTop5"}
            style={{ backgroundColor: "#FDD34333",fontSize:'12px' }}
          >
            Auction Ongoing
          </div>
        );
      } else if (lowercaseAuctionStatus === "closed") {
        return (
          <div
            className={"subHeaderFont paddingTop5"}
            style={{ backgroundColor: "#009E4B1A",fontSize:'12px' }}
          >
            จบการประมูล
          </div>
        );
      } else if (biddingProductJson?.productDetail?.userProductList &&
        biddingProductJson.productDetail.userProductList.length === 0){
        return (
          <div
            className={"subHeaderFont paddingTop5"}
            style={{ backgroundColor: "#FDD34333",fontSize:'12px' }}
          >
            รอประมูล
          </div>
        );
      }
    } else {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{ backgroundColor: "#EFF0F1",fontSize:'12px' }}
        >
          DRAFT
        </div>
      );
    }
  }

  const dateTime = new Date(
    biddingProductJson.productDetail?.auctionclosedatetime?.split("T")[0]
  );

  function biddingStatus(biddingStatus, totalAuction, paymentStatus, userId, is_winner) {
    
    
    const dateTime = new Date();
    
    const winnerid =  biddingProductJson.productDetail.winnerid

    const date = dateTime.toDateString();
    const time = dateTime.toLocaleTimeString();
    const twentyFourHoursLater = new Date(
      biddingProductJson.productDetail?.auctionclosedatetime?.split("T")[0]
    );
    twentyFourHoursLater.setHours(twentyFourHoursLater.getHours() + 24);
    const paymentBefore24Hours = dateTime < twentyFourHoursLater;
    
    var status = parseInt(biddingStatus);
    if (status === 1 && is_winner === 1 ) {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#009E4B1A",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#009E4B",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          ชนะประมูล
        </div>
      );
    }
    else if (status === 1 && is_winner === 0){
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#FFD2DA",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#FC5C7E",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          สละสิทธิ์
        </div>
      );


    } else if (
      status === 2 && is_winner === 0
    ) {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#E5EDF9",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#3D79F2",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          สิทธิ์สำรอง
        </div>
      );
    } 
    else if (
      status === 2 && is_winner === 1
    ) {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#009E4B1A",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#009E4B",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          ชนะประมูล
        </div>
      );
    } else if (
      totalAuction > 0 && biddingStatus > 2
    ) {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#EFF0F1",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#6F6F71",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          เข้าร่วม
        </div>
      );
    } else {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#EFF0F1",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#6F6F71",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          จองสิทธิ์
        </div>
      );
    }
  }
  function paymentStatus(biddingStatus, totalAuction, paymentStatus, userId, is_winner) {
    const dateTime = new Date();
    const winnerid =  biddingProductJson.productDetail.winnerid
    const date = dateTime.toDateString();
    const time = dateTime.toLocaleTimeString();
    const twentyFourHoursLater = new Date(
      biddingProductJson.productDetail?.auctionclosedatetime?.split("T")[0]
    );
    twentyFourHoursLater.setHours(twentyFourHoursLater.getHours() + 24);
    const paymentBefore24Hours = dateTime < twentyFourHoursLater;

    var status = parseInt(biddingStatus);
   

    if (status === 1 && is_winner === 1 && paymentStatus !== "SUCCESSFUL") {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#FFE2D3",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#F78D23",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          waiting for payment
        </div>
      );
    }
    else if(status === 1 && is_winner === 1 && paymentStatus === "SUCCESSFUL"  ){
      return (
        <div
        className={"subHeaderFont paddingTop5"}
        style={{
          backgroundColor: "#009E4B1A",
          width: "fit-content",
          height: "28px",
          padding: "5px",
          borderRadius: "3px",
          color: "#009E4B",
          fontSize:'12px',
          fontWeight: 500,
        }}
      >
          Payment done 
        </div>
      );

    } else if (
      status === 1 && is_winner === 0
    ) {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#FFD2DA",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#FC5C7E",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          ไม่ชาระเงิน
        </div>
      );
    } else if (
      status === 2 && is_winner === 1 && paymentStatus !== "SUCCESSFUL"
    ) {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#FFE2D3",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#F27405",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
        รอชำระเงิน
        </div>
      );
    } else if(status === 2 && is_winner === 1 && paymentStatus === "SUCCESSFUL"  ){
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#009E4B1A",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#009E4B",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          Payment done 
        </div>
      );

    } else if ( status === 2 && is_winner === 0 ) {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#FDFAD7",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#454442",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          รอสิทธิ์
        </div>
      );
    }
   else if (status > 2 ) {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#EFF0F1",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            fontSize:'12px',
            borderRadius: "3px",
            color: "#959596",
            fontWeight: 500,
          }}
        >
         คืนเงิน
        </div>
      );
    } 
  }

  const handleBreadcrumbClick = () => {
    // Go back to the previous page
    window.history.back();
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 p-0" style={{ width: "240px" }}>
          <SideNavbar code="biddingList" />
        </div>
        <div className="col-9" style={{ width: "1080px" }}>
          <Row>
            <Col style={{ padding: "15px" }}>
              <div className="headerTitleDiv headerFont">
                รายละเอียดการประมูล
              </div>
              <div className="headerActionButtonDiv">
                <button
                  onClick={handleButtonClick}
                  style={{
                    background: "#ffffff", // Change background color if needed
                    color: "#131313",
                    fontSize: "12px",
                    fontWeight: 500,
                    border: "1px soild #B3B5BD  ",
                    borderRadius: "4px",
                    width: "105px",
                    height: "40px",
                    cursor: "pointer",
                  }}
                >
                  <span>แก้ไขข้อมูล</span>
                </button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="row paddingBottom20">
                <div
                  className="col-lg textAlignLeft font12Light cursor-pointer"
                  onClick={handleBreadcrumbClick}
                >
                  <span style={{ opacity: "0.5" }}>
                    รายการสินค้าประมูล {">"}
                  </span>{" "}
                  รายละเอียดการประมูล
                </div>
              </div>
              <div
                className="container-fluid"
                style={{ border: "1px solid #B3B5BD" }}
              >
                <div
                  className={"row textAlignLeft paddingTopBottom10"}
                  style={{ borderBottom: "1px solid #B3B5BD" }}
                >
                  <div
                    className={"col-lg font16Light"}
                    style={{ marginLeft: "5px" }}
                  >
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M19.75 2.25V16.5C19.7499 16.7599 19.6152 17.0012 19.394 17.1378C19.1729 17.2743 18.8968 17.2866 18.6644 17.1703L16 15.8381L13.3356 17.1703C13.1244 17.276 12.8756 17.276 12.6644 17.1703L10 15.8381L7.33563 17.1703C7.12436 17.276 6.87564 17.276 6.66437 17.1703L4 15.8381L1.33563 17.1703C1.1032 17.2866 0.827121 17.2743 0.605974 17.1378C0.384828 17.0012 0.250139 16.7599 0.25 16.5V2.25C0.25 1.42157 0.921573 0.75 1.75 0.75H18.25C19.0784 0.75 19.75 1.42157 19.75 2.25ZM5.5 6C5.08579 6 4.75 6.33579 4.75 6.75C4.75 7.16421 5.08579 7.5 5.5 7.5H14.5C14.9142 7.5 15.25 7.16421 15.25 6.75C15.25 6.33579 14.9142 6 14.5 6H5.5ZM14.5 10.5H5.5C5.08579 10.5 4.75 10.1642 4.75 9.75C4.75 9.33579 5.08579 9 5.5 9H14.5C14.9142 9 15.25 9.33579 15.25 9.75C15.25 10.1642 14.9142 10.5 14.5 10.5ZM1.75 2.25H18.25V15.2869L16.3356 14.3287C16.1244 14.223 15.8756 14.223 15.6644 14.3287L13 15.6619L10.3356 14.3287C10.1244 14.223 9.87564 14.223 9.66438 14.3287L7 15.6619L4.33562 14.3287C4.12436 14.223 3.87564 14.223 3.66437 14.3287L1.75 15.2869V2.25Z"
                        fill="#222226"
                      />
                    </svg>
                    <span style={{ paddingLeft: "10px" }}>
                      รายการละเอียดการประมูล
                    </span>
                  </div>
                </div>
                <div
                  className={
                    "row font12Light paddingLeftRight10 paddingTopBottom20"
                  }
                >
                  <div className={"col-lg-2"} style={{ marginLeft: "10px" }}>
                    <div className={"row"}>
                      <div
                        style={{
                          width: "290px",
                          height: "160px",
                          border: "1px solid #B3B5BD",
                          padding: "0px",
                          borderRadius: "4px",
                          marginBottom: "8px",
                        }}
                      >
                        <img
                          className={"p-0 m-0"}
                          style={{ width: "100%", height: "100%" }}
                          src={
                            biddingProductJson.productDetail?.fileList?.[0]
                              ?.filepath
                          }
                          type={
                            biddingProductJson.productDetail?.fileList?.[0]
                              ?.contenttype
                          }
                        />
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          fontWeight: 500,
                          borderRadius: "3px",
                        }}
                      >
                        {statusUpdate(
                          biddingProductJson.productDetail.auctionstartdatetime,
                          biddingProductJson.productDetail.auctionclosedatetime
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={"col-lg-2"}
                    style={{ textAlign: "left", marginLeft: "40px" }}
                  >
                    <div className={"row"}>
                      <div className="appFontLight">รหัสสินค้า</div>
                      <div className="subHeaderFont paddingTop5 ">
                        {biddingProductJson.productDetail.product_id}
                      </div>
                    </div>

                    <div className={"row paddingTop15"}>
                      <div className="appFontLight">วันที่เปิดประมูล</div>
                      <div className={"subHeaderFont paddingTop5"}>
                        {
                          biddingProductJson.productDetail?.auctionstartdatetime?.split(
                            "T"
                          )[0]
                        }
                      </div>
                    </div>

                    <div className={"row paddingTop15"}>
                      <div className="appFontLight">ราคาเริ่มประมูล</div>
                      <div className={"subHeaderFont paddingTop5"}>
                        { Math.round(biddingProductJson.productDetail.openingprice)}
                      </div>
                    </div>
                  </div>
                  <div className={"col-lg-2"} style={{ textAlign: "left" }}>
                    <div className={"row"}>
                      <div className="appFontLight">Running No</div>
                      <div className={"subHeaderFont paddingTop5"}>
                        {biddingProductJson.productDetail.runningno}
                      </div>
                    </div>

                    <div className={"row paddingTop15"}>
                      <div className="appFontLight">วันที่ปิดประมูล</div>
                      <div className={"subHeaderFont paddingTop5"}>
                        {
                          biddingProductJson.productDetail?.auctionclosedatetime?.split(
                            "T"
                          )[0]
                        }
                      </div>
                    </div>
                  </div>
                  <div className={"col-lg "} style={{ textAlign: "left" }}>
                    <div className={"row"}>
                      <div className="appFontLight">ชื่อสินค้า</div>
                      <div className={"subHeaderFont paddingTop5"}>
                        {biddingProductJson.productDetail.name}
                      </div>
                    </div>

                    <div className={"row paddingTop15"}>
                      <div className={"col"}>
                        <div className={"row"}>
                          <div className="appFontLight">สินค้าฝากขาย</div>
                          <div className={"subHeaderFont paddingTop5"}>-</div>
                        </div>
                      </div>
                      <div className={"col"}>
                        <div className={"row"}>
                          <div className="appFontLight">ราคาจำหน่าย</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            { Math.round(biddingProductJson.productDetail.openingprice)}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={"row paddingTop15"}>
                      <div className={"col"}>
                        <div className={"row"}>
                          <div className="appFontLight">
                            จำนวนเงินประมูลขั้นต่ำต่อครั้ง
                          </div>
                          <div className={"subHeaderFont paddingTop5"}>
                            { Math.round(biddingProductJson.productDetail.minbidamount)}
                          </div>
                        </div>
                      </div>
                      <div className={"col"}>
                        <div className={"row"}>
                          <div className="appFontLight">จำนวนเงินจองประมูล</div>
                          <div className={"subHeaderFont paddingTop5"}>
                          { Math.round(biddingProductJson.productDetail.minbidamount)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid col-lg-12 paddingTop15">
                <div className="row">
                  <div className="boxBorder col">
                    <div
                      className={"row textAlignLeft paddingTopBottom10"}
                      style={{ borderBottom: "1px solid #B3B5BD" }}
                    >
                      <div
                        className={"col-lg font16Light"}
                        style={{ marginLeft: "5px" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20.25 8.99997C20.2523 5.16734 17.6148 1.83788 13.8834 0.962929C10.152 0.0879784 6.30939 1.89799 4.60774 5.33215C2.90608 8.7663 3.79354 12.9201 6.74999 15.359V22.5C6.74981 22.76 6.88436 23.0016 7.10557 23.1384C7.32677 23.2752 7.60304 23.2876 7.83562 23.1712L12 21.0937L16.1653 23.1759C16.2697 23.2259 16.3842 23.2512 16.5 23.25C16.9142 23.25 17.25 22.9142 17.25 22.5V15.359C19.15 13.7942 20.2505 11.4615 20.25 8.99997ZM5.24999 8.99997C5.24999 5.27205 8.27207 2.24997 12 2.24997C15.7279 2.24997 18.75 5.27205 18.75 8.99997C18.75 12.7279 15.7279 15.75 12 15.75C8.27378 15.7458 5.25413 12.7262 5.24999 8.99997ZM12.3347 19.5797L15.75 21.2868V16.3472C13.3949 17.5509 10.6051 17.5509 8.24999 16.3472V21.2868L11.6634 19.5797C11.8747 19.4739 12.1234 19.4739 12.3347 19.5797ZM12 14.25C14.8995 14.25 17.25 11.8995 17.25 8.99997C17.25 6.10048 14.8995 3.74997 12 3.74997C9.1005 3.74997 6.74999 6.10048 6.74999 8.99997C6.75309 11.8982 9.10178 14.2469 12 14.25ZM15.75 8.99997C15.75 6.9289 14.0711 5.24997 12 5.24997C9.92892 5.24997 8.24999 6.9289 8.24999 8.99997C8.24999 11.071 9.92892 12.75 12 12.75C14.0711 12.75 15.75 11.071 15.75 8.99997Z"
                            fill="#222226"
                          />
                        </svg>
                        <span style={{ paddingLeft: "10px" }}>
                          สรุปการประมูล
                        </span>
                      </div>
                    </div>

                    {/* <div className={"row textAlignLeft paddingTopBottom10"} style={{ marginLeft: "5px" }}>
  {biddingProductJson.productDetail.latestAuctionList && biddingProductJson.productDetail.latestAuctionList.length > 0 &&
  biddingProductJson.productDetail.latestAuctionList.filter(auction => auction.bid_order === 1 || auction.bid_order === 2).map((auction, index) => (
    <div className={"row"} style={{ textAlign: "left" }} key={index}>
      <div className={"col paddingTop15"}>
        <div className="appFontLight font12Light">ผู้ชนะการประมูลอันดับ</div>
        <div className={"subHeaderFont paddingTop5"}>
          {biddingProductJson.productDetail.performancestatus === "DRAFT" ? "-" : auction.username}
        </div>
      </div>

      <div className={"col paddingTop15"}>
        <div className="appFontLight font12Light">ราคาที่ประมูล</div>
        <div className={"subHeaderFont paddingTop5"}>
          {biddingProductJson.productDetail.performancestatus === "DRAFT" ? "0.0" : auction.bidding_amount}
        </div>
      </div>
      
      {biddingProductJson.productDetail.performancestatus !== "DRAFT" && (
        <div className={"col paddingTop15"}>
          <div className="appFontLight font12Light">การชำระเงิน</div>
          <div className={"subHeaderFont paddingTop5"}>
            {paymentStatus(auction.bid_order, auction.total_participant_count, auction.payment_status)}
          </div>
        </div>
      )}
    </div>
  ))}
</div> */}
<div className={"row textAlignLeft paddingTopBottom10"} style={{ marginLeft: "5px" }}>
  {biddingProductJson.productDetail.latestAuctionList && biddingProductJson.productDetail.latestAuctionList.length > 0 && (() => {
    const uniqueBidOrders = new Set();
    return biddingProductJson.productDetail.latestAuctionList
      .filter(auction => {
        if ((auction.bid_order === 1 || auction.bid_order === 2) && !uniqueBidOrders.has(auction.bid_order)) {
          uniqueBidOrders.add(auction.bid_order);
          return true;
        }
        return false;
      })
      .map((auction, index) => (
        <div className={"row"} style={{ textAlign: "left" }} key={index}>
          <div className={"col paddingTop15"}>
            <div className="appFontLight font12Light">ผู้ชนะการประมูลอันดับ</div>
            <div className={"subHeaderFont paddingTop5"}>
              {biddingProductJson.productDetail.performancestatus === "DRAFT" ? "-" : auction.username}
            </div>
          </div>

          <div className={"col paddingTop15"}>
            <div className="appFontLight font12Light">ราคาที่ประมูล</div>
            <div className={"subHeaderFont paddingTop5"}>
              {biddingProductJson.productDetail.performancestatus === "DRAFT" ? "0.0" : auction.bidding_amount}
            </div>
          </div>

          {biddingProductJson.productDetail.performancestatus !== "DRAFT" && (
            <div className={"col paddingTop15"}>
              <div className="appFontLight font12Light">การชำระเงิน</div>
              <div className={"subHeaderFont paddingTop5"}>
              {biddingProductJson.productDetail.product_purchased_by === null 
                ? paymentStatus(
                   auction.bid_order,
                   auction.total_participant_count,
                   auction.payment_status,
                   auction.user_id,
                   auction.is_winner
               )
                : auction.user_id == biddingProductJson.productDetail.product_purchased_by 
                ?  <div
                className={"subHeaderFont paddingTop5"}
                style={{
                  backgroundColor: "#009E4B1A",
                  width: "fit-content",
                  height: "28px",
                  padding: "5px",
                  borderRadius: "3px",
                  color: "#009E4B",
                  fontSize:'12px',
                  fontWeight: 500,
                }}
              >
                  Payment done 
                </div>
                : <div
                className={"subHeaderFont paddingTop5"}
                style={{
                  backgroundColor: "#EFF0F1",
                  width: "fit-content",
                  height: "28px",
                  padding: "5px",
                  fontSize:'12px',
                  borderRadius: "3px",
                  color: "#959596",
                  fontWeight: 500,
                }}
              >
               คืนเงิน
              </div>
            }
                
                {/* {paymentStatus(auction.bid_order, auction.last_auction_total, auction.payment_status,auction.user_id, auction.is_winner)} */}
              </div>
            </div>
          )}
        </div>
      ));
  })()}
</div>

                  </div>
                  <div className="boxBorder col" style={{ marginLeft: "10px" }}>
                    <div
                      className={"row textAlignLeft paddingTopBottom10"}
                      style={{ borderBottom: "1px solid #B3B5BD" }}
                    >
                      <div
                        className={"col-lg font16Light"}
                        style={{ marginLeft: "5px" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.75 19.5C21.75 19.9142 21.4142 20.25 21 20.25H3C2.58579 20.25 2.25 19.9142 2.25 19.5V4.5C2.25 4.08579 2.58579 3.75 3 3.75C3.41421 3.75 3.75 4.08579 3.75 4.5V13.3472L8.50594 9.1875C8.77266 8.95401 9.16644 8.93916 9.45 9.15188L14.9634 13.2872L20.5059 8.4375C20.704 8.24149 20.9943 8.1714 21.2599 8.2555C21.5256 8.33959 21.7227 8.56402 21.7718 8.83828C21.8209 9.11254 21.714 9.39143 21.4941 9.5625L15.4941 14.8125C15.2273 15.046 14.8336 15.0608 14.55 14.8481L9.03656 10.7147L3.75 15.3403V18.75H21C21.4142 18.75 21.75 19.0858 21.75 19.5Z"
                            fill="#222226"
                          />
                        </svg>
                        <span style={{ paddingLeft: "10px" }}>
                          สถิติการประมูล
                        </span>
                      </div>
                    </div>
                    <div
                      className={"row textAlignLeft paddingTopBottom10"}
                      style={{ marginLeft: "5px" }}
                    >
                      <div className={"col"} style={{ textAlign: "left" }}>
                        <div className={"row paddingTop15"}>
                          <div className="appFontLight font12Light">
                            จำนวนผู้จองประมูลทั้งหมด
                          </div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {
                              biddingProductJson.productDetail
                                ?.staticticDetails?.[0]?.total_bidders
                            }
                          </div>
                        </div>
                        <div className={"row paddingTop15"}>
                          <div className="appFontLight font12Light">
                            จำนวนเงินมัดจำทั้งหมด
                          </div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {
                              biddingProductJson.productDetail
                                ?.staticticDetails?.[0]?.deposite_amount
                            }
                          </div>
                        </div>
                        
                      </div>

                      <div className={"col"} style={{ textAlign: "left" }}>
                      <div className={"row paddingTop15"}>
                          <div className="appFontLight font12Light">
                            จำนวนครั้งที่ประมูล
                          </div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {" "}
                            {
                              biddingProductJson.productDetail
                                ?.staticticDetails?.[0]?.number_of_auction_times
                            }
                          </div>
                        </div>

                        <div className={"row paddingTop15"}>
                          <div className="appFontLight font12Light">
                            จำนวนเงินค่าบริการทั้งหมด
                          </div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {
                              biddingProductJson.productDetail
                                ?.staticticDetails?.[0]?.service_fee
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Row>
                <Col style={{ paddingLeft: "20px" }}>
                  <div className=" row" style={{ paddingTop: "10px" }}>
                    <InputGroup style={{ width: "532px", height: "32px" }}>
                      <InputGroup.Text
                        style={{ background: "#131313" }}
                        id="basic-addon1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M12.2029 10.7653L15.7811 14.3435C16.0729 14.6354 16.0738 15.1077 15.7785 15.403V15.403C15.4852 15.6963 15.0114 15.6979 14.7161 15.4026L11.1826 11.8691C9.99708 12.8963 8.45056 13.5178 6.75888 13.5178C3.02605 13.5178 0 10.4917 0 6.75888C0 3.02605 3.02605 0 6.75888 0C10.4917 0 13.5178 3.02605 13.5178 6.75888C13.5178 8.25856 13.0293 9.64417 12.2029 10.7653ZM12.0158 6.75888C12.0158 3.85557 9.66218 1.50197 6.75888 1.50197C3.85557 1.50197 1.50197 3.85557 1.50197 6.75888C1.50197 9.66218 3.85557 12.0158 6.75888 12.0158C9.66218 12.0158 12.0158 9.66218 12.0158 6.75888Z"
                            fill="#F3F4F8"
                          />
                        </svg>
                      </InputGroup.Text>
                      <Form.Control
                        style={{ fontSize: "12px" }}
                        placeholder="ค้นหาด้วยรหัสสินค้าหรือชื่อสินค้า"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </InputGroup>
                    <select
                      className="col-2 me-3 rounded"
                      style={dropdownStyle}
                      value={selectedStatus}
                      onChange={handleStatusChange}
                    >
                      <option value="" disabled selected hidden>
                        เลือกดูตามสถานะ
                      </option>
                      <option value="">All Status </option>
                      <option value="SUCCESSFUL">CLOSED </option>
                      <option value="PENDING">OPEN</option>
                    </select>
                    <Button
                      className="col-2  rounded "
                      style={{
                        textWrap: "nowrap",
                        background: "transparent",
                        height: "32px",
                        color: "#222226",
                        borderRadius: "2px",
                        border: "1px solid #B3B5BD",
                        width: "108px",
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                      onClick={downloadCSV}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.3172 2.05781L16.6922 6.43281C16.8094 6.55012 16.8751 6.70919 16.875 6.875V16.875C16.875 17.5654 16.3154 18.125 15.625 18.125H4.375C3.68464 18.125 3.125 17.5654 3.125 16.875V3.125C3.125 2.43464 3.68464 1.875 4.375 1.875H11.875C12.0408 1.87487 12.1999 1.94063 12.3172 2.05781ZM14.7414 6.25L12.5 4.00859V6.25H14.7414ZM15.625 7.5V16.875H4.375V3.125H11.25V6.875C11.25 7.22018 11.5298 7.5 11.875 7.5H15.625ZM12.5005 12.5C12.5005 12.3341 12.4345 12.175 12.3172 12.0578C12.2 11.9405 12.0409 11.8745 11.875 11.8745C11.7091 11.8745 11.55 11.9405 11.4328 12.0578L10.625 12.8664V9.375C10.625 9.02982 10.3452 8.75 10 8.75C9.65482 8.75 9.375 9.02982 9.375 9.375V12.8664L8.56719 12.0578C8.32297 11.8136 7.92703 11.8136 7.68281 12.0578C7.4386 12.302 7.4386 12.698 7.68281 12.9422L9.55781 14.8172C9.67504 14.9345 9.83412 15.0005 10 15.0005C10.1659 15.0005 10.325 14.9345 10.4422 14.8172L12.3172 12.9422C12.4345 12.825 12.5005 12.6659 12.5005 12.5Z"
                          fill="#222226"
                        />
                      </svg>
                      Export CSV
                    </Button>
                    <CSVLink
                      data={userProductLists}
                      filename="sample.csv"
                      className="csv-link"
                      ref={csvLinkRef}
                      target="_blank"
                    />
                  </div>
                </Col>
              </Row>
              <div className="container-fluid col-lg-12 paddingTop15">
                <div className="row">
                  <div style={{ paddingTop: "20px", textAlign: "left" }}>
                    <Table>
                      <thead>
                        <tr style={{ fontSize: "12px", fontWeight: 500 }}>
                          <th>ลำดับ</th>
                          <th>รหัสผู้ใช้งาน</th>
                          <th>ชื่อ-นามสกุล</th>
                          <th>สถานะการประมูล</th>
                          <th>การชำระเงิน</th>
                          <th>จำนวนครั้งที่ประมูล</th>
                          <th>ยอดประมูลครั้งสุดท้าย</th>
                          <th>วันที่อัพเดต</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData?.length === 0 ? (
                          <tr>
                            <td colSpan="12" className="textAlignCenter">
                              <svg
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="24" cy="24" r="24" fill="#F3F4F8" />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.25 24C14.25 18.6152 18.6152 14.25 24 14.25C29.3824 14.2557 33.7443 18.6176 33.75 24C33.75 29.3848 29.3848 33.75 24 33.75C18.6152 33.75 14.25 29.3848 14.25 24ZM15.75 24C15.75 28.5563 19.4437 32.25 24 32.25C28.5542 32.2448 32.2448 28.5542 32.25 24C32.25 19.4437 28.5563 15.75 24 15.75C19.4437 15.75 15.75 19.4437 15.75 24ZM23.25 24.75V19.5C23.25 19.0858 23.5858 18.75 24 18.75C24.4142 18.75 24.75 19.0858 24.75 19.5V24.75C24.75 25.1642 24.4142 25.5 24 25.5C23.5858 25.5 23.25 25.1642 23.25 24.75ZM24 29.25C24.6213 29.25 25.125 28.7463 25.125 28.125C25.125 27.5037 24.6213 27 24 27C23.3787 27 22.875 27.5037 22.875 28.125C22.875 28.7463 23.3787 29.25 24 29.25Z"
                                  fill="#272A31"
                                />
                              </svg>

                              <div className="opacity-50 font12">
                                ไม่มีข้อมูลในระบบ
                              </div>
                            </td>
                          </tr>
                        ) : (
                          filteredData?.map((data, idx) => (
                            <tr key={idx}>
                              <td>{idx + 1}</td>
                              <td>{data.user_id}</td>
                              <td>{data.username}</td>
                              <td>
                              {biddingProductJson.productDetail.product_purchased_by === null 
                ? biddingStatus(
                    data.bid_order,
                    data.total_participant_count,
                    data.payment_status,
                    data.user_id,
                    data.is_winner
                )
                : data.user_id == biddingProductJson.productDetail.product_purchased_by 
                ? <div
                className={"subHeaderFont paddingTop5"}
                style={{
                  backgroundColor: "#009E4B1A",
                  width: "fit-content",
                  height: "28px",
                  padding: "5px",
                  borderRadius: "3px",
                  color: "#009E4B",
                  fontSize:'12px',
                  fontWeight: 500,
                }}
              >
                ชนะประมูล
              </div>
                : <div
                className={"subHeaderFont paddingTop5"}
                style={{
                  backgroundColor: "#EFF0F1",
                  width: "fit-content",
                  height: "28px",
                  padding: "5px",
                  borderRadius: "3px",
                  color: "#6F6F71",
                  fontSize:'12px',
                  fontWeight: 500,
                }}
              >
                เข้าร่วม
              </div>
            }
                              </td>
                              
                                <td>


                                {biddingProductJson.productDetail.product_purchased_by === null 
                ? paymentStatus(
                  data.bid_order,
                  data.total_participant_count,
                  data.payment_status,
                  data.user_id,
                  data.is_winner
               )
                : data.user_id == biddingProductJson.productDetail.product_purchased_by 
                ?  <div
                className={"subHeaderFont paddingTop5"}
                style={{
                  backgroundColor: "#009E4B1A",
                  width: "fit-content",
                  height: "28px",
                  padding: "5px",
                  borderRadius: "3px",
                  color: "#009E4B",
                  fontSize:'12px',
                  fontWeight: 500,
                }}
              >
                  Payment done 
                </div>
                : <div
                className={"subHeaderFont paddingTop5"}
                style={{
                  backgroundColor: "#EFF0F1",
                  width: "fit-content",
                  height: "28px",
                  padding: "5px",
                  fontSize:'12px',
                  borderRadius: "3px",
                  color: "#959596",
                  fontWeight: 500,
                }}
              >
               คืนเงิน
              </div>
            }
                                </td>
                            

                                <td>{data.total_participant_count}</td>
                             
                                <td>{data.last_auction_total}</td>
                          
                              <td>{getInputDate(data.createdon)}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
