import React, { useState, useEffect } from "react";
import SideNavbar from "../../../layouts/SideNavbar";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import ButtonComponent from "../../../components/Button";
import { Form, InputGroup, Table } from "react-bootstrap";
import { getBannerList } from "../../Banner/Services/api";
import { getInputDate } from "../../../Utils/DateUtils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiInterceptor from "../../..//hooks/apiInterceptor";
import { ExclamationCircleFill, XCircleFill } from "react-bootstrap-icons";

export default function Detail() {
  const navigate = useNavigate();
  const action = "highlight";

  const paginatorPage = {
    background: "transparent",
    border: "none",
    margin: "10px",
  };
  const [bannerListJson, setBannerListJson] = useState({
    bannerList: [],
  });

  useEffect(() => {
    getBannerListWrapper(action);
  }, [action]);

  function getBannerListWrapper(action) {
    var reqParam = {};
    reqParam.bannertype = action;
    getBannerList(reqParam)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setBannerListJson({
            ...bannerListJson,
            bannerList: res.data.response,
          });
        } else {
          setBannerListJson({
            ...bannerListJson,
            bannerList: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error");
      });
  }


  const copyToClipboard = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("คุณทำรายการสำเร็จ", {
          position: "top-left",
          autoClose: 2000, // Set the duration in milliseconds (e.g., 5000 milliseconds = 5 seconds)
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          style: {
            width: "1030px",
            minHeight: "68px",
            backgroundColor: "#D4F9E7",
            color: "#009E4B",
            fontSize: "18px",
            fontWeight: 600,
            marginLeft: "250px",
          },
        });
      })
      .catch((error) => {
        toast.warning("คุณกรอกข้อมูลไม่ครบ กรุณาตรวจสอบการกรอกข้อมูล", {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          icon: <ExclamationCircleFill />,
          style: {
            width: "1030px",
            minHeight: "68px",
            backgroundColor: "#FDF3DD",
            color: "#EC7405",
            fontSize: "18px",
            fontWeight: 600,
            marginLeft: "250px",
          }
        });
      });
  };

  function handleButtonClick() {
    navigate("/highlight/create");
  }

  function navigateToPreview(data) {
    try {
      // Access data properties directly
      navigate("/highlight/detail/" + data.id);
    } catch (error) {
      console.error("Error navigating:", error);
    }
  }

  function statusupdate(status) {
    if (status === null || status === undefined) {
      return null;
    }
    const lowercaseStatus = status.toLowerCase();
    if (lowercaseStatus === "publish") {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{ backgroundColor: "#009E4B1A",textAlign:"center",fontSize:"12px",fontWeight:500,color:'#009E4B'}}
        >
          {status}
        </div>
      );
    } else if (lowercaseStatus === "unpublish") {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{ backgroundColor: "#F36D2733" ,textAlign:"center",fontSize:"12px",color:'#EC7405' }}
        >
          {status}
        </div>
      );
    } else {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{ backgroundColor: "#FDD34333",textAlign:"center",fontSize:"12px"  }}
        >
          {status}
        </div>
      );
    }
  }

  function AsyncUsernameFetcher({ userId }) {
    const [username, setUsername] = useState(null);
  
    useEffect(() => {
      async function fetchUsername() {
        try {
          const response = await apiInterceptor.get(`/user/userid/userName/${userId}`);
          setUsername(response.data);
        } catch (error) {
          console.error("Error fetching username:", error);
        }
      }
  
      fetchUsername();
  
      // Cleanup function to prevent memory leaks
      return () => {
        setUsername(null); // Reset username state
      };
    }, [userId]);
  
    return username;
  }
  
  const [currentPage, setCurrentPage] = useState(0);
  const perPage = 10; // Set the number of items per page

  const startIndex = currentPage * perPage;
  const endIndex = startIndex + perPage;
  const slicedCardData = bannerListJson.bannerList.slice(startIndex, endIndex);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 p-0"  style={{width: "240px"}} >
          <SideNavbar code="highlightList" />
        </div>
        <div className="col-9" style={{width:'1080px'}}>
          <Row>
            <Col lg={9} sm={12} style={{ padding: "15px" }}>
              <div className="headerTitleDiv headerFont">
                  จัดการข้อมูลหน้า Highlight
              </div>
            </Col>
            <Col lg={3} sm={12} style={{ padding: "15px" }}>
                <div className='headerActionButtonDiv'>
                <button
                style={{
                    width:'136px',
                    fontWeight:400,
                    height: '40px',
                    borderRadius:'4px', 
                    background: '#222226', 
                    color: '#FFFFFF', 
                    fontSize: '16px', 
                    border: 'none',
                }}
                onClick={handleButtonClick}
                >
                เพิ่มข้อมูลใหม่
                </button>
                </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div style={{ paddingTop: "20px",overflowX: "auto",whiteSpace:'nowrap'}}>
                <Table>
                  <thead>
                    <tr style={{fontSize:'12px',fontWeight:500}}>
                      <th>ลำดับ</th>
                      <th>หัวข้อ</th>
                      <th>สถานะ</th>
                      <th>ผู้ทำรายการ</th>
                      <th>วันที่อัพเดต</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  {slicedCardData?.length === 0 ? (
                    <tr>
                      <td colSpan="12" className="textAlignCenter">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="24" cy="24" r="24" fill="#F3F4F8"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 24C14.25 18.6152 18.6152 14.25 24 14.25C29.3824 14.2557 33.7443 18.6176 33.75 24C33.75 29.3848 29.3848 33.75 24 33.75C18.6152 33.75 14.25 29.3848 14.25 24ZM15.75 24C15.75 28.5563 19.4437 32.25 24 32.25C28.5542 32.2448 32.2448 28.5542 32.25 24C32.25 19.4437 28.5563 15.75 24 15.75C19.4437 15.75 15.75 19.4437 15.75 24ZM23.25 24.75V19.5C23.25 19.0858 23.5858 18.75 24 18.75C24.4142 18.75 24.75 19.0858 24.75 19.5V24.75C24.75 25.1642 24.4142 25.5 24 25.5C23.5858 25.5 23.25 25.1642 23.25 24.75ZM24 29.25C24.6213 29.25 25.125 28.7463 25.125 28.125C25.125 27.5037 24.6213 27 24 27C23.3787 27 22.875 27.5037 22.875 28.125C22.875 28.7463 23.3787 29.25 24 29.25Z" fill="#272A31"/>
                        </svg> 
                        
                        <div className="opacity-50 font12">ไม่มีข้อมูลในระบบ</div>
                      </td>
                    </tr>
                  ) : (
                    slicedCardData.map((data, idx) => (
                      <tr className="font14Light">
                        <td>{idx + 1}</td>
                        <td style={{ cursor: "pointer" }}
                          onClick={() => navigateToPreview(data)}>
                          <div>{data.bannertopic}</div>
                        </td>
                        <td>{statusupdate(data.bannerstatus)}</td>
                         <td className="font12">{data.createdby ? (
                          <AsyncUsernameFetcher userId={data.createdby} />
                        ) : null}</td> 
                        <td>{getInputDate(data.createdon)}</td>
                        <td>
                          <div className="d-flex">
                            <div className="me-3">
                              <ButtonComponent
                                buttonJson={{
                                  label: "คัดลอก url",
                                  handleClick: () => copyToClipboard("https://iguat.cts-digital.com/highlight/" + data.id),
                                  style: "cancel",
                                  value: JSON.stringify(data.bannerurl),
                                }}
                              />
                            </div>
                            <div className="ms-4">
                              <ButtonComponent
                                buttonJson={{
                                  label: "ดูพรีวิว",
                                  handleClick: () => {
                                    const url =
                                      "https://iguat.cts-digital.com/highlight/" + data.id;
                                    window.open(url, "_blank");
                                  },
                                  style: "cancel",
                                  value: data ? JSON.stringify(data) : null,
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
            {bannerListJson.bannerList.length > perPage && (
              <div className="flex text-center gap-8 mt-20 ">
                {Array.from(
                  {
                    length: Math.ceil(
                      bannerListJson.bannerList.length / perPage
                    ),
                  },
                  (_, index) => (
                    <button
                      key={index}
                      className={`paginator-page text-black ${
                        currentPage === index && "active"
                      }`}
                      onClick={() => setCurrentPage(index)}
                      style={{ fontWeight: currentPage === index ? "bold" : "normal", ...paginatorPage }}
                    >
                      {index + 1}
                    </button>
                  )
                )}
              </div>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}
