import React, { useState, useEffect } from "react";
import SideNavbar from "../../../layouts/SideNavbar";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import ButtonComponent from "../../../components/Button";
import { getUserById } from "../../UserSystem/Services/api";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { updateUserDetail } from "../Services/api";
import {
  createUserAddress,
  getUserAddress,
  updateUserAddress,
} from "../Services/api";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ExclamationCircleFill, XCircleFill } from "react-bootstrap-icons";

export default function Create(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const UserData = Object.fromEntries(queryParams.entries());

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getUserAddressWrapper(id);
  }, [id]);

  const [modalJson, setModalJson] = useState({
    show: false,
    handleClose: handleButtonClose,
    title: "เพิ่มที่อยู่",
  });

  function handleButtonClose() {
    setModalJson({
      ...modalJson,
      show: false,
    });
  }

  const [userAddressJson, setUserAddressJson] = useState({
    dataError: "",
    addressList: [],
    addressInfo: {},
    madatoryFormList: [
      {
        name: "address1",
      },
      {
        name: "address2",
      },
      {
        name: "city",
      },
      {
        name: "country",
      },
      {
        name: "province",
      },
      {
        name: "pincode",
      },
    ],
  });

  function handleButtonSave() {
    let isDataNotValid = false;

    // Check if mandatory fields are empty
    for (let i = 0; i < userAddressJson.madatoryFormList.length; i++) {
      const formField = userAddressJson.madatoryFormList[i];
      if (
        userAddressJson.addressInfo[formField.name] === undefined ||
        userAddressJson.addressInfo[formField.name] === ""
      ) {
        isDataNotValid = true;
        break;
      }
    }

    // If mandatory fields are empty, show error and return
    if (isDataNotValid) {
      setUserAddressJson({
        ...userAddressJson,
        dataError: "Mandatory fields cannot be empty",
      });
      return;
    }

    const reqParam = {
      userId: id,
      addressId: userAddressJson.addressInfo.id,
      address1: userAddressJson.addressInfo.address1,
      address2: userAddressJson.addressInfo.address2,
      city: userAddressJson.addressInfo.city,
      country: userAddressJson.addressInfo.country,
      contactNumber: userAddressJson.addressInfo.contactnumber,
      firstName: userAddressJson.addressInfo.firstname,
      lastName: userAddressJson.addressInfo.lastname,
      pincode: userAddressJson.addressInfo.pincode,
      province: userAddressJson.addressInfo.province,
      isdeliveryaddress: userAddressJson.addressInfo.isdeliveryaddress,
      isreceiptaddress: userAddressJson.addressInfo.isreceiptaddress,
    };

    if (
      userAddressJson.addressInfo.id === undefined ||
      userAddressJson.addressInfo.id === ""
    ) {
      createUserAddress(reqParam)
        .then((res) => {
          setModalJson({
            ...modalJson,
            show: false,
          });
          getUserAddressWrapper(props.userId);
          navigate("/user/detail/" + id + "/address");
          toast.success("คุณทำรายการสำเร็จ", {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            style: {
              width: "1030px",
              minHeight: "68px",
              backgroundColor: "#D4F9E7",
              color: "#009E4B",
              fontSize: "18px",
              fontWeight: 600,
              marginLeft: "250px",
            },
          });
        })
        .catch((err) => {
          toast.error("คุณกรอกข้อมูลไม่ครบ กรุณาตรวจสอบการกรอกข้อมูล", {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            icon: <XCircleFill />,
            style: {
              width: "1030px",
              minHeight: "68px",
              backgroundColor: "#FDF3DD",
              color: "#EC7405",
              fontSize: "18px",
              fontWeight: 600,
              marginLeft: "250px",
            },
          });
          console.log(err);
        });
    } else {
      updateUserAddress(reqParam)
        .then((res) => {
          setModalJson({
            ...modalJson,
            show: false,
          });
          getUserAddressWrapper(props.userId);
          navigate("/user/detail/" + id + "/address");
          toast.success("คุณทำรายการสำเร็จ", {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            style: {
              width: "1030px",
              minHeight: "68px",
              backgroundColor: "#D4F9E7",
              color: "#009E4B",
              fontSize: "18px",
              fontWeight: 600,
              marginLeft: "250px",
            },
          });
        })
        .catch((err) => {
          toast.error("คุณกรอกข้อมูลไม่ครบ กรุณาตรวจสอบการกรอกข้อมูล", {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            icon: <XCircleFill />,
            style: {
              width: "1030px",
              minHeight: "68px",
              backgroundColor: "#FDF3DD",
              color: "#EC7405",
              fontSize: "18px",
              fontWeight: 600,
              marginLeft: "250px",
            },
          });
          console.log(err);
        });
    }
  }

  function getUserAddressWrapper(userId) {
    getUserAddress(userId)
      .then((res) => {
        if (
          checkIfKeyExistInObj(res.data, "response") &&
          res.data.response.length > 0
        ) {
          setUserAddressJson({
            ...userAddressJson,
            addressInfo: UserData,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setModalJson({
      ...modalJson,
      show: false,
    });
  }
  function handleInputChange(event) {
    const key = event.target.name;
    let value = event.target.value;
    const type = event.target.type;
    
    let addressInfoObj = userAddressJson.addressInfo;
    if (type === "radio") {
      if (key === "isdeliveryaddress") {
        addressInfoObj["isdeliveryaddress"] = "Y";
        addressInfoObj["isreceiptaddress"] = "N";
      } else if (key === "isreceiptaddress") {
        addressInfoObj["isdeliveryaddress"] = "N";
        addressInfoObj["isreceiptaddress"] = "Y";
      }
    }
    
    addressInfoObj[key] = value;
    
    setUserAddressJson({
      ...userAddressJson,
      addressInfo: addressInfoObj,
      isreceiptaddress: value,
    });
   
  }

  function checkIfKeyExistInObj(obj, prop) {
    return obj.hasOwnProperty(prop);
  }

  const handleBreadcrumbClick = () => {
    window.history.back();
  };

  function radiobutton(status) {
    if (status === "Y") {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 p-0" style={{ width: "240px" }}>
          <SideNavbar code="userList" />
        </div>

        <div className="col-9" style={{ width: "1080px" }}>
          <Row>
            <Col style={{ padding: "15px" }}>
              <div className="headerTitleDiv headerFont">รายละเอียดสมาชิก</div>
              <div className="headerActionButtonDiv">
                <button
                  onClick={handleButtonSave}
                  style={{
                    width: "136px",
                    fontSize: "16px",
                    height: "40px",
                    background: "#131313",
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: 400,
                    border: "none",
                    borderRadius: "4px",
                    padding: "8px 16px",
                    cursor: "pointer",
                  }}
                >
                  บันทึก
                </button>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="row paddingBottom20">
              <div
                className="col-lg textAlignLeft font12Light cursor-pointer"
                onClick={handleBreadcrumbClick}
              >
                <span style={{ opacity: "0.5" }}>
                  รายชื่อสมาชิกผู้ใช้งานเว็บไซต์ {">"} รายละเอียดสมาชิก {">"}{" "}
                </span>{" "}
                เพิ่มที่อยู่
              </div>
              <div
                className="row textAlignLeft paddingTopBottom10"
                style={{ borderTop: "1px solid #B3B5BD", marginTop: "20px" }}
              />
              <div className="headerActionButtonDiv text-center">
                <button
                  className=""
                  style={{
                    borderRadius: "12px",
                    borderWidth: "1px",
                    width: "125px",
                    height: "26px",
                    padding: "1px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.3538 5.14625L9.85375 1.64625C9.7599 1.55251 9.63265 1.4999 9.5 1.5H3.5C2.94772 1.5 2.5 1.94772 2.5 2.5V13.5C2.5 14.0523 2.94772 14.5 3.5 14.5H12.5C13.0523 14.5 13.5 14.0523 13.5 13.5V5.5C13.5001 5.36735 13.4475 5.2401 13.3538 5.14625ZM10 3.20688L11.7931 5H10V3.20688ZM3.5 13.5H12.5V6H9.5C9.22386 6 9 5.77614 9 5.5V2.5H3.5V13.5ZM10.5 8.5C10.5 8.77614 10.2761 9 10 9H6C5.72386 9 5.5 8.77614 5.5 8.5C5.5 8.22386 5.72386 8 6 8H10C10.2761 8 10.5 8.22386 10.5 8.5ZM10 11C10.2761 11 10.5 10.7761 10.5 10.5C10.5 10.2239 10.2761 10 10 10H6C5.72386 10 5.5 10.2239 5.5 10.5C5.5 10.7761 5.72386 11 6 11H10Z"
                      fill="#222226"
                    />
                  </svg>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#222226",
                      padding: "4px",
                    }}
                  >
                    เพิ่มที่อยู่
                  </span>
                </button>
              </div>
              <div
                className="row textAlignLeft paddingTopBottom10"
                style={{ borderBottom: "1px solid #B3B5BD" }}
              />
            </div>

            <Col lg={12}>
              <div
                style={{
                  textAlign: "left",
                  border: "1px solid #B3B5BD",
                  borderRadius: "4px",
                }}
              >
                <div
                  className={"subHeaderFont padding10"}
                  style={{ borderBottom: "1px solid #B3B5BD" }}
                >
                  <svg
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.0306 5.71938L11.7806 0.469375C11.6399 0.328759 11.449 0.249844 11.25 0.25H2.25C1.42157 0.25 0.75 0.921573 0.75 1.75V18.25C0.75 19.0784 1.42157 19.75 2.25 19.75H15.75C16.5784 19.75 17.25 19.0784 17.25 18.25V6.25C17.2502 6.05103 17.1712 5.86015 17.0306 5.71938ZM12 2.81031L14.6897 5.5H12V2.81031ZM2.25 18.25H15.75V7H11.25C10.8358 7 10.5 6.66421 10.5 6.25V1.75H2.25V18.25ZM12.75 10.75C12.75 11.1642 12.4142 11.5 12 11.5H6C5.58579 11.5 5.25 11.1642 5.25 10.75C5.25 10.3358 5.58579 10 6 10H12C12.4142 10 12.75 10.3358 12.75 10.75ZM12 14.5C12.4142 14.5 12.75 14.1642 12.75 13.75C12.75 13.3358 12.4142 13 12 13H6C5.58579 13 5.25 13.3358 5.25 13.75C5.25 14.1642 5.58579 14.5 6 14.5H12Z"
                      fill="#222226"
                    />
                  </svg>
                  <span className="ms-2"> รายละเอียดที่อยู่</span>
                </div>

                <div className={"subHeaderFont padding10"}>
                  <Row>
                    <Col lg={6}>
                      <div
                        style={{ paddingBottom: "10px" }}
                        className={"font12"}
                      >
                        ชื่ที่อยู่ : บ้านเลขที่ ซอย หมู่ ถนนอ
                      </div>
                      <div className={"appFont14Regular"}>
                        <InputGroup>
                          <Form.Control
                            placeholder=" 8/8 หมู่ที่ 1 ถนนสุราษฎร์-ทุ่งสง"
                            aria-label=" 8/8 หมู่ที่ 1 ถนนสุราษฎร์-ทุ่งสง"
                            aria-describedby="basic-addon1"
                            name="address1"
                            defaultValue={
                              checkIfKeyExistInObj(
                                userAddressJson.addressInfo,
                                "address1"
                              )
                                ? userAddressJson.addressInfo.address1
                                : ""
                            }
                            // defaultValue={userAddressJson.addressList[0]?.address1 ? userAddressJson.addressList[0]?.address1: null}
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div
                        style={{ paddingBottom: "10px" }}
                        className={"font12"}
                      >
                        อาคาร ชั้น
                      </div>
                      <div className={"appFont14Regular"}>
                        <InputGroup>
                          <Form.Control
                            placeholder="-"
                            aria-label="-"
                            aria-describedby="basic-addon1"
                            name="address2"
                            defaultValue={
                              checkIfKeyExistInObj(
                                userAddressJson.addressInfo,
                                "address2"
                              )
                                ? userAddressJson.addressInfo.address2
                                : ""
                            }
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "20px", marginBottom: "36px" }}>
                    <Col lg={3}>
                      <div
                        style={{ paddingBottom: "10px" }}
                        className={"appFont12Light"}
                      >
                        <span style={{ color: "red" }}> *</span> จังหวัด
                      </div>
                      <div className={"appFont14Regular"}>
                        <Form.Select
                          placeholder="-"
                          style={{ fontSize: "12px", height: "32px" }}
                          aria-label="สุราษฎร์ธานี"
                          name="province"
                          defaultValue={userAddressJson.addressInfo.province}
                          onChange={handleInputChange}
                        >
                          <option selected disabled value>
                            {userAddressJson.addressInfo.province}
                          </option>
                          <option value={"สุราษฎร์ธานี"}>สุราษฎร์ธานี</option>
                          <option value={"สุราษฎร์ธานี"}>สุราษฎร์ธานี</option>
                        </Form.Select>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div
                        style={{ paddingBottom: "10px" }}
                        className={"appFont12Light"}
                      >
                        <span style={{ color: "red" }}> *</span> เขต/อำเภอ
                      </div>
                      <div className={"appFont14Regular"}>
                        <Form.Select
                          style={{ fontSize: "12px", height: "32px" }}
                          aria-label="Default select example"
                          name="city"
                          defaultValue={userAddressJson.addressInfo.city}
                          onChange={handleInputChange}
                        >
                          <option selected disabled value>
                            {userAddressJson.addressInfo.city}
                          </option>
                          <option value={"เขานิพันธ์"}>เขานิพันธ์</option>
                          <option value={"เขานิพันธ์"}>เขานิพันธ์</option>
                        </Form.Select>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div
                        style={{ paddingBottom: "10px" }}
                        className={"appFont12Light"}
                      >
                        <span style={{ color: "red" }}> *</span> แขวง/ตำบล
                      </div>
                      <div className={"appFont14Regular"}>
                        <Form.Select
                          style={{ fontSize: "12px", height: "32px" }}
                          aria-label="Default select example"
                          name="country"
                          defaultValue={userAddressJson.addressInfo.country}
                          onChange={handleInputChange}
                        >
                          <option selected disabled value>
                            {userAddressJson.addressInfo.country}
                          </option>
                          <option value={"เขานิพันธ์"}>เขานิพันธ์</option>
                          <option value={"เขานิพันธ์"}>เขานิพันธ์</option>
                        </Form.Select>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div
                        style={{ paddingBottom: "10px" }}
                        className={"appFont12Light"}
                      >
                        <span style={{ color: "red" }}> *</span> รหัสไปรษณีย์
                      </div>
                      <div>
                        <Form.Select
                          style={{ fontSize: "12px", height: "32px" }}
                          aria-label="Default select example"
                          name="pincode"
                          defaultValue={userAddressJson.addressInfo.pincode}
                          onChange={handleInputChange}
                        >
                          <option selected disabled value>
                            {userAddressJson.addressInfo.pincode}
                          </option>
                          <option value={84190}>84190</option>
                          <option value={84190}>12345</option>
                        </Form.Select>
                      </div>
                    </Col>
                  </Row>
                  <div
                    className={"padding10 font12"}
                    style={{ borderTop: "1px solid #B3B5BD" }}
                  >
                    <Row>
                      <Col>
                        <Form>
                          <Form.Check
                            style={{ cursor: "pointer" }}
                            name="isdeliveryaddress"
                            checked={
                              userAddressJson.addressInfo.isdeliveryaddress ===
                              "Y"
                            }
                            type="radio"
                            id="deliveryaddress-radio"
                            label="ที่อยู่จัดส่งตั้งต้น"
                            value="Y"
                            onChange={handleInputChange}
                          />
                        </Form>
                      </Col>
                      <Col>
                        <Form>
                          <Form.Check
                            style={{ cursor: "pointer" }}
                            name="isreceiptaddress"
                            checked={
                              userAddressJson.addressInfo.isreceiptaddress ===
                              "Y"
                            }
                            type="radio"
                            id="receiptaddress-radio"
                            label="ที่อยู่ใบเสร็จ"
                            value="Y"
                            onChange={handleInputChange}
                          />
                        </Form>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
