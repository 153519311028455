import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Form, InputGroup, Table } from "react-bootstrap";
import ModalComponent from "../../../../components/Modal";
import ButtonComponent from "../../../../components/Button";
import { getAuctionHistory, getAuctionHistoryDetail } from "../../Services/api";
import Robota from "../../../../assets/images/productImg.png";

export default function AuctionHistory(props) {
  const dropdownStyle = {
    border: "none",
    padding: "9px",
    width: "100%",
  };

  const [showModalOne, setShowModalOne] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [AuctionHistoryJson, SetAuctionHistoryJson] = useState();
  const [AuctionHistoryDetailJson, SetAuctionHistoryDetailJson] = useState({});




  useEffect(() => {
    getAuctionHistoryWrapper(props.userId);
  }, [props.userId]);

  function getAuctionHistoryWrapper(userId) {
    getAuctionHistory(userId)
      .then((res) => {
        if (
          checkIfKeyExistInObj(res.data, "response") &&
          res.data.response) {
          SetAuctionHistoryJson({
            ...AuctionHistoryJson,
            auctionHistoryList: res.data.response,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  function getAuctionHistoryDetailWrapper(auction_history_id) {
    getAuctionHistoryDetail(auction_history_id)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          SetAuctionHistoryDetailJson({
            ...AuctionHistoryDetailJson,
            auctionHistoryDetail: res.data.response,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleButtonOneClick = (auction_history_id) => {
    getAuctionHistoryDetailWrapper(auction_history_id);
    setShowModalOne(true);
  };
  const handleCloseModalOne = () => {
    setShowModalOne(false);
  };

  function checkIfKeyExistInObj(obj, prop) {
    return obj.hasOwnProperty(prop);
  }

  function biddingStatus(biddingStatus, totalAuction, paymentStatus, userId, is_winner) {
    
    var status = parseInt(biddingStatus);
    if (status === 1 && is_winner === 1 ) {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#009E4B1A",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#009E4B",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          ชนะประมูล
        </div>
      );
    }
    else if (status === 1 && is_winner === 0){
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#FFD2DA",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#FC5C7E",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          สละสิทธิ์
        </div>
      );


    } else if (
      status === 2 && is_winner === 0
    ) {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#E5EDF9",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#3D79F2",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          สิทธิ์สำรอง
        </div>
      );
    } 
    else if (
      status === 2 && is_winner === 1
    ) {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#009E4B1A",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#009E4B",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          ชนะประมูล
        </div>
      );
    } else if (
      totalAuction > 0 && biddingStatus > 2
    ) {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#EFF0F1",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#6F6F71",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          เข้าร่วม
        </div>
      );
    } else {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#EFF0F1",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#6F6F71",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          จองสิทธิ์
        </div>
      );
    }
  }
  function paymentStatus(biddingStatus, totalAuction, paymentStatus, userId, is_winner) {
    

    var status = parseInt(biddingStatus);
   

    if (status === 1 && is_winner === 1) {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#FFE2D3",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#F78D23",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          waiting for payment
        </div>
      );
    } else if (
      status === 1 && is_winner === 0
    ) {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#FFD2DA",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#FC5C7E",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          ไม่ชาระเงิน
        </div>
      );
    } else if (
      status === 2 && is_winner === 1
    ) {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#FFE2D3",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#F27405",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
        รอชำระเงิน
        </div>
      );
    } else if ( status === 2 && is_winner === 0) {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#FDFAD7",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            borderRadius: "3px",
            color: "#454442",
            fontSize:'12px',
            fontWeight: 500,
          }}
        >
          รอสิทธิ์
        </div>
      );
    }
   else if (status > 2 ) {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{
            backgroundColor: "#EFF0F1",
            width: "fit-content",
            height: "28px",
            padding: "5px",
            fontSize:'12px',
            borderRadius: "3px",
            color: "#959596",
            fontWeight: 500,
          }}
        >
         คืนเงิน
        </div>
      );
    } 
  }

  const depositAmount =
  Number(AuctionHistoryDetailJson
    ?.auctionHistoryDetail?.productdepositamount) || 0;
const biddingAmount =
  Number(AuctionHistoryDetailJson?.auctionHistoryDetail?.bidding_amount) || 0;

const TotalAmount = Math.floor(Math.abs(biddingAmount - depositAmount));

  let modalBodytracking = (
    <div className="container">
      <Row>
        <Col>
          <Row>
            <div class="card me-5">
              <div class="card-header">
                <div className={"col-lg font16Light"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M21.75 5.25V19.5C21.7499 19.7599 21.6152 20.0012 21.394 20.1378C21.1729 20.2743 20.8968 20.2866 20.6644 20.1703L18 18.8381L15.3356 20.1703C15.1244 20.276 14.8756 20.276 14.6644 20.1703L12 18.8381L9.33563 20.1703C9.12436 20.276 8.87564 20.276 8.66437 20.1703L6 18.8381L3.33563 20.1703C3.1032 20.2866 2.82712 20.2743 2.60597 20.1378C2.38483 20.0012 2.25014 19.7599 2.25 19.5V5.25C2.25 4.42157 2.92157 3.75 3.75 3.75H20.25C21.0784 3.75 21.75 4.42157 21.75 5.25ZM7.5 9C7.08579 9 6.75 9.33579 6.75 9.75C6.75 10.1642 7.08579 10.5 7.5 10.5H16.5C16.9142 10.5 17.25 10.1642 17.25 9.75C17.25 9.33579 16.9142 9 16.5 9H7.5ZM16.5 13.5H7.5C7.08579 13.5 6.75 13.1642 6.75 12.75C6.75 12.3358 7.08579 12 7.5 12H16.5C16.9142 12 17.25 12.3358 17.25 12.75C17.25 13.1642 16.9142 13.5 16.5 13.5ZM3.75 5.25H20.25V18.2869L18.3356 17.3287C18.1244 17.223 17.8756 17.223 17.6644 17.3287L15 18.6619L12.3356 17.3287C12.1244 17.223 11.8756 17.223 11.6644 17.3287L9 18.6619L6.33562 17.3287C6.12436 17.223 5.87564 17.223 5.66437 17.3287L3.75 18.2869V5.25Z"
                      fill="#222226"
                    />
                  </svg>
                  <span style={{ paddingLeft: "10px" }}>
                    รายการละเอียดการประมูล
                  </span>
                </div>
              </div>
              <div class="card-body">
                <div
                  className={
                    "row font14Light paddingLeftRight10 paddingTopBottom20"
                  }
                >
                  <Row>
                    <Col xl={4} lg={12} md={12}>
                      <div>
                        <div>
                          <img
                            style={{
                              width: "120px",
                              height: "120px",
                            }}
                            className={"p-0 m-0"}
                            src={AuctionHistoryDetailJson?.auctionHistoryDetail?.product_file_detail[0]?.filepath}

                            alt="120120"
                          />
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "10px",
                            top : "10px"
                          }}
                        >
                         {biddingStatus(AuctionHistoryDetailJson?.auctionHistoryDetail?.bid_order,AuctionHistoryDetailJson?.auctionHistoryDetail?.total_participant_count,AuctionHistoryDetailJson?.auctionHistoryDetail?.paymentStatus,AuctionHistoryDetailJson?.auctionHistoryDetail?.userId
                          , AuctionHistoryDetailJson?.auctionHistoryDetail?.is_winner
                         )}
                        </div>
                      </div>
                    </Col>

                    <Col xl={8} lg={12} md={12}>
                      <Row>
                        <Col>
                          <div>
                            <div className="appFontLight">รหัสสินค้า</div>
                            <div className={"subHeaderFont paddingTop5"}>
                              {
                                AuctionHistoryDetailJson
                                  ?.auctionHistoryDetail?.id
                              }
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <div className="appFontLight">Running No</div>
                            <div className={"subHeaderFont paddingTop5"}>
                              {
                                AuctionHistoryDetailJson
                                  ?.auctionHistoryDetail?.runningno
                              }
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <div className="" style={{ marginTop: "20px" }}>
                          <div className="appFontLight">ชื่อสินค้า</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {
                              AuctionHistoryDetailJson
                                ?.auctionHistoryDetail?.name
                            }
                          </div>
                        </div>
                      </Row>

                      <Row style={{ marginTop: "20px" }}>
                        <Col>
                          <div>
                            <div className="appFontLight">วันที่เปิดประมูล</div>
                            <div className={"subHeaderFont paddingTop5"}>
                              {
                                AuctionHistoryDetailJson
                                  ?.auctionHistoryDetail?.auctionstartdatetime
                              }
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <div className="appFontLight">วันที่ปิดประมูล</div>
                            <div className={"subHeaderFont paddingTop5"}>
                              {
                                AuctionHistoryDetailJson
                                  ?.auctionHistoryDetail?.auctionclosedatetime
                              }
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Row>

          <Row>
            <div class="card me-5 mt-5" style={{ height: "100%" }}>
              <div class="card-header">
                <div className={"col-lg font16Light"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M21 4.5H3C2.17157 4.5 1.5 5.17157 1.5 6V18C1.5 18.8284 2.17157 19.5 3 19.5H21C21.8284 19.5 22.5 18.8284 22.5 18V6C22.5 5.17157 21.8284 4.5 21 4.5ZM21 6V8.25H3V6H21ZM3 18H21V9.75H3V18ZM19.5 15.75C19.5 16.1642 19.1642 16.5 18.75 16.5H15.75C15.3358 16.5 15 16.1642 15 15.75C15 15.3358 15.3358 15 15.75 15H18.75C19.1642 15 19.5 15.3358 19.5 15.75ZM12.75 16.5C13.1642 16.5 13.5 16.1642 13.5 15.75C13.5 15.3358 13.1642 15 12.75 15H11.25C10.8358 15 10.5 15.3358 10.5 15.75C10.5 16.1642 10.8358 16.5 11.25 16.5H12.75Z"
                      fill="#131313"
                    />
                  </svg>
                  <span style={{ paddingLeft: "10px" }}>การชำระเงิน</span>
                </div>
              </div>
              <div class="card-body">
                <div class="row  paddingLeftRight10 paddingTopBottom20">
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <div className={"row appFontLight"}>การชำระเงิน</div>
                    <span
                      style={{
                        fontSize: "12px",
                        justifyContent: "center",
                        borderRadius: "4px",
                      }}
                    >
                       {paymentStatus(AuctionHistoryDetailJson?.auctionHistoryDetail?.bid_order,AuctionHistoryDetailJson?.auctionHistoryDetail?.total_participant_count,AuctionHistoryDetailJson?.auctionHistoryDetail?.paymentStatus,AuctionHistoryDetailJson?.auctionHistoryDetail?.userId
                          , AuctionHistoryDetailJson?.auctionHistoryDetail?.is_winner
                         )}
                    </span>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <div className={"row appFontLight"}>ช่องทางการชำระเงิน</div>
                    <div>{AuctionHistoryDetailJson
                      ?.auctionHistoryDetail?.payment_details[0]?.payment_mode}</div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <div className={"row appFontLight"}>Ref No.</div>
                    <div> {AuctionHistoryDetailJson
                      ?.auctionHistoryDetail?.payment_details[0]?.reference_id}</div>
                  </div>
                </div>
                <div class="row paddingLeftRight10 paddingTopBottom20">
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <div className={"row appFontLight"}>ยอดประมูล</div>
                    <div>{AuctionHistoryDetailJson
                                ?.auctionHistoryDetail?.bidding_amount}</div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <div className={"row appFontLight"}>ยอดมัดจำ</div>
                    <div>  {
                      AuctionHistoryDetailJson
                        ?.auctionHistoryDetail?.productdepositamount
                    }</div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <div className={"row appFontLight"}>
                      ยอดชำระหลังหักมัดจำ
                    </div>
                    <div>{TotalAmount}</div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <span
                      className={"row"}
                      style={{
                        justifyContent: "center",
                        borderRadius: "4px",
                        borderWidth: "1px",
                        marginTop: "20px",
                        backgroundColor: "rgba(19, 19, 19, 0.10)",
                      }}
                    >
                      ใบเสร็จรับเงิน
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Col>

        <Col>
          <div class="card me-5">
            <div class="card-header">
              <div className={"col-lg font16Light"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.6325 5.63912C7.3174 3.94439 9.61024 2.9941 12 3.00006C16.0404 3.00269 19.5839 5.69765 20.6656 9.59061C21.7473 13.4836 20.1021 17.6203 16.6422 19.7069C13.1822 21.7936 8.75577 21.3188 5.81719 18.5457C5.62225 18.3615 5.54041 18.0872 5.60247 17.8263C5.66454 17.5654 5.8611 17.3574 6.1181 17.2807C6.3751 17.204 6.6535 17.2702 6.84844 17.4544C9.84035 20.278 14.5484 20.1645 17.4007 17.2C20.253 14.2354 20.1849 9.52653 17.248 6.64574C14.3111 3.76495 9.6018 3.78765 6.69281 6.69662C5.93156 7.46724 5.26406 8.21068 4.59375 9.00006H6.75C7.16421 9.00006 7.5 9.33584 7.5 9.75006C7.5 10.1643 7.16421 10.5001 6.75 10.5001H3C2.58579 10.5001 2.25 10.1643 2.25 9.75006V6.00006C2.25 5.58584 2.58579 5.25006 3 5.25006C3.41421 5.25006 3.75 5.58584 3.75 6.00006V7.68756C4.34531 6.99287 4.95094 6.32912 5.6325 5.63912ZM12.75 11.5754V7.50006C12.75 7.08584 12.4142 6.75006 12 6.75006C11.5858 6.75006 11.25 7.08584 11.25 7.50006V12.0001C11.2499 12.2634 11.388 12.5075 11.6138 12.6432L15.3638 14.8932C15.7189 15.1065 16.1798 14.9915 16.3931 14.6363C16.6064 14.2811 16.4914 13.8203 16.1362 13.6069L12.75 11.5754Z"
                    fill="#222226"
                  />
                </svg>
                <span style={{ paddingLeft: "10px" }}>ประวัติการประมูล</span>
              </div>
            </div>
            <div class="card-body">
              <div style={{ paddingTop: "20px" }}>
                <Table>
                  <thead>
                    <tr>
                      <th>ลำดับ</th>
                      <th>รหัสสินค้า</th>
                      <th>ชื่อสินค้า</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AuctionHistoryDetailJson?.auctionHistoryDetail?.auction_history?.map((data, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{data.id}</td>
                        <td>{data.name}</td>

                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );


  return (
    <div className="col-12">
      <Row>
        <Col>
          <div style={{ paddingTop: "20px", textAlign: "left" }}>
            <Table>
              <thead>
                <tr style={{ fontSize: '12px', fontWeight: 500 }}>
                  <th>ลำดับ</th>
                  <th>รหัสสินค้า</th>
                  <th>ชื่อสินค้า</th>
                  <th>ผลการประมูล</th>
                  <th>ลำดับ</th>
                  <th>ยอดประมูล</th>
                  <th>ยอดมัดจำ</th>
                  <th>การชำระเงิน</th>
                </tr>
              </thead>
              <tbody>
                {AuctionHistoryJson?.auctionHistoryList?.length === 0 ? (
                  <tr>
                    <td colSpan="12" className="textAlignCenter">
                      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="24" cy="24" r="24" fill="#F3F4F8" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 24C14.25 18.6152 18.6152 14.25 24 14.25C29.3824 14.2557 33.7443 18.6176 33.75 24C33.75 29.3848 29.3848 33.75 24 33.75C18.6152 33.75 14.25 29.3848 14.25 24ZM15.75 24C15.75 28.5563 19.4437 32.25 24 32.25C28.5542 32.2448 32.2448 28.5542 32.25 24C32.25 19.4437 28.5563 15.75 24 15.75C19.4437 15.75 15.75 19.4437 15.75 24ZM23.25 24.75V19.5C23.25 19.0858 23.5858 18.75 24 18.75C24.4142 18.75 24.75 19.0858 24.75 19.5V24.75C24.75 25.1642 24.4142 25.5 24 25.5C23.5858 25.5 23.25 25.1642 23.25 24.75ZM24 29.25C24.6213 29.25 25.125 28.7463 25.125 28.125C25.125 27.5037 24.6213 27 24 27C23.3787 27 22.875 27.5037 22.875 28.125C22.875 28.7463 23.3787 29.25 24 29.25Z" fill="#272A31" />
                      </svg>

                      <div className="opacity-50 font12">ไม่มีข้อมูลในระบบ</div>
                    </td>
                  </tr>
                ) : (
                  (AuctionHistoryJson?.auctionHistoryList?.map((data, idx) => (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td
                        onClick={() => handleButtonOneClick(data.auction_history_id)}
                        className="pointer"
                      >
                        {data.auction_history_id}{" "}
                      </td>
                      <td>{data.product_name}</td>
                      <td>{biddingStatus(data.bid_order,
                                  data.total_participant_count,
                                  data.payment_status,
                                  data.user_id,
                                  data.is_winner)}</td>
                      <td>{data.is_winner}</td>
                      <td>{data.auction_total}</td>
                      <td>{data.deposite_amount}</td>
                      <td>{paymentStatus(data.bid_order,
                                  data.total_participant_count,
                                  data.payment_status,
                                  data.user_id,
                                  data.is_winner)}</td>
                    </tr>
                  )))
                )}
              </tbody>
            </Table>
            <Modal show={showModalOne} onHide={handleCloseModalOne} size="xl">
              <Modal.Header closeButton>
                <Modal.Title>Modal title</Modal.Title>
              </Modal.Header>
              <Modal.Body>{modalBodytracking}</Modal.Body>
            </Modal>
          </div>
        </Col>
      </Row>
    </div>
  );
}
