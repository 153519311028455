import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideNavbar from "../../../layouts/SideNavbar";
import ButtonComponent from "../../../components/Button";
import { Form, InputGroup } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useParams } from "react-router";
import { Table } from "react-bootstrap";
import { getOrderProductDetail, updateTrackingProduct } from "../Services/api";
import { formatDate } from "../../../Utils/DateUtils";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../../../components/Modal";

export default function Detail() {
  const style = {
    background: "#FFFFFF",
    color: "#222226",
    fontSize: "14px",
    border: "none",
    height: "40px",
    paddingLeft: "25px",
    paddingRight: "25px",
    border: "1px solid #222226",
    width: "190px",
    marginLeft: "20px",
    marginBottom: "10px",
  };
  const dropdownStyle = {
    border: "none",
    padding: "9px",
    width: "100%",
  };

  const svgStyle = {
    marginRight: "30px", // Add some space between SVG and text
  };

  const navigate = useNavigate();

  const { orderId } = useParams();

  const [OrderProductJson, setOrderProductJson] = useState({
    productDetail: {
      fileList: [],
    },
  });

  useEffect(() => {
    getOrderProductDetailWrapper(orderId);
  }, [orderId]);
  function getOrderProductDetailWrapper(Id) {
    getOrderProductDetail(Id)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setOrderProductJson({
            ...OrderProductJson,
            productDetail: res.data.response,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // const TotalAmount = Math.floor(Number(OrderProductJson?.productDetail?.productdepositamount) - OrderProductJson?.productDetail?.auctionParticipantDetaial?.[0]?.bidding_amount );
  const depositAmount =
    Number(OrderProductJson?.productDetail?.productdepositamount) || 0;
  const biddingAmount =
    Number(
      OrderProductJson?.productDetail?.auctionParticipantDetaial?.[0]
        ?.bidding_amount
    ) || 0;

  const TotalAmount = Math.floor(Math.abs(biddingAmount - depositAmount));
  const handleBreadcrumbClick = () => {
    // Go back to the previous page
    window.history.back();
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 p-0"  style={{width: "240px"}} >
          <SideNavbar code="orderShipping" />
        </div>
        <div className="col-9" style={{width:'1080px'}}>
          <Row>
            <Col style={{ padding: "15px" }}>
              <div className="headerTitleDiv headerFont">รายละเอียดออเดอร์</div>
              <div className="headerActionButtonDiv"></div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="row paddingBottom20">
                <div className="col-lg textAlignLeft font12Light cursor-pointer"  onClick={handleBreadcrumbClick}>
                <span style={{ opacity:'0.5'}}> รายการออเดอร์และสถานะการจัดส่ง {">"}</span> รายละเอียดออเดอร์
                </div>
              </div>
              
              <div
                className="container-fluid"
                style={{ border: "1px solid #B3B5BD",borderRadius:'3px' }}
              >
                <div
                  className={"row textAlignLeft paddingTopBottom10"}
                  style={{ borderBottom: "1px solid #B3B5BD" }}
                >
                  <div className={"col-lg font16Light"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M21.75 5.25V19.5C21.7499 19.7599 21.6152 20.0012 21.394 20.1378C21.1729 20.2743 20.8968 20.2866 20.6644 20.1703L18 18.8381L15.3356 20.1703C15.1244 20.276 14.8756 20.276 14.6644 20.1703L12 18.8381L9.33563 20.1703C9.12436 20.276 8.87564 20.276 8.66437 20.1703L6 18.8381L3.33563 20.1703C3.1032 20.2866 2.82712 20.2743 2.60597 20.1378C2.38483 20.0012 2.25014 19.7599 2.25 19.5V5.25C2.25 4.42157 2.92157 3.75 3.75 3.75H20.25C21.0784 3.75 21.75 4.42157 21.75 5.25ZM7.5 9C7.08579 9 6.75 9.33579 6.75 9.75C6.75 10.1642 7.08579 10.5 7.5 10.5H16.5C16.9142 10.5 17.25 10.1642 17.25 9.75C17.25 9.33579 16.9142 9 16.5 9H7.5ZM16.5 13.5H7.5C7.08579 13.5 6.75 13.1642 6.75 12.75C6.75 12.3358 7.08579 12 7.5 12H16.5C16.9142 12 17.25 12.3358 17.25 12.75C17.25 13.1642 16.9142 13.5 16.5 13.5ZM3.75 5.25H20.25V18.2869L18.3356 17.3287C18.1244 17.223 17.8756 17.223 17.6644 17.3287L15 18.6619L12.3356 17.3287C12.1244 17.223 11.8756 17.223 11.6644 17.3287L9 18.6619L6.33562 17.3287C6.12436 17.223 5.87564 17.223 5.66437 17.3287L3.75 18.2869V5.25Z"
                        fill="#222226"
                      />
                    </svg>
                    <span style={{ paddingLeft: "10px" }}>
                      รายการละเอียดการประมูล
                    </span>
                  </div>
                </div>
                <div
                  className={
                    "row font14Light paddingLeftRight10 paddingTopBottom20"
                  }
                >
                  <div className={"col-lg-2"}>
                    <div className={"row"}>
                    <div style={{width:'120px',height:'120px',border:'1px solid #B3B5BD',padding:'0px',borderRadius:'4px',marginBottom:'8px',marginLeft:'17px'}}>
                    <img
                          className={"p-0 m-0"}
                          style={{ width: "100%", height: "100%" }}
                          src={

                            OrderProductJson?.productDetail
                              ?.auctionProductFileDetail?.[0]?.filepath 
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className={"col-lg-10"} style={{ textAlign: "left" }}>
                    <div className={"row"}>
                      <div className={"col-lg-2"}>
                        <div className="appFontLight">รหัสสินค้า</div>
                          <div className="subHeaderFont paddingTop5">
                            {OrderProductJson?.productDetail.product_id}
                          </div>
                      </div>
                      <div className={"col-lg-2"}>
                        <div className="appFontLight">Running No</div>
                        <div className={"subHeaderFont paddingTop5"}>
                          {OrderProductJson?.productDetail.runningno}
                        </div>
                      </div>
                      <div className={"col-lg-8"}>
                        <div className="appFontLight">ชื่อสินค้า</div>
                        <div className={"subHeaderFont paddingTop5"}>
                          {OrderProductJson?.productDetail?.productname}
                        </div>
                      </div>
                    </div>

                    <div className={"row paddingTop20"}>
                      <div className="col-2">
                        <div className="appFontLight">ยอดประมูล</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {
                              OrderProductJson?.productDetail
                                ?.auctionParticipantDetaial?.[0]?.bidding_amount
                            }
                          </div>
                      </div>
                      <div className="col-2">
                        <div className="appFontLight">ยอดมัดจำ</div>
                        <div className={"subHeaderFont paddingTop5"}>
                          {OrderProductJson?.productDetail?.productdepositamount}
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="appFontLight">ยอดชำระหลังหักมัดจำ</div>
                        <div className={"subHeaderFont paddingTop5"}>
                          {TotalAmount}
                        </div>
                      </div> 
                      <div className="col-2">
                      <div className="appFontLight">ช่องทางการชำระเงิน</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {
                              OrderProductJson?.productDetail
                                ?.auctionPaymentDetail?.[0]?.payment_mode
                            }
                          </div>
                      </div> 
                      <div className="col-4">
                      <div className="appFontLight">Ref No.</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {
                              OrderProductJson?.productDetail
                                ?.auctionPaymentDetail?.[0]?.reference_id
                            }
                          </div>
                      </div>   
                    </div>  
                  </div>
                  
                  
                  
                </div>
              </div>

              <div className="container-fluid col-lg-12 paddingTop15">
                <div className="row">
                  <div className="boxBorder col">
                    <div
                      className={"row textAlignLeft paddingTopBottom10"}
                      style={{ borderBottom: "1px solid #B3B5BD" }}
                    >
                      <div className={"col-lg font16Light"}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M23.1956 10.9688L21.8831 7.6875C21.6553 7.11944 21.1039 6.74789 20.4919 6.75H17.25V6C17.25 5.58579 16.9142 5.25 16.5 5.25H2.25C1.42157 5.25 0.75 5.92157 0.75 6.75V17.25C0.75 18.0784 1.42157 18.75 2.25 18.75H3.84375C4.18363 20.0774 5.37974 21.0059 6.75 21.0059C8.12026 21.0059 9.31637 20.0774 9.65625 18.75H14.3438C14.6836 20.0774 15.8797 21.0059 17.25 21.0059C18.6203 21.0059 19.8164 20.0774 20.1562 18.75H21.75C22.5784 18.75 23.25 18.0784 23.25 17.25V11.25C23.2503 11.1536 23.2318 11.0581 23.1956 10.9688ZM17.25 8.25H20.4919L21.3919 10.5H17.25V8.25ZM2.25 6.75H15.75V12.75H2.25V6.75ZM6.75 19.5C5.92157 19.5 5.25 18.8284 5.25 18C5.25 17.1716 5.92157 16.5 6.75 16.5C7.57843 16.5 8.25 17.1716 8.25 18C8.25 18.8284 7.57843 19.5 6.75 19.5ZM14.3438 17.25H9.65625C9.31637 15.9226 8.12026 14.9941 6.75 14.9941C5.37974 14.9941 4.18363 15.9226 3.84375 17.25H2.25V14.25H15.75V15.4041C15.0534 15.8067 14.547 16.4715 14.3438 17.25ZM17.25 19.5C16.4216 19.5 15.75 18.8284 15.75 18C15.75 17.1716 16.4216 16.5 17.25 16.5C18.0784 16.5 18.75 17.1716 18.75 18C18.75 18.8284 18.0784 19.5 17.25 19.5ZM21.75 17.25H20.1562C19.8124 15.9261 18.6179 15.0013 17.25 15V12H21.75V17.25Z"
                            fill="#222226"
                          />
                        </svg>
                        <span style={{ paddingLeft: "10px" }}>การจัดส่ง</span>
                      </div>
                    </div>



                  <div
                    className={"row textAlignLeft paddingLeftRight10 paddingTopBottom10"}
                    style={{ borderTop: "1px solid #B3B5BD"}}
                  >
                    <div className="col-2">
                      <div className={"subHeaderFont paddingTop5"} style={{backgroundColor:'#EFF0F1',width:'fit-content',borderRadius:'3px',padding:'5px'}}>
                        <span style={{fontSize:'12px',fontWeight:500}}>ที่อยู่จัดส่งตั้งต้น</span>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="appFontLight font12">
                        ชื่อ-นามสกุล
                      </div>
                    <div className={"subHeaderFont paddingTop5 text-break"}>
                        {OrderProductJson?.productDetail?.shippingfirstnameandlastname && (
                          OrderProductJson.productDetail.shippingfirstnameandlastname
                        )}
                    </div>                 
                    </div>
                    <div className="col-5">
                      <div className="appFontLight font12">เบอร์ติดต่อ</div>
                        <div className={"subHeaderFont paddingTop5"}>
                        {OrderProductJson?.productDetail?.shippingcontactnumber && (
                          OrderProductJson.productDetail.shippingcontactnumber
                        )}
                        </div>
                    </div>
                        

                    <div className="row paddingTopBottom10">
                      <div className="col-2">
                      </div>
                      <div className="col-10">
                      <div className="appFontLight font12">ที่อยู่</div>
                      <div className="appFont16Regular" style={{ flexDirection: 'row', gap: '5px'}}>
                            <div>{OrderProductJson?.productDetail?.shipping_address1}{OrderProductJson?.productDetail?.shipping_address1 && ','}</div>
                            <div>{OrderProductJson?.productDetail?.shipping_address2}{OrderProductJson?.productDetail?.shipping_address2 && ','}</div>
                            <div>{OrderProductJson?.productDetail?.shipping_province}{OrderProductJson?.productDetail?.shipping_province && ','}</div>
                            <div>{OrderProductJson?.productDetail?.shipping_country}{OrderProductJson?.productDetail?.shipping_country && ','}</div>
                            <div>{OrderProductJson?.productDetail?.shipping_city}{OrderProductJson?.productDetail?.shipping_city && ''}</div>
                            <div>{OrderProductJson?.productDetail?.shipping_pincode}</div>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={"row textAlignLeft paddingLeftRight10 paddingTopBottom10"}
                    style={{ borderTop: "2px solid #B3B5BD",marginBottom:'21px'}}
                  >
                    <div className="col-2">
                    </div>
                    <div className="col-5">
                      <div className="appFontLight font12">ชื่อขนส่ง</div>
                      <div className={"subHeaderFont paddingTop5"}>
                        {OrderProductJson?.productDetail &&  OrderProductJson.productDetail.transport_type}
                      </div>
                    </div>
                    <div className="col-5">
                    <div className="appFontLight font12">เลข Tracking No.</div>
                      <div className={"subHeaderFont paddingTop5"}>
                        {OrderProductJson?.productDetail && OrderProductJson.productDetail.tracking_number}
                      </div>
                    </div>
                  
                  
                  
                  </div>

                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
