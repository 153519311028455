import apiInterceptor from "../../../hooks/apiInterceptor";

export function createBanner(reqParam) {
  return apiInterceptor
    .post("/banner/create", reqParam)
}

export function updateBanner(reqParam) {
  return apiInterceptor
    .post("/banner/update", reqParam)
}

export function saveBannerFileInfo(reqParam) {
  return apiInterceptor
    .post("/media/file/info/create", reqParam)
}


export function saveHighlightInfo(reqParam) {
  return apiInterceptor
    .post("/banner/highlight/detail/create", reqParam)
}

export function deleteBannerFileInfo(reqParam) {
  return apiInterceptor
    .post("/media/file/info/delete", reqParam)
}
export function deletehighlightFileInfo(reqParam) {
  return apiInterceptor
    .post("/media/file/highlight/delete", reqParam)
}

export function getBannerList(reqParam) {
  return apiInterceptor
    .post("/banner/list", reqParam)
}

export function getBannerDetail(reqParam) {
  return apiInterceptor
    .post("/banner/detail", reqParam)
}

export function getMediaList() {

  return apiInterceptor
    .get("/media/list", {

    })
}
