import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideNavbar from "../../../layouts/SideNavbar";
import ButtonComponent from "../../../components/Button";
import { Form, InputGroup } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useParams } from "react-router";
import { Table } from "react-bootstrap";
import {
  getMediaFileDetail,
  getProductFileDetail,
  deleteMediaFileInfo,
  updateTagsList,
} from "../Services/api";
import { formatDate } from "../../../Utils/DateUtils";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../../../components/Modal";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import apiInterceptor from "../../../hooks/apiInterceptor";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { ExclamationCircleFill,XCircleFill } from "react-bootstrap-icons";

const animatedComponents = makeAnimated();


// const initializeSelect2 = () => {
//   const elements = document.querySelectorAll('.js-select2');
//   elements.forEach(element => {
//     new Select(element, {
//       closeOnSelect: false,
//       placeholder: "Click to select an option",
//       allowHtml: true,
//       allowClear: true,
//       tags: true
//     });
//   });
// };

// async function deleteAndFetchData(id) {
//   try {
//     const response = await apiInterceptor.delete(`/banner/delete/${id}`);

//     if (response.data.status === "SUCCESS") {
//       toast.success(response.data.status);
//     }
//   } catch (error) {
//     toast.error(error.message);
//   }
// }

export default function Detail() {
  const style = {
    background: "#FFFFFF",
    color: "#222226",
    fontSize: "14px",
    border: "none",
    height: "40px",
    paddingLeft: "25px",
    paddingRight: "25px",
    border: "1px solid #222226",
    width: "190px",
    marginLeft: "20px",
    marginBottom: "10px",
  };
  const dropdownStyle = {
    border: "none",
    padding: "9px",
    width: "100%",
  };

  const svgStyle = {
    marginRight: "30px", // Add some space between SVG and text
  };
  const navigate = useNavigate();

  const { productId } = useParams();

  const [isSelectDisabled, setSelectDisabled] = useState(false);


  const [MediaFileJson, setMediaFileJson] = useState({});


  const options = [
    { value: 'mobile', label: 'Mobile' },
    { value: 'desktop', label: 'Desktop' },
    { value: 'highlight', label: 'Highlight' },
    { value: 'model', label: 'Model' },
  ];


  // const tesoptions = [
  //   { value: 'mobile', label: 'Mobile' },
  //   { value: 'desktop', label: 'Desktop' },
  //   { value: 'highlight', label: 'Highlight' }
  // ];


  const [selectedOption, setSelectedOption] = useState(null);
  const [tagList, setTagsList] = useState([]);


  useEffect(() => {
    getMediaFileDetailWrapper(productId);
  }, [productId]);

  const handleTagList = (e) => {
    let tagData = {}
    tagData.tags = e
    updateTagsList(tagData, productId)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setSelectedOption(res.key);
          getMediaFileDetailWrapper(productId)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  function updateTagListApi(id) {

  }
  const getMediaFileDetailWrapper = async (id) => {
    await getMediaFileDetail(id)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setTagsList(res.data.response?.tags)
          setMediaFileJson({
            ...MediaFileJson,
            MediaDetail: res.data.response,
          });

        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleButtonClick = (id) => {
    Swal.fire({
      title: "คุณแน่ใจไหม??",
      text: "คุณจะไม่สามารถย้อนกลับสิ่งนี้ได้!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่ ลบมันออก!"
    }).then((result) => {
      if (result.isConfirmed) {
        const reqParam = {
          fileId: id,
        };
  
        deleteMediaFileInfo(reqParam)
          .then((result) => {
              navigate("/medialibrary/list");
              toast.success("คุณทำรายการสำเร็จ", {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                style: {
                  width: "1030px", 
                  minHeight: "68px", 
                  backgroundColor: "#D4F9E7",
                  color: "#009E4B",
                  fontSize: "18px",
                  fontWeight: 600,
                  marginLeft:'250px'              
                },
              });
          })
          .catch((error) => {
            toast.error("เกิดข้อผิดพลาด ไม่สามารถทำรายการได้", {
              position: "top-left",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              icon: <XCircleFill />,
              style: {
                width: "1030px", 
                minHeight: "68px", 
                backgroundColor: "#FEE5E4",
                color: "#EA3348",
                fontSize: "18px",
                fontWeight: 600,
                marginLeft:'250px'              
              },
            });
            console.error(error.message);
          });
      }
    });
  };
  

  function navigateToAccountInfoEdit() {
    setSelectDisabled(!isSelectDisabled)
    // navigate("/medialibrary/revise/" + productId);
  }

  const handleBreadcrumbClick = () => {
    // Go back to the previous page
    window.history.back();
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 p-0"  style={{width: "240px"}} >
          <SideNavbar code="medialibrary" />
        </div>
        <div className="col-10" style={{width:'1080px'}}>
          <Row>
            <Col style={{ padding: "15px" }}>
              <div className="headerTitleDiv headerFont">Media Library</div>
              <div className="headerActionButtonDiv">
                <Button
                  style={{width:'112px',height:'40px'}}
                  className="btn btn-danger"
                  label="ลบข้อมูล"
                  onClick={() => handleButtonClick(MediaFileJson?.MediaDetail?.id)}
                >
                  ลบข้อมูล
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="row paddingBottom20">
                <div className="col-lg textAlignLeft font12Light cursor-pointer" onClick={handleBreadcrumbClick}>
                  <span style={{opacity:'0.5'}}>รายการ Media {">"} </span> รายละเอียด Media
                </div>
              </div>
              <div
                className="container-fluid"
                style={{ border: "1px solid #B3B5BD",borderRadius:'4px' }}
              >
                <div
                  className={"row textAlignLeft"}
                  style={{ borderBottom: "1px solid #B3B5BD",height:'35px'}}
                >
                  <div className={"col-lg font16Light"} style={{marginTop:'8px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20.25 3.75H3.75C2.92157 3.75 2.25 4.42157 2.25 5.25V18.75C2.25 19.5784 2.92157 20.25 3.75 20.25H20.25C21.0784 20.25 21.75 19.5784 21.75 18.75V5.25C21.75 4.42157 21.0784 3.75 20.25 3.75ZM20.25 5.25V14.8828L17.8059 12.4397C17.5246 12.1583 17.143 12.0002 16.7452 12.0002C16.3473 12.0002 15.9657 12.1583 15.6844 12.4397L13.8094 14.3147L9.68437 10.1897C9.09867 9.60436 8.14946 9.60436 7.56375 10.1897L3.75 14.0034V5.25H20.25ZM8.625 11.25L3.75 16.125V18.75H16.125L8.625 11.25ZM20.25 18.75H18.2466L14.8716 15.375L16.7466 13.5L20.25 17.0044V18.75ZM14.625 8.25C14.0037 8.25 13.5 8.75368 13.5 9.375C13.5 9.99632 14.0037 10.5 14.625 10.5C15.2463 10.5 15.75 9.99632 15.75 9.375C15.75 8.75368 15.2463 8.25 14.625 8.25Z"
                        fill="#272A31"
                      />
                    </svg>
                    <span style={{ paddingLeft: "10px" }}>
                      รายละเอียด Media
                    </span>
                  </div>
                </div>
                <div
                  className={
                    "row font14Light paddingLeftRight10 paddingTopBottom20"
                  }
                >
                  <div className={"col-lg-4"}>
                    <div className={"row"}>
                      <div className={"mb-3"}>
                        {MediaFileJson?.MediaDetail?.filetype === "video" ? (
                          <video width="320px" height="240px" controls>
                            <source
                              src={MediaFileJson?.MediaDetail?.filepath}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            className={"p-0 m-0"}
                            style={{width:'200px',objectFit:'cover'}}
                            src={MediaFileJson?.MediaDetail?.filepath}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={"col-lg-8 col-md-6"} style={{ textAlign: "left" }}>
                    <div className={"row"}>
                      <div className={"col-lg-6"}>
                        <div className={"paddingTop20"}>
                          <div className="appFontLight font12">ชื่อไฟล์</div>
                          <div className="subHeaderFont paddingTop5">
                            {MediaFileJson?.MediaDetail?.filename}
                          </div>
                        </div>

                        <div className={"paddingTop20"}>
                          <div className="appFontLight font12">ประเภทไฟล์</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {MediaFileJson?.MediaDetail?.filetype}
                          </div>
                        </div>
                      </div>
                      <div className={"col-lg-6"}>
                        <div className={"paddingTop20"}>
                          <div className="appFontLight font12">ขนาด</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {MediaFileJson?.MediaDetail?.filesize}
                          </div>
                        </div>

                        <div className={"paddingTop20"}>
                          <div className="appFontLight font12">Dimension</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            1080 by 768 Pixels
                            { }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={"row paddingTop20"}>
                      <div className="appFontLight font12">Tags</div>
                      <div className={"subHeaderFont paddingTop5"}>
                        <div className="d-flex align-items-center mr-2" style={{ flexWrap: 'wrap'}}>
                        {!isSelectDisabled ? (
                          <Select
                            styles={{
                              indicatorsContainer: () => ({
                                display: 'none',
                              }), 
                              control: (provided, state) => ({
                                ...provided,
                                boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)" : null,
                                backgroundColor: "none",
                                width:'532px',
                                borderStyle:'none',
                                fontSize:'12px',
                                padding:'0px'
                              }),
                              multiValueRemove: () => ({
                                display: 'none',
                              }),
                              multiValue: (base) => ({
                                ...base,
                                padding: '0px 5px 0px 5px',
                                fontWeight: '500',
                                borderRadius:'3px'
                              }),
                            }}                            
                            isDisabled={!isSelectDisabled}
                            closeMenuOnSelect={false}
                            value={tagList}
                            onChange={handleTagList}
                            isMulti
                            options={options}
                          />
                        ) : (
                          <Select
                            styles={{
                              indicatorsContainer: () => ({
                                display: 'none',
                              }), 
                              control: (provided, state) => ({
                                ...provided,
                                boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)" : null,
                                backgroundColor: "none",
                                width:'532px',
                                height:'32px',
                                fontSize:'12px' 
                              }),
                              multiValue: (base) => ({
                                ...base,
                                padding: '0px 5px 0px 5px',
                                fontWeight: '500',
                                borderRadius:'3px'
                              }),
                            }}                            
                            isDisabled={!isSelectDisabled}
                            closeMenuOnSelect={false}
                            value={tagList}
                            onChange={handleTagList}
                            isMulti
                            options={options}
                          />
                        )}
                        
                        </div>
                      </div>
                      <div className={"paddingTop15"}>
                        <Button
                          style={{ 
                            width: isSelectDisabled ? "136px" : "108px", 
                            height: "32px", 
                            background: "none", 
                            backgroundColor:isSelectDisabled ? "#222226" : "#FFFFFF", 
                            border: "1px solid #343a40", 
                            color: "#343a40",
                            color:isSelectDisabled ? "#FFFFFF" : "#343a40",  
                            display: "flex", 
                            alignItems: "center", 
                            justifyContent: "space-evenly", 
                            fontSize: isSelectDisabled ? "12px" : "16px", 
                             
                          }}
                          className="btn btn-dark"
                          label="แก้ไข Tags"
                          onClick={() => navigateToAccountInfoEdit()}
                        >
                          {!isSelectDisabled && <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M13.3291 3.58547L10.3155 0.792974C10.1132 0.605388 9.83869 0.5 9.55249 0.5C9.26629 0.5 8.99182 0.605388 8.78947 0.792974L0.47416 8.49985C0.270901 8.68674 0.156979 8.94136 0.157894 9.20672V11.9998C0.157894 12.5521 0.640955 12.9998 1.23684 12.9998H12.5658C12.8637 12.9998 13.1053 12.776 13.1053 12.4998C13.1053 12.2237 12.8637 11.9998 12.5658 11.9998H5.77651L13.3291 4.99985C13.5315 4.81231 13.6452 4.55792 13.6452 4.29266C13.6452 4.02741 13.5315 3.77302 13.3291 3.58547ZM4.25048 11.9998H1.23684V9.20672L7.17105 3.70672L10.1847 6.49985L4.25048 11.9998ZM7.93441 2.99985L10.9474 5.79297L12.5658 4.29297L9.55283 1.49985L7.93441 2.99985Z" fill="#131313" />
                          </svg>
                          }
                          <span style={{ marginLeft: "5px",fontSize:'12px' }}>{isSelectDisabled ? 'บันทึก' : "แก้ไข Tags"}</span>
                        </Button>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
