import apiInterceptor from "../../../hooks/apiInterceptor";


export function getMediaList() {

  return apiInterceptor
    .get("/media/list", {

    })
}

export function deleteMediaFileInfo(reqParam) {
  return apiInterceptor
    .post("/media/file/info/delete", reqParam)
}

export function createBanner(reqParam) {
  return apiInterceptor
    .post("/banner/create", reqParam)
}

export function saveBannerFileInfo(reqParam) {
  return apiInterceptor
    .post("/media/file/info/create", reqParam)
}



export function getMediaFileDetail(mediaId) {
  return apiInterceptor
    .get("/media/file/info/detail/" + mediaId, {
      // params: queryParams
    })
}

export function getBannerDetail(reqParam) {
  return apiInterceptor
    .post("/banner/detail", reqParam)
}


export function updateTagsList(reqParam,id) {
  return apiInterceptor
    .post("/media/file/updateTags/"+id, reqParam)
}