import React, { useState, useEffect } from 'react';
import './index.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Form, InputGroup, Table} from 'react-bootstrap';
import SideNavbar from '../../../layouts/SideNavbar';
import ButtonComponent from '../../../components/Button';
import ModalComponent from '../../../components/Modal';
import {
    getUserGroups,
    createUser, 
    getUserListByGroupId
} from '../Services/api';
import {useNavigate} from "react-router-dom";
import 
{
    getInputDate
} from '../../../Utils/DateUtils';

export default function List() {
    const navigate = useNavigate();

    useEffect(() => {
        getUserGroupsWrapper();
      }, []);

    const [userSystemJson, setUserSystemJson] = useState({
        userGroupList : [],
        userList : [],
        userGroupActive : '',
        dataError : '',
        userGroupId : '',
        username : '',
        name : '',
        password : '', 
        lastName : '',
    });

    function getUserGroupsWrapper()
    {    
          getUserGroups()
          .then((res) => 
          {              
            getUserListByGroupIdWrapper(res.data[0].id,res.data);
          })
          .catch((err) => 
          {
            console.log(err);
          });
    }

    function getUserListByGroupIdWrapper(groupId, userGroupList)
    {    
        getUserListByGroupId(groupId)
          .then((res) => 
          { 
            setUserSystemJson(
            {
                ...userSystemJson,
                'userGroupList' : userGroupList,
                'userList' : res.data,
                'userGroupActive' : groupId
            }
            );  
          })
          .catch((err) => 
          {
            console.log(err);
          });
    }

    let modalFooter = 
       (<div className={'container-fluid'}>
            <div style={{textAlign:'center'}} className={'row p-0'}>
                <div className={'col-lg paddingBottom5'} style={{marginTop:'34px',marginBottom:'51px'}}>
                    <button
                        onClick={handleButtonClose}
                            style={{
                            width:'136px',
                            height:'40px',
                            fontWeight:400,
                            fontSize: "16px",
                            padding: "8px 16px",
                            cursor: "pointer",
                            color: "#222226",
                            border:"1px solid #222226",
                            borderRadius:'4px',
                            background: "#FFFFFF",
                            }}
                        >
                        ยกเลิก
                    </button>
                    <span style={{paddingRight:'15px'}}>

                    </span>
                    <button
                            onClick={() => handleButtonSave(userSystemJson)}                            
                            style={{
                            width:'136px',
                            height:'40px',
                            fontWeight:400,
                            fontSize: "16px",
                            padding: "8px 16px",
                            cursor: "pointer",
                            color: "#FFFFFF",
                            border:"1px solid #222226",
                            borderRadius:'4px',
                            background: "#222226",
                            }}
                        >
                        บันทึก
                    </button>
            </div>

            {/*Old Code For Button */}

              {/*  <div className={'col-lg paddingBottom5'}>
                    <ButtonComponent buttonJson={{
                        label : "ยกเลิก",
                        handleClick : handleButtonClose
                    }}>
                    </ButtonComponent>
                    <span style={{paddingRight:'15px'}}>

                    </span>
                    <ButtonComponent buttonJson={{
                        label : "บันทึก",
                        handleClick : handleButtonSave,
                        userSystemJson : userSystemJson
                    }}>

                    </ButtonComponent>
                </div> */}
            </div>
        </div>
        );

    let modalBody = 
       (<div className={'container-fluid'}>
            <div className={'row p-0'}>
                <div className={'col-lg paddingBottom5'}>
                    <span style={{color:'red'}}>
                        *
                    </span>
                    <span>
                        กลุ่มผู้ใช้งาน
                    </span>
                </div>
            </div>
            <div className={'row p-0'}>
                <div className={'col-lg'}>
                    <Form.Select 
                    aria-label="Default select example"
                    name='userGroupId'
                    onChange={handleInputChange}
                    >
                        <option>เลือกกลุ่มผู้ใช้งาน</option>
                        {userSystemJson.userGroupList.map((data, idx) => (
                            <option value={data.id}>{data.usergroupname}</option>
                        ))}
                    </Form.Select>
                </div>
            
            </div>
            <div className={'row sidePadding0 paddingTop10'}>
                <div className={'col-lg paddingBottom5'}>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                            <span  style={{color:'red'}}>
                                *
                            </span>
                            <span>
                                Username
                            </span>
                        </div>
                    </div>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                        <InputGroup>
                            <Form.Control
                            placeholder="ระบุอีเมล์"
                            aria-label="ระบุอีเมล์"
                            aria-describedby="basic-addon1"
                            name='username'
                            onChange={handleInputChange}
                            />
                        </InputGroup>
                        </div>
                    </div>
                </div>
                <div className={'col-lg paddingBottom5'}>
                <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                            <span  style={{color:'red'}}>
                                *
                            </span>
                            <span>
                                Password
                            </span>
                        </div>
                    </div>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                        <InputGroup>
                            <Form.Control
                            placeholder="ระบุรหัสผ่านเพื่อเข้าใช้งานครั้งแรก"
                            aria-label="ระบุรหัสผ่านเพื่อเข้าใช้งานครั้งแรก"
                            aria-describedby="basic-addon1"
                            name='password'
                            onChange={handleInputChange}
                            />
                        </InputGroup>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'row sidePadding0 paddingTop10'}>
                <div className={'col-lg paddingBottom5'}>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                            ชื่อ
                        </div>
                    </div>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                        <InputGroup>
                            <Form.Control
                            placeholder="ระบุชื่อ"
                            aria-label="ระบุชื่อ"
                            aria-describedby="basic-addon1"
                            name='name'
                            onChange={handleInputChange}
                            />
                        </InputGroup>
                        </div>
                    </div>
                </div>
                <div className={'col-lg paddingBottom5'}>
                <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                            นามกสุล
                        </div>
                    </div>
                    <div className={'row sidePadding0 paddingTop10'}>
                        <div className={'col-lg paddingBottom5'}>
                        <InputGroup>
                            <Form.Control
                            placeholder="ระบุนามสกุล"
                            aria-label="ระบุนามสกุล"
                            aria-describedby="basic-addon1"
                            name='lastName'
                            onChange={handleInputChange}
                            />
                        </InputGroup>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'row paddingTop10'} style={{color:'red'}}>
                {userSystemJson.dataError}
            </div>
            <div className={'row sidePadding0 paddingTop10'}>
                {modalFooter}
            </div>
        </div>
        );

    const [modalJson, setModalJson] = useState({
        show : false,
        handleClose : handleButtonClose,
        title : 'เพิ่มผู้ใช้งานระบบจัดการ',
        footer : modalFooter,
        userGroupList : []
    }); 

    function handleButtonClose()
    {
        setModalJson({
            ...modalJson,
            show : false
        });
    }

    function handleButtonClick()
    {
        setUserSystemJson({
            ...userSystemJson,
            'dataError' : '',
            'userGroupId' : '',
            'username' : '',
            'password' : ''
        });

        setModalJson({
            ...modalJson,
            show : true
        });
    }
    
    function handleButtonSave()
    {
        // Check if username is not a valid email
if (!validateEmail(userSystemJson.username)) {
    setUserSystemJson({
        ...userSystemJson,
        'dataError': 'Invalid email address'
    });
    return;
}


if (
    userSystemJson.userGroupId === '' ||
    userSystemJson.username === '' ||
    userSystemJson.password === ''
) {
    setUserSystemJson({
        ...userSystemJson,
        'dataError': 'Mandatory fields missing'
    });
    return;
}


        const reqParam = {
            name : userSystemJson.name,
            lastName : userSystemJson.lastName,
            userGroupId : userSystemJson.userGroupId,
            email : userSystemJson.username,
            password : userSystemJson.password,
            isSystemUser : "Y"
        };

        createUser(reqParam)
        .then((res) => 
        { 
            navigate("/user/system/detail/"+res.data.id);  
            setModalJson({
                ...modalJson,
                show : false
            });        
        })
        .catch((err) => 
        {
            setUserSystemJson(
                {
                    ...userSystemJson,
                    dataError : err.response.data.error
                }
                
            );
            setModalJson({
                ...modalJson,
                show : true
            });
            console.log(err.response.data);
        });

        // setModalJson({
        //     ...modalJson,
        //     show : false
        // });
    }
    const validateEmail = (email) => {
        // Regular expression for validating an Email
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        // Check if email format is valid
        if (!re.test(String(email).toLowerCase())) {
          return false; // Invalid format
        }
      
        // Check if email contains capital letters
        if (email !== email.toLowerCase()) {
          return false; // Contains capital letters
        }
      
        return true; // Email is valid
      };
      
    function handleInputChange(event) {
        const key = event.target.name;
        let updatedUserSystemJson = { ...userSystemJson }; // Make a copy of the state
        if (key === 'username') {
           
            updatedUserSystemJson = {
                ...updatedUserSystemJson,
                'username': event.target.value
            };
        } else if (key === 'name') {
            updatedUserSystemJson = {
                ...updatedUserSystemJson,
                name: event.target.value
            };
        } else if (key === 'lastName') {
            updatedUserSystemJson = {
                ...updatedUserSystemJson,
                lastName: event.target.value
            };
        } else if (key === 'userGroupId') {
            updatedUserSystemJson = {
                ...updatedUserSystemJson,
                userGroupId: event.target.value
            };
        } else if (key === 'password') {
            updatedUserSystemJson = {
                ...updatedUserSystemJson,
                password: event.target.value
            };
        }
        setUserSystemJson(updatedUserSystemJson);
    }
    
   function StatusUpdate(statusData){
    if (statusData === 'true') {
        return (
          <div
            className={"subHeaderFont paddingTop5"}
            style={{ backgroundColor: "#009E4B1A" ,width:'fit-content',height:'28px',padding:'5px',fontSize:'12px',borderRadius:'3px',color:'#009E4B',fontWeight:500}}>
            ACTIVATED
          </div>
        );
    }
    else{
        return (
            <div
              className={"subHeaderFont paddingTop5"}
              style={{ backgroundColor: "#EFF0F1" ,width:'fit-content',height:'28px',padding:'5px',fontSize:'12px',borderRadius:'3px',color:'#6F6F71',fontWeight:500}}>
              DISABLED
            </div>
          );

    }
   }

    const refreshDataSetForSelectedGroup = (groupId) => {
        getUserListByGroupIdWrapper(groupId,userSystemJson.userGroupList);
    };

    const navigateToAccountSettings = (userId) => {
        navigate("/user/system/detail/"+userId);
    };
      
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {

    const filteredResults = userSystemJson.userList.filter(item =>
      (
        (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.lastname && item.lastname.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.username && item.username.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.contactnumber && item.contactnumber.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    );

    setFilteredData(filteredResults);
  }, [searchTerm, userSystemJson]);
  
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <ModalComponent modalJson={modalJson} style={true}>
                    {modalBody}
                </ModalComponent>
                <div className='row'>
                <div className="col-2 p-0"  style={{width: "240px"}} >
                    <SideNavbar code="userSystemList" />
                </div> 
                <div className="col-9" style={{width:'1080px'}}>
                    <Row>
                        <Col style={{padding : '15px'}}>
                        <div className='headerTitleDiv headerFont'>
                            ผู้ใช้งานในระบบ
                        </div>
                        <div className='headerActionButtonDiv'>
                        <button
                        onClick={handleButtonClick}
                            style={{
                            width:'136px',
                            height:'40px',
                            background: "#131313", // Change background color if needed
                            color: "#FFFFFF",
                            fontSize: "14px",
                            fontWeight: "bold",
                            border: "none",
                            borderRadius: "4px",
                            padding: "8px 16px", // Adjust padding as needed
                            cursor: "pointer",
                            fontWeight:400
                            }}
                        >
                        เพิ่มผู้ใช้งาน
                        </button>
                        </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2} className='p-0' style={{ width: '169px',overflow:'hidden' }}>
                        <div style={{ height: '100vh', textAlign: 'left', background: '#EFF0F1', display: 'flex', flexDirection: 'column' }}>
                            {userSystemJson?.userGroupList && userSystemJson.userGroupList.map((data, idx) => (
                                data.usergroupname === 'ประวัติการเข้าใช้งาน' ? (
                                    <div key={idx} className={'pointer'} style={{ marginTop:'20px',marginLeft:'20px',width: '131px'}} onClick={() => navigate("/accesshistory/list")}>
                                        <div 
                                            style={{ 
                                                height: "24px", 
                                                flexWrap: 'nowrap', 
                                                overflow: 'hidden', 
                                                textOverflow: 'ellipsis', 
                                                whiteSpace: 'nowrap',
                                            }} 
                                            className={(data.id === userSystemJson.userGroupActive ? 'menuActive' : '')}>
                                            <div 
                                                style={{
                                                fontSize: '12px', 
                                                fontWeight: 600, 
                                                marginTop:'4px',
                                                marginLeft:'11px',
                                                marginRight:'10px'
                                                }}
                                            >
                                            {data.usergroupname}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div key={idx} className={'pointer'} style={{ marginTop:'20px',marginLeft:'20px',width: '131px'}} onClick={() => refreshDataSetForSelectedGroup(data.id)}>
                                        <div 
                                            style={{ 
                                                height: "24px", 
                                                flexWrap: 'nowrap', 
                                                overflow: 'hidden', 
                                                textOverflow: 'ellipsis', 
                                                whiteSpace: 'nowrap',
                                            }} 
                                            className={(data.id === userSystemJson.userGroupActive ? 'menuActive' : '')}>
                                            <div 
                                                style={{
                                                fontSize: '12px', 
                                                fontWeight: 600, 
                                                marginTop:'4px',
                                                marginLeft:'11px',
                                                marginRight:'10px'
                                                }}
                                            >
                                            {data.usergroupname}
                                            </div>
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>
                        </Col>
                        <Col lg={10}>
                            <div style={{paddingLeft : '20px'}}>
                                <div style={{paddingTop : '10px'}}>
                                <InputGroup style={{ width: "532px",height:'32px' }}>
                                    <InputGroup.Text
                                        style={{ background: "#131313" }}
                                        id="basic-addon1"
                                    >
                                        <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        >
                                        <path
                                            d="M12.2029 10.7653L15.7811 14.3435C16.0729 14.6354 16.0738 15.1077 15.7785 15.403V15.403C15.4852 15.6963 15.0114 15.6979 14.7161 15.4026L11.1826 11.8691C9.99708 12.8963 8.45056 13.5178 6.75888 13.5178C3.02605 13.5178 0 10.4917 0 6.75888C0 3.02605 3.02605 0 6.75888 0C10.4917 0 13.5178 3.02605 13.5178 6.75888C13.5178 8.25856 13.0293 9.64417 12.2029 10.7653ZM12.0158 6.75888C12.0158 3.85557 9.66218 1.50197 6.75888 1.50197C3.85557 1.50197 1.50197 3.85557 1.50197 6.75888C1.50197 9.66218 3.85557 12.0158 6.75888 12.0158C9.66218 12.0158 12.0158 9.66218 12.0158 6.75888Z"
                                            fill="#F3F4F8"
                                        />
                                        </svg>
                                    </InputGroup.Text>
                                    <Form.Control
                                        style={{fontSize:'12px'}}
                                        placeholder="ค้นหาด้วยชื่อลูกค้า อีเมล์ หรือเบอร์โทร"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                    </InputGroup>
                                </div>

                                <div style={{paddingTop : '20px',overflowX: "auto",overflowY:'hidden',whiteSpace:'nowrap'}}>
                                    <Table >
                                        <thead>
                                            <tr style={{fontSize:'12px',fontWeight:500}}>
                                            <th>ลำดับ</th>
                                            <th>รหัสผู้ใช้งาน</th>
                                            <th>ชื่อ-นามสกุล</th>
                                            <th>Username</th>
                                            <th>สถานะ</th>
                                            <th>วันที่อัพเดต</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {filteredData?.length === 0 ? (
                                            <tr>
                                              <td colSpan="12" className="textAlignCenter">
                                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="24" cy="24" r="24" fill="#F3F4F8"/>
                                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 24C14.25 18.6152 18.6152 14.25 24 14.25C29.3824 14.2557 33.7443 18.6176 33.75 24C33.75 29.3848 29.3848 33.75 24 33.75C18.6152 33.75 14.25 29.3848 14.25 24ZM15.75 24C15.75 28.5563 19.4437 32.25 24 32.25C28.5542 32.2448 32.2448 28.5542 32.25 24C32.25 19.4437 28.5563 15.75 24 15.75C19.4437 15.75 15.75 19.4437 15.75 24ZM23.25 24.75V19.5C23.25 19.0858 23.5858 18.75 24 18.75C24.4142 18.75 24.75 19.0858 24.75 19.5V24.75C24.75 25.1642 24.4142 25.5 24 25.5C23.5858 25.5 23.25 25.1642 23.25 24.75ZM24 29.25C24.6213 29.25 25.125 28.7463 25.125 28.125C25.125 27.5037 24.6213 27 24 27C23.3787 27 22.875 27.5037 22.875 28.125C22.875 28.7463 23.3787 29.25 24 29.25Z" fill="#272A31"/>
                                                </svg> 
                                                
                                                <div className="opacity-50 font12">ไม่มีข้อมูลในระบบ</div>
                                              </td>
                                            </tr>
                                          ): (
                                        (filteredData.map((data, idx) => (
                                             <tr className="font14Light">
                                             <td>{idx + 1}</td>
                                             <td className='pointer' onClick={() => navigateToAccountSettings(data.id)} >{data.id}</td>
                                             <td>{data.name && data.lastname ? `${data.name} ${data.lastname}` : 'No name available'}</td>
                                             <td>{data.email}</td>
                                             <td>{StatusUpdate(data.status)}</td>
                                             <td>{getInputDate(data.createdon)}</td>
                                             </tr>
                                        )))
                                        )}                                          
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div> 
                </div>
            </div>  
        </React.Fragment>
             
    );
  }