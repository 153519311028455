import React, { useState, useEffect,useMemo } from "react";
import SideNavbar from "../../../layouts/SideNavbar";
import { Row, Col } from "react-bootstrap";
import { Form, InputGroup, Table } from "react-bootstrap";
import { getUserList } from "../Services/api";
import { useNavigate } from "react-router-dom";
import 
{
    getInputDate
} from '../../../Utils/DateUtils';

export default function List() {
  const paginatorPage = {
    background: "transparent",
    border: "none",
    margin: "10px",
  };
  const navigate = useNavigate();

  useEffect(() => {
    getUserListWrapper();
  }, []);

  const [userJson, setUserJson] = useState({
    userList: [],
  });

  function getUserListWrapper() {
    getUserList()
      .then((res) => {
        setUserJson({
          ...userJson,
          userList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function navigateToUserDetail(userId) {
    navigate("/user/detail/" + userId + "/info");
  }

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const allStatusValues = userJson.userList.map((user) => user.status);
  const data = userJson.userList

  const [currentPage, setCurrentPage] = useState(0);
  const perPage = 10;

  // Pagination logic
  const totalFilteredItems = filteredData.length;
  const totalPages = Math.ceil(totalFilteredItems / perPage);

  // Update currentPage if it exceeds the total number of pages after filtering
  useEffect(() => {
    if (totalPages > 0 && currentPage >= totalPages) {
      setCurrentPage(totalPages - 1);
    }
  }, [totalPages]);

  // Update startIndex and endIndex based on currentPage
  const startIndex = currentPage * perPage;
  const endIndex = Math.min(startIndex + perPage, totalFilteredItems);
  const slicedCardData = filteredData.slice(startIndex, endIndex);

  // Function to filter by search term
  const filterBySearchTerm = () => {
    return data.filter(
      (item) =>
      (item.name &&
        item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.lastname &&
        item.lastname.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.contactnumber &&
        item.contactnumber.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  };

   // Function to filter by selected status
  const filterBySelectedStatus = () => {
    if (selectedStatus !== "") {
      return data.filter((user) => user.status === selectedStatus);
    } else {
      return data;
    }
  };


  // Function to apply filters and update filtered data
    const filteredResults = () => {
    const searchData = filterBySearchTerm();
    const statusData = filterBySelectedStatus();
    
    // Combine filtered results
    let combinedResults = searchTerm ? searchData : statusData;
    
    setFilteredData(combinedResults);
    setCurrentPage(0); // Reset page to first page after filtering
  };

     // Effect to apply filters when search term, selected status, or dates change
     useEffect(() => {
      filteredResults();
    }, [searchTerm, selectedStatus, data]);

      
    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
      setSelectedStatus("");
    };

    const handleDivClick = (status) => {
      const formattedStatus =
      (status === toString(true) || 
      status === toString(false) )
        ? status.toUpperCase()
        : status.toLowerCase();
    
    setSelectedStatus(formattedStatus);
    setSelectedStatus(status);
    setSearchTerm(""); 
    };
    
  function StatusUpdate(statusData, emailverify, emailotp){
    if ((emailverify === "Y" && emailotp !== null) && statusData === "true") {
        return (
          <div
            className={"subHeaderFont paddingTop5"}
            style={{ backgroundColor: "#009E4B1A" ,width:'fit-content',height:'28px',padding:'5px',borderRadius:'3px',color:'#009E4B',fontWeight:500,fontSize:'12px'}}
          >
            ACTIVATED
          </div>
        );
    }
    else if (statusData === "true") {
      return (
          <div
              className={"subHeaderFont paddingTop5"}
              style={{
                  backgroundColor: "#009E4B1A",
                  width: 'fit-content',
                  height: '28px',
                  padding: '5px',
                  borderRadius: '3px',
                  color: '#009E4B',
                  fontWeight: 500,
                  fontSize: '12px'
              }}
          >
              ACTIVATED
          </div>
      );
  } else if (emailverify !== 'Y') {
      return (
          <div
              className={"subHeaderFont paddingTop5"}
              style={{
                  backgroundColor: "#FFE2D3",
                  width: 'fit-content',
                  height: '28px',
                  padding: '5px',
                  borderRadius: '3px',
                  color: '#F57500',
                  fontWeight: 500,
                  fontSize: '12px'
              }}
          >
              Waiting for email verification
          </div>
      );


    }else if(emailotp === null){
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{ backgroundColor: "#FFE2D3" ,width:'fit-content',height:'28px',padding:'5px',borderRadius:'3px',color:'#F57500',fontWeight:500,fontSize:'12px'}}
        >
          Waiting for email OTP
        </div>
      );

    }
    else {
        return (
            <div
              className={"subHeaderFont paddingTop5"}
              style={{ backgroundColor: "#FFD2DA" ,width:'fit-content',height:'28px',padding:'5px',borderRadius:'3px',color:'#F80042',fontWeight:500,fontSize:'12px'}}
            >
              BANNED
            </div>
          );
    }
   }
  const paidUsersCount = userJson.userList.filter(
    (user) => user.status === "true"
  ).length;
  const pendingUsersCount = userJson.userList.filter(
    (user) => user.status === "false"
  ).length;
  

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 p-0"  style={{width: "240px"}} >
          <SideNavbar code="userList" />
        </div>
        <div className="col-9" style={{width:'1080px'}}>
          <Row>
            <Col style={{ padding: "15px" }}>
              <div className="headerTitleDiv headerFont">
                รายชื่อสมาชิกผู้ใช้งานเว็บไซต์
              </div>
            </Col>
          </Row>
          <Row style={{ padding: "15px" }} className="">
            <div onClick={() => handleDivClick("")}  className="col-3 square border border-1 border-dark me-4 d-flex  justify-content-between cursor-pointer" style={{fontSize:'12px',borderRadius:'4px',height:'46px',width:"260px",paddingLeft:"0px"}}>
              <Col style={{ backgroundColor: selectedStatus === "" ? 'black' : '' }} lg={1}></Col>
              <Col lg={7} className="me-4 p-2">สมาชิกทั้งหมด</Col>
              <Col lg={3} style={{fontSize:'24px',fontWeight:600}}>{userJson.userList.length && userJson.userList.length}</Col>
            </div>
            <div  onClick={() => handleDivClick("true")} className="col-3  square border border-1 border-dark me-4 d-flex  justify-content-between cursor-pointer"  style={{fontSize:'12px',borderRadius:'4px',height:'46px',width:"260px",paddingLeft:"0px"}}>
              <Col style={{ backgroundColor: selectedStatus === "true" ? 'black' : '' }}  lg={1}></Col>
              <Col lg={7} className="me-4 p-2">สมาชิกที่ยืนยึนตัวตน</Col>
              <Col lg={3} style={{fontSize:'24px',fontWeight:600}}>{paidUsersCount && paidUsersCount}</Col>
            </div>
            <div  onClick={() => handleDivClick("false")}  className="col-3 square border border-1 border-dark me-4 d-flex justify-content-between cursor-pointer"  style={{fontSize:'12px',borderRadius:'4px',height:'46px',width:"260px",paddingLeft:"0px"}}>
              <Col style={{ backgroundColor: selectedStatus === "false" ? 'black' : '' }}  lg={1}></Col>
              <Col lg={7} className="me-4 p-2">สมาชิกรอยืนยันตัวตน</Col>
              <Col lg={3} style={{fontSize:'24px',fontWeight:600}}>{pendingUsersCount && pendingUsersCount}</Col>
            </div>
          </Row>
          <Row>
            <Col style={{ paddingLeft: "15px" }}>
              <div style={{ paddingTop: "10px" }}>
                <InputGroup style={{ width: "532px",height:'32px'  }}>
                  <InputGroup.Text
                    style={{ background: "#131313" }}
                    id="basic-addon1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M12.2029 10.7653L15.7811 14.3435C16.0729 14.6354 16.0738 15.1077 15.7785 15.403V15.403C15.4852 15.6963 15.0114 15.6979 14.7161 15.4026L11.1826 11.8691C9.99708 12.8963 8.45056 13.5178 6.75888 13.5178C3.02605 13.5178 0 10.4917 0 6.75888C0 3.02605 3.02605 0 6.75888 0C10.4917 0 13.5178 3.02605 13.5178 6.75888C13.5178 8.25856 13.0293 9.64417 12.2029 10.7653ZM12.0158 6.75888C12.0158 3.85557 9.66218 1.50197 6.75888 1.50197C3.85557 1.50197 1.50197 3.85557 1.50197 6.75888C1.50197 9.66218 3.85557 12.0158 6.75888 12.0158C9.66218 12.0158 12.0158 9.66218 12.0158 6.75888Z"
                        fill="#F3F4F8"
                      />
                    </svg>
                  </InputGroup.Text>
                  <Form.Control
                    style={{fontSize:'12px'}}
                    placeholder="ค้นหาด้วยชื่อลูกค้า อีเมล์ หรือเบอร์โทร"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </InputGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ paddingTop: "20px",overflowX: "auto",overflowY:'hidden',whiteSpace:'nowrap' }}>
                <Table>
                  <thead>
                    <tr style={{fontSize:'12px',fontWeight:500}}>
                      <th>ลำดับ</th>
                      <th>รหัสผู้ใช้งาน</th>
                      <th>ชื่อ-นามสกุล</th>
                      <th>อีเมล์</th>
                      <th>เบอร์ติดต่อ</th>
                      <th>สถานะ</th>
                      <th>วันที่อัพเดต</th>
                    </tr>
                  </thead>
                  <tbody>
                  {slicedCardData?.length === 0 ? (
                    <tr>
                      <td colSpan="12" className="textAlignCenter">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="24" cy="24" r="24" fill="#F3F4F8"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 24C14.25 18.6152 18.6152 14.25 24 14.25C29.3824 14.2557 33.7443 18.6176 33.75 24C33.75 29.3848 29.3848 33.75 24 33.75C18.6152 33.75 14.25 29.3848 14.25 24ZM15.75 24C15.75 28.5563 19.4437 32.25 24 32.25C28.5542 32.2448 32.2448 28.5542 32.25 24C32.25 19.4437 28.5563 15.75 24 15.75C19.4437 15.75 15.75 19.4437 15.75 24ZM23.25 24.75V19.5C23.25 19.0858 23.5858 18.75 24 18.75C24.4142 18.75 24.75 19.0858 24.75 19.5V24.75C24.75 25.1642 24.4142 25.5 24 25.5C23.5858 25.5 23.25 25.1642 23.25 24.75ZM24 29.25C24.6213 29.25 25.125 28.7463 25.125 28.125C25.125 27.5037 24.6213 27 24 27C23.3787 27 22.875 27.5037 22.875 28.125C22.875 28.7463 23.3787 29.25 24 29.25Z" fill="#272A31"/>
                        </svg> 
                        
                        <div className="opacity-50 font12">ไม่มีข้อมูลในระบบ</div>
                      </td>
                    </tr>
                  ): (
                    (slicedCardData.map((data, idx) => (
                      <tr  className="font14Light">
                        <td>{idx + 1}</td>
                        <td
                          className="pointer"
                          onClick={() => navigateToUserDetail(data.id)}
                        >
                          {data.id}
                        </td>
                        <td>
                          {data.name}
                        </td>
                        <td>{data.email}</td>
                        <td>{data.contactnumber}</td>
                        <td>{StatusUpdate(data.status, data.emailverified, data.emailotp)}</td>
                        <td>{getInputDate(data.createdon)}</td>
                      </tr>
                    )))
                  )}
                  </tbody>
                </Table>
              </div>
            </Col>
            {filteredData.length > perPage && (
              <div className="flex text-center gap-8 mt-20 ">
                {Array.from(
                  { length: Math.ceil(filteredData.length / perPage) },
                  (_, index) => (
                    <button
                      key={index}
                      className={`paginator-page text-black ${
                        currentPage === index && "active"
                      }`}
                      onClick={() => setCurrentPage(index)}
                      style={{ fontWeight: currentPage === index ? "bold" : "normal", ...paginatorPage }}
                    >
                      {index + 1}
                    </button>
                  )
                )}
              </div>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}
