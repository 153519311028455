import { useEffect, useRef, useState } from "react";
import ButtonComponent from "../Button";
import { BeatLoader } from "react-spinners";
import fileUploadApiInterceptor from "../../hooks/fileUploadApiInterceptor";
import { Form, InputGroup, Table } from "react-bootstrap";
import {
  createBanner,
  saveBannerFileInfo,
  getBannerDetail,
  updateBanner,
  deleteMediaFileInfo,
  getMediaList,
} from "../../modules/MediaLibrary/Services/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ExclamationCircleFill, XCircleFill } from "react-bootstrap-icons";
import ImageSizeDisplay from "../ImageSizeDisplay/ImageSizeDisplay";

const MediaLibraryFile = (props) => {
  const navigate = useNavigate();

  const [bannerCreateJson, setBannerCreateJson] = useState({
    tmpFilePathlist: [],
    activeFileUploadTab: "image",
    dataError: "",
    fileContainerList: [
      {
        containerid: 0,
      },
    ],
    activeFileSelectJson: {
      filetype: "image",
    },
    formFieldList: [
      {
        name: "bannertopic",
        key: "bannertopic",
        isMandatory: true,
      },
    ],
    
  });
  const [fileList, setFileList] = useState([]);
  const [fileDelte, setFileDelete] = useState();
  const [fileType, setFileType] = useState("image");
  const hiddenFileInput = useRef(null);
  const hiddenFileInputDesktopImage = useRef(null);
  const hiddenFileInputDesktopVideo = useRef(null);

  const [state, setState] = useState({
    saveButton: false,
    hidebutton: true,
    isLoading: false,
  });
  const [selectedImages, setSelectedImages] = useState([]);
  const [MediaListJson, setMediaListJson] = useState({});

  useEffect(() => {
    getMediaListWrapper();
  }, []);
  const handleButtonClick = (data) => {
    Swal.fire({
      title: "คุณแน่ใจไหม??",
      text: "คุณจะไม่สามารถย้อนกลับสิ่งนี้ได้!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่ ลบมันออก!",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqParam = {
          fileId: data.id,
        };

        deleteMediaFileInfo(reqParam)
        .then(() => {
          navigate("/medialibrary/list");
          })
          .then((result) => {
            toast.success("คุณทำรายการสำเร็จ", {
              position: "top-left",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              style: {
                width: "1030px",
                minHeight: "68px",
                backgroundColor: "#D4F9E7",
                color: "#009E4B",
                fontSize: "18px",
                fontWeight: 600,
                marginLeft: "250px",
              },
            })
          })
          .catch((error) => {
            toast.error("เกิดข้อผิดพลาด ไม่สามารถทำรายการได้", {
              position: "top-left",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              icon: <XCircleFill />,
              style: {
                width: "1030px",
                minHeight: "68px",
                backgroundColor: "#FEE5E4",
                color: "#EA3348",
                fontSize: "18px",
                fontWeight: 600,
                marginLeft: "250px",
              },
            });
            console.error(error.message);
          });
      }
    });
  };
  function getMediaListWrapper() {
    getMediaList()
      .then((result) => {
        if (result.data.status === "SUCCESS") {
          setMediaListJson({
            ...MediaListJson,
            mediaList: result.data.response,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function saveBannerFileInfoWrapper() {
    var userDetail = JSON.parse(sessionStorage.getItem("userDetail"));
    for (var i = 0; i < bannerCreateJson.tmpFilePathlist.length; i++) {
      var reqParam = bannerCreateJson.tmpFilePathlist[i];
      reqParam.fileData = null;
      reqParam.tmpPath = null;
      reqParam.bannerid = null;
      reqParam.product_id = null;
      reqParam.filename = reqParam.fileName;
      reqParam.filepath = reqParam.filePath;
      reqParam.filetype = reqParam.fileType;
      reqParam.contenttype = reqParam.contentType;
      reqParam.filesize = reqParam.fileSize;
      reqParam.mediatype = "Media";
      reqParam.userId = userDetail.userId;

      // const fileCategory = reqParam.filecat === 'mobile' ? 'mobile' : 'desktop';

      // Add the file category to reqParam
      reqParam.filecat = reqParam.filecat;

      saveBannerFileInfo(reqParam)
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          // handle errors
          // console.log(error);
        });
    }
  }
  function handleFileUploadButtonClose() {
    setFileUploadModalJson({
      ...fileUploadModalJson,
      show: false,
    });
    saveBannerFileInfoWrapper();
  }
  const [fileUploadModalJson, setFileUploadModalJson] = useState({
    show: false,
    title: "Media Library",
    handleClose: handleFileUploadButtonClose,
  });

  function toggleFileUploadTab(activeTab) {
    setBannerCreateJson({
      ...bannerCreateJson,
      dataError: "",
      activeFileUploadTab: activeTab,
    });
  }

  const enableFileSelect = () => {
    let updatedFileSelectJson = { ...bannerCreateJson.activeFileSelectJson };
    if (bannerCreateJson.activeFileUploadTab === "image") {
      hiddenFileInputDesktopImage.current.click();
      setFileType("image");
      updatedFileSelectJson.filetype = "image";
    } else if (bannerCreateJson.activeFileUploadTab === "video") {
      hiddenFileInputDesktopVideo.current.click();
      setFileType("video");
      updatedFileSelectJson.filetype = "video";
    } else {
      hiddenFileInput.current.click();
    }

    setBannerCreateJson({
      ...bannerCreateJson,
      activeFileSelectJson: updatedFileSelectJson,
    });
   
  };
  function uploadBannerFile() {
    
  
    setBannerCreateJson((prevBannerCreateJson) => ({
      ...prevBannerCreateJson,
      dataError: "Uploading.. Please wait",
    }));
  
    const fileList = bannerCreateJson.tmpFilePathlist;
    const filesToUpload = fileList.filter(fileItem => !fileItem.isuploaded);
    const totalFilesToUpload = filesToUpload.length;
 
    let progCount = 0;
  
    filesToUpload.forEach((fileItem, index) => {
      const file = fileItem.fileData;
  
      const formData = new FormData();
      formData.append("file", file);
      formData.append("filePath", fileItem.filePath);
      formData.append("fileName", fileItem.fileName);
      formData.append("filesize", fileItem.fileSize);
  
      fileUploadApiInterceptor
        .post("/common/file/upload", formData)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            // Update the state with the uploaded file information
            setBannerCreateJson((prevBannerCreateJson) => {
              const updatedFileList = prevBannerCreateJson.tmpFilePathlist.map((item, idx) => {
                if (item.fileName === fileItem.fileName && item.filePath === fileItem.filePath) {
                  return { ...item, isuploaded: true };
                }
                return item;
              });
  
              return {
                ...prevBannerCreateJson,
                tmpFilePathlist: updatedFileList,
                dataError: "",
              };
            });
  
            // Check if all files are processed
            progCount++;
            if (progCount === totalFilesToUpload) {
              setState((prevState) => ({
                ...prevState,
                isLoading: false,
                saveButton: true,
              }));
  
              setFileUploadModalJson((prevFileUploadModalJson) => ({
                ...prevFileUploadModalJson,
                show: true,
                dataError: "",
              }));
            }
          } else {
            setBannerCreateJson((prevBannerCreateJson) => ({
              ...prevBannerCreateJson,
              dataError: "Error.. Try again",
              tmpFilePathlist: [],
            }));
          }
        })
        .catch((error) => {
          progCount++;
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            saveButton: false
          }));
         
          if (error.response.status === 400) {
            // Assuming 413 is the status code for file size error
            toast.error('File size should not exceed Limit');
          }
          setBannerCreateJson((prevBannerCreateJson) => ({
            ...prevBannerCreateJson,
            dataError: "Error.. Try again",
            tmpFilePathlist: [],
          }));
        });
    });
  }
  

  function formatBytes(bytes) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  }
  function handleFileUploadButtonClick(event) {
    setFileUploadModalJson({
      ...fileUploadModalJson,
      show: true,
    });
  }
  // function handleImgaeUpload(event, existingImageData) {
  //   var userDetail = JSON.parse(sessionStorage.getItem("userDetail"));

  //   var fileList = []; // Initialize an empty array for the file list

  //   var timestamp = new Date().getTime();
  //   if (!existingImageData) {
  //     // Handle new file upload
  //     if (event.target.files.length > 0) {
  //       var tmppath = URL.createObjectURL(event.target.files[0]);
  //       var filesize = formatBytes(event.target.files[0].size);
  //       var originalFilename = event.target.files[0].name;
  //       var filenameWithoutSpaces = originalFilename.replace(/\s/g, "_"); // Replace spaces with underscores

  //       var fileJson = {
  //         image_path_tmp_id: timestamp,
  //         tmpPath: tmppath,
  //         fileType: bannerCreateJson.activeFileSelectJson.filetype,
  //         fileName: filenameWithoutSpaces,
  //         fileData: event.target.files[0],
  //         idx: 0,
  //         fileId: null,
  //         filePath: "Media/" + userDetail.userId + "/" + new Date().getTime(),
  //         contentType: event.target.files[0].type,
  //         fileSize: filesize,
  //         filecat: "desktop",
  //         containerid: bannerCreateJson.activeFileSelectJson.containerid,
  //         isuploaded :false
  //       };

  //       fileList.push(fileJson);
  //     }
  //   } else if (existingImageData && existingImageData.length > 0) {
  //     // Handle existing image data

  //     var existingImage = existingImageData[0]; // Assuming existingImageData is an array, consider only the first element
  //     var fileJson = {
  //       tmpPath: existingImage.filepath,
  //       fileType: existingImage.filetype,
  //       fileName: existingImage.filename,
  //       idx: 0, // Assuming index 0
  //       fileId: null,
  //       filePath: existingImage.filepath,
  //       contentType: existingImage.contenttype,
  //       fileSize: existingImage.filesize,
  //       filecat: existingImage.filecat,
  //       containerid: existingImage.containerid,
  //       isuploaded :true
  //     };
  //     fileList.push(fileJson);
  //   }

  //   if (fileList.length > 0) {
  //     console.log("inside condiii888")
  //     setBannerCreateJson({
  //       ...bannerCreateJson,
  //       tmpFilePathlist: fileList,
  //     });
  //   }
  //   if (fileList.length> 0 && !existingImageData) {
  //     console.log("inside condiii")
  //     setState((prevState) => ({
  //       ...prevState,
  //       isLoading: true,
  //     }));
    
  //     uploadBannerFile();
  //   }
  // }
  function handleImgaeUpload(event, existingImageData) {
    var userDetail = JSON.parse(sessionStorage.getItem("userDetail"));

    var newFileList = []; // Initialize an empty array for the new file list
    var timestamp = new Date().getTime();

    if (!existingImageData) {
      // Handle new file upload
      if (event.target.files.length > 0) {
        var tmppath = URL.createObjectURL(event.target.files[0]);
        var filesize = formatBytes(event.target.files[0].size);
        var originalFilename = event.target.files[0].name;
        var filenameWithoutSpaces = originalFilename.replace(/\s/g, "_"); // Replace spaces with underscores

        var fileJson = {
          image_path_tmp_id: timestamp,
          tmpPath: tmppath,
          fileType: bannerCreateJson.activeFileSelectJson.filetype,
          fileName: filenameWithoutSpaces,
          fileData: event.target.files[0],
          idx: 0,
          fileId: null,
          filePath: "Media/" + userDetail.userId + "/" + new Date().getTime(),
          contentType: event.target.files[0].type,
          fileSize: filesize,
          filecat: "desktop",
          containerid: bannerCreateJson.activeFileSelectJson.containerid,
          isuploaded: false
        };

        newFileList.push(fileJson);
      }
    } else if (existingImageData && existingImageData.length > 0) {
      // Handle existing image data

      var existingImage = existingImageData[0]; // Assuming existingImageData is an array, consider only the first element
      var fileJson = {
        tmpPath: existingImage.filepath,
        fileType: existingImage.filetype,
        fileName: existingImage.filename,
        idx: 0, // Assuming index 0
        fileId: null,
        filePath: existingImage.filepath,
        contentType: existingImage.contenttype,
        fileSize: existingImage.filesize,
        filecat: existingImage.filecat,
        containerid: existingImage.containerid,
        isuploaded: true
      };
      newFileList.push(fileJson);
    }

    setFileList(newFileList);
  }

  // Watch for changes in fileList to update bannerCreateJson
  useEffect(() => {
    if (fileList.length > 0) {
      setBannerCreateJson(prevBannerCreateJson => ({
        ...prevBannerCreateJson,
        tmpFilePathlist: fileList
      }));
    }
  }, [fileList]);

  useEffect(() => {
    const newFilesToUpload = bannerCreateJson.tmpFilePathlist.filter(file => !file.isuploaded);
    if (newFilesToUpload.length > 0) {
      setState(prevState => ({
        ...prevState,
        isLoading: true
      }));

      uploadBannerFile(newFilesToUpload);
    }
  }, [bannerCreateJson.tmpFilePathlist]);


  function removeFile(fileJson) {
    handleButtonClick(fileJson);
  }

  const toggleSelection = (e, data) => {
    setFileDelete(data);
    const imageSelected = selectedImages.includes(data);
    // const imageSelected = e.target.checked;

    let updatedImages;

    if (!imageSelected) {
      updatedImages = [data]; // Select the clicked image
    } else {
      updatedImages = []; // Deselect the clicked image
    }

    setSelectedImages(updatedImages);
    setState({
      ...state,
      saveButton: true,
    });
    handleImgaeUpload(e, updatedImages);
  };

  let modalFooter = (
    <div className={"container-fluid"}>
      <div
        style={{ textAlign: "end", display: "flex", float: "inline-end" }}
        className={"row p-0"}
      >
        <div className={"col-6 "}>
          <ButtonComponent
            buttonJson={{
              label: "Save",
              handleClick: handleFileUploadButtonClose,
            }}
          ></ButtonComponent>
        </div>
      </div>
    </div>
  );

  let duplicateMediaListJson = MediaListJson?.mediaList?.MediaImages;
  if(props?.additionalProps?.searchTerm !== '' && props?.additionalProps?.searchTerm !== undefined && props?.additionalProps?.searchTerm !== null){
    duplicateMediaListJson = duplicateMediaListJson.filter(e => e.filename.includes(props?.additionalProps?.searchTerm));
  }


  return (
    <div className={"container-fluid"}>
      <div>
        <div
          className={"row p-0"}
          style={{ borderBottom: "1px solid rgb(222, 226, 230)" }}
        >
          <div className={"col-lg-7 paddingBottom5"}>
            <span
              onClick={() => toggleFileUploadTab("image")}
              style={{
                padding: "10px",
                borderBottom:
                  bannerCreateJson.activeFileUploadTab === "image"
                    ? "2px solid black"
                    : "none",
              }}
              className="pointer"
            >
              Image
            </span>
            <span style={{ padding: "10px" }}></span>
            <span
              onClick={() => toggleFileUploadTab("video")}
              style={{
                padding: "10px",
                borderBottom:
                  bannerCreateJson.activeFileUploadTab === "video"
                    ? "2px solid black"
                    : "none",
              }}
              className="pointer"
            >
              Video
            </span>
          </div>
          <div
            className="col-5 d-flex justify-content-end  paddingBottom5"
            style={{ textAlign: "right" }}
          >

            <div className="col-3" style={{ marginRight: "10px" }}>
              <div>
             
                <ButtonComponent
                  buttonJson={{
                    label: "เลือกไฟล์",
                    handleClick: enableFileSelect,
                    style: "edit",
                  }}
                />
              </div>
              
            </div>
            {state.isLoading && (
         <button style={{ background :"black",color:"white",border:"rounded"}} disabled>
         <span className="spinner-grow spinner-grow-sm"></span>
         Uploading..
       </button>
      )}
            {/* {state.hidebutton && (
              <div>
                <ButtonComponent
                  buttonJson={{
                    label: "อัพโหลดไฟล์",
                    handleClick: uploadBannerFile,
                  }}
                />
              </div>
            )} */}
          </div>
        </div>

        <div className={"row sidePadding0"}>
          <div className={"row-lg"} style={{ overflowY: "hidden" }}>
            {bannerCreateJson?.tmpFilePathlist && (
              <div>
                <div className={"row p-0 justify-content-center"}>
                  {bannerCreateJson.tmpFilePathlist.map(
                    (data, idx) =>
                      data.tmpPath && (
                        <div
                          style={{
                            position: "relative",
                            margin: "10px",
                            width: "300px",
                            height: "160px",
                          }}
                          key={idx}
                        >
                          <div
                            style={{
                              position: "relative",
                              overflow: "hidden",
                              width: "250px",
                              height: "160px",
                            }}
                          >
                            <div >
                              {data.fileType === "video" ? (
                                <div
                                  style={{ width: "250px", height: "190px" }}
                                >
                                  <video
                                    autoPlay
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: "4px",
                                      objectFit: "cover",
                                      visibility:
                                        bannerCreateJson.activeFileUploadTab ===
                                        "video"
                                          ? ""
                                          : "",
                                    }}
                                    className={"p-0 m-0 pointer"}
                                    src={data.tmpPath}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{ width: "250px", height: "150px" }}
                                >
                                  <img
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: "4px",
                                      visibility:
                                        bannerCreateJson.activeFileUploadTab ===
                                        "image"
                                          ? ""
                                          : "",
                                    }}
                                    className={"p-0 m-0 pointer"}
                                    src={data.tmpPath}
                                    alt={`Image ${idx}`}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
                {(bannerCreateJson.tmpFilePathlist &&
                  bannerCreateJson.tmpFilePathlist.length > 0) ||
                (selectedImages && selectedImages.length > 0) ? (
                  <div
                    className=""
                    style={{ border: "1px solid rgb(222, 226, 230)" }}
                  ></div>
                ) : null}
              </div>
            )}
            <></>
          </div>


          <div
            className={"col-lg paddingTop10 paddingBottom5"}
            style={{ overflowY: "scroll", height: "320px" }}
          >
            {duplicateMediaListJson? (
              duplicateMediaListJson.map((data, idx) => {
                const isChecked = idx === 0;
                if (
                  (bannerCreateJson.activeFileUploadTab === "image" && data.filetype === "image") ||
                  (bannerCreateJson.activeFileUploadTab === "video" && data.filetype === "video")
                ) {
                  return (
                    <div
                      style={{
                        display: "inline-block",
                        padding: "10px",
                        width: "184px",
                        height: "156px",
                        border: selectedImages.includes(data)
                          ? "1px solid #272A31"
                          : "1px solid #B3B5BD",
                        borderRadius: "8px",
                        margin: "10px",
                        overflow: "hidden",
                        position: "relative",
                        cursor: "pointer",
                      }}
                      key={idx}
                      onClick={(e) => toggleSelection(e, data)}
                    >
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          height: "94px",
                        }}
                      >
                        {data.filetype === "image" && (
                          <div style={{ width: "94px" }}>
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "4px",
                                boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.2)",
                                visibility:
                                  bannerCreateJson.activeFileUploadTab === "image"
                                    ? ""
                                    : "hidden",
                              }}
                              className={"p-0 m-0 "}
                              src={data.filepath}
                              alt={`Image ${idx}`}
                            />
                          </div>
                        )}
            
                        {data.filetype === "video" && (
                          <div style={{ width: "167px" }}>
                            <video
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "4px",
                                visibility:
                                  bannerCreateJson.activeFileUploadTab === "video"
                                    ? ""
                                    : "hidden",
                              }}
                              className={"p-0 m-0"}
                              src={data.filepath}
                            />
                          </div>
                        )}
                      </div>
            
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 400,
                          color: "#9496A1",
                          marginTop: "10px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div className="col-8">
                            <div>
                              {data.filename &&
                                (data.filename.length > 15
                                  ? data.filename.slice(0, 15) + " ..."
                                  : data.filename)}
                              {!data.filename && "-"}
                            </div>
                            <div>
                              {data.filepath && (
                                <ImageSizeDisplay filepath={data.filepath} />
                              )}
                            </div>
                          </div>
            
                          <div className="col-4 ">
                            <div>
                              {data.filecat === "desktop" ||
                              data.filecat === "desktopVideo" ||
                              data.filecat === "mobile"
                                ? data.filecat === "desktop" || data.filecat === "desktopVideo"
                                  ? "Desktop"
                                  : "Mobile"
                                : "-"}
                            </div>
                            <div>
                              {data.filesize && data.filesize}
                              {!data.filesize && "- KB"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null; // Added to handle no return case
              })
            ) : (
              <div className="textAlignCenter opacity-50 font12">
                ไม่มีข้อมูลในระบบ
              </div>
            )}
          </div>
        </div>
        <div className="headerActionButtonDiv">
          <div className="paddingTopBottom20">
            <Form.Group
              style={{ display: "none" }}
              controlId="formFileImage"
              className="mb-3"
            >
              <Form.Control
                onChange={handleImgaeUpload}
                ref={hiddenFileInputDesktopImage}
                type="file"
                accept="image/*"
              />

              <Form.Control
                onChange={handleImgaeUpload}
                ref={hiddenFileInputDesktopVideo}
                type="file"
                accept="video/*"
              />
            </Form.Group>
          </div>
        </div>
        {state.saveButton && (
          <div className={"row sidePadding0 paddingTop10"}>{modalFooter}</div>
        )}
      </div>
    </div>
  );
};

export default MediaLibraryFile;
