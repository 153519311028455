import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Form, InputGroup} from 'react-bootstrap';
import SideNavbar from '../../../layouts/SideNavbar';
import ButtonComponent from '../../../components/Button';
import ModalComponent from '../../../components/Modal';
import { useParams } from 'react-router';
import {getUserById, updatePassword} from '../Services/api';

export default function Setting() {
    const { id } = useParams();

    const [userSettingJson, setUserSettingJson] = useState({
        password : '',
        dataError : ''
    }); 

    useEffect(() => {
        getUserByIdWrapper(id);
    }, [id]);

    function getUserByIdWrapper(id)
    {
        getUserById(id)
        .then((res) => 
          { 
            if(res.data.status === 'SUCCESS')
            {
                setUserSettingJson({
                    ...res.data.response,
                    'dataError' : ''
                });
            }     
          })
          .catch((err) => 
          {
            console.log(err);
          });
    }

    const [buttonJson, setButtonJson] = useState({
        label : "เพิ่มผู้ใช้งาน",
        handleClick : handleButtonClick
    }); 

    let modalFooter = 
       (<div className={'container-fluid'}>
            <div style={{textAlign:'center'}} className={'row p-0'}>
                <div className={'col-lg paddingBottom5'}>
                    <ButtonComponent buttonJson={{
                        label : "ยกเลิก",
                        handleClick : handleButtonClose
                    }}>
                    </ButtonComponent>
                    <span style={{paddingRight:'15px'}}>

                    </span>
                    <ButtonComponent buttonJson={{
                        label : "บันทึก",
                        handleClick : handleButtonSave
                    }}>

                    </ButtonComponent>
                </div>
            </div>
        </div>
        );

    let modalBody = 
       (<div className={'container-fluid'}>
            <div className={'row sidePadding0 paddingTop20'}>
                <div className={'col-lg paddingBottom5'}>
                    <span>
                        *
                    </span>
                    <span>
                        Password เดิม
                    </span>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-lg'}>
                    <InputGroup>
                        <Form.Control
                        placeholder="ระบุรหัสผ่านเข้าใช้งานเดิม"
                        aria-label="ระบุรหัสผ่านเข้าใช้งานเดิม"
                        aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                </div>
            </div>
            <div className={'row sidePadding0 paddingTop20'}>
                <div className={'col-lg paddingBottom5'}>
                    <span>
                        *
                    </span>
                    <span>
                        Password ใหม่
                    </span>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-lg'}>
                    <InputGroup>
                        <Form.Control
                        placeholder="ระบุรหัสผ่านเข้าใช้งานใหม่"
                        aria-label="ระบุรหัสผ่านเข้าใช้งานใหม่"
                        aria-describedby="basic-addon1"
                        name='newPassword'
                        onChange={handleInputChange}
                        />
                    </InputGroup>
                </div>
            </div>
            <div className={'row sidePadding0 paddingTop20'}>
                <div className={'col-lg paddingBottom5'}>
                    <span>
                        *
                    </span>
                    <span>
                        Password ใหม่ซ้ำ
                    </span>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-lg'}>
                    <InputGroup>
                        <Form.Control
                        placeholder="ระบุรหัสผ่านเข้าใช้งานเพื่อยืนยัน"
                        aria-label="ระบุรหัสผ่านเข้าใช้งานเพื่อยืนยัน"
                        aria-describedby="basic-addon1"
                        name='confirmPassword'
                        onChange={handleInputChange}
                        />
                    </InputGroup>
                </div>
            </div>
            <div className={'row paddingTop10'} style={{color:'red'}}>
                {userSettingJson.dataError}
            </div>
            <div className={'row paddingTop10'}>
                {modalFooter}
            </div>
        </div>
        );

        const [modalJson, setModalJson] = useState({
            show : false,
            handleClose : handleButtonClose,
            title : 'รีเซ็ทรหัสผ่านให้ผู้ใช้งาน',
        }); 

    function handleInputChange(event)
    {
        setUserSettingJson({
            ...userSettingJson,
            'password' : event.target.value
        });
    }

    function handleButtonClose()
    {
        setModalJson({
            ...modalJson,
            show : false
        });
        
    }

    function handleButtonClick()
    {
        setUserSettingJson({
            ...userSettingJson,
            'dataError' : '',
            password : ''
        });

        setModalJson({
            ...modalJson,
            show : true
        });
    }
    
    function handleButtonSave()
    {

        if(userSettingJson.password === undefined || userSettingJson.password === '')
        {
            setUserSettingJson({
                ...userSettingJson,
                dataError : 'Mandatory fields missing'
            });
            return;
        }

        const reqParam = {
            id : id,
            password : userSettingJson.password
        };

        updatePassword(reqParam)
        .then((res) => 
          { 
            console.log("Success");        
          })
          .catch((err) => 
          {
            console.log(err);
          });

        setModalJson({
            ...modalJson,
            show : false
        });
    }


    return (
        <React.Fragment>
            <div className='container-fluid'>
                <ModalComponent modalJson={modalJson}>
                   {modalBody}
                </ModalComponent>
                <div className='row'>
                <div className="col-2 p-0"  style={{width: "240px"}} >
                    <SideNavbar code="userSystemSetting" />
                </div> 
                <div className="col-9" style={{width:'1080px'}}>
                    <Row>
                        <Col style={{padding : '15px'}}>
                            <div className='headerTitleDiv headerFont'>
                                ข้อมูลบัญชีผู้ใช้งาน
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='container-fluid' style={{border : '1px solid #B3B5BD'}}>
                                <div className={'row textAlignLeft paddingTopBottom10'} style={{borderBottom:'1px solid #B3B5BD'}}>
                                    <div className={'col-lg font16Light'}>
                                        <img style={{width:'24px', height:'24px'}} src={require('../../../assets/images/rectangle.png')} alt="sideManuImg" />
                                        <span style={{paddingLeft:'10px'}}>ข้อมูลบัญชี</span>
                                    </div>
                                </div>
                                <div className={'row textAlignLeft paddingLeftRight10 paddingTopBottom20'}>
                                    <div className={'col-lg '}>
                                        <div className={'row font12Light'}>
                                            ชื่อ-นามสกุล
                                        </div>
                                        <div className={'row font12Light'}>
                                            {userSettingJson.name} {userSettingJson.lastname}
                                        </div>
                                    </div>
                                    <div className={'col-lg '}>
                                        <div className={'row font12Light'}>
                                            Username
                                        </div>
                                        <div className={'row font12Light'}>
                                            {userSettingJson.username}
                                        </div>
                                    </div>
                                    <div className={'col-lg '}>
                                        <div className={'row font12Light'}>
                                            กลุ่มผู้ใช้งาน
                                        </div>
                                        <div className={'row font12Light'}>
                                        {userSettingJson.usergroupname}
                                        </div>
                                    </div>
                                    <div className={'col-lg '}>
                                        <div className={'row font12Light'}>
                                            <ButtonComponent buttonJson={
                                                {
                                                    label : "เปลี่ยนรหัสผ่าน",
                                                    handleClick : handleButtonClick
                                                }
                                            } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div> 
                </div>
            </div>  
        </React.Fragment>
             
    );
  }