import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  }
});

instance.interceptors.request.use(
  (config) => {
    if(process.env.REACT_APP_API_BASE_URL === '')
    {
      config.baseURL = window.location.origin;
    }

    // const accessToken = TokenService.getLocalAccessToken();
    // if (accessToken) {
    //   config.headers["Authorization"] = 'Bearer ' + accessToken;
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;