import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useNavigate} from "react-router-dom"
import {Form, InputGroup} from 'react-bootstrap';
import SideNavbar from '../../../layouts/SideNavbar';
import ButtonComponent from '../../../components/Button';
import ModalComponent from '../../../components/Modal';
import { useParams } from 'react-router';
import {getUserById, updatePassword} from '../Services/api';
import { toast } from 'react-toastify';
export default function Detail() {
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        getUserByIdWrapper(id);
    }, [id]);

    const [userDetailJson, setUserDetailJson] = useState({
        password : '',
        dataError : ''
    }); 
    
    function getUserByIdWrapper(id)
    {
        getUserById(id)
        .then((res) => 
          { 
            setUserDetailJson({
                ...res.data.response,
                dataError : '',
                password : ''
            });           
          })
          .catch((err) => 
          {
            console.log(err);
          });
    }

    function handleInputChange(event)
    {
            setUserDetailJson({
                ...userDetailJson,
                'password' : event.target.value
            });
    }

    function StatusUpdate(statusData){
        if (statusData === "true") {
            return (
              <div
                className={"subHeaderFont paddingTop5"}
                style={{ backgroundColor: "#009E4B1A" ,width:'fit-content',height:'28px',padding:'5px',fontSize:'12px',borderRadius:'3px',color:'#009E4B',fontWeight:500}}>
                ACTIVATED
              </div>
            );
        }
        else{
            return (
                <div
                  className={"subHeaderFont paddingTop5"}
                  style={{ backgroundColor: "#EFF0F1" ,width:'fit-content',height:'28px',padding:'5px',fontSize:'12px',borderRadius:'3px',color:'#6F6F71',fontWeight:500}}>
                  DISABLED
                </div>
              );
        }
       }


    let modalFooter = 
       (<div className={'container-fluid'}>
            <div style={{textAlign:'center'}} className={'row p-0'}>
                <div className={'col-lg paddingBottom5'} style={{marginTop:'34px',marginBottom:'51px'}}>
                    <button
                        onClick={handleButtonClose}
                            style={{
                            width:'136px',
                            height:'40px',
                            fontWeight:400,
                            fontSize: "16px",
                            padding: "8px 16px",
                            cursor: "pointer",
                            color: "#222226",
                            border:"1px solid #222226",
                            borderRadius:'4px',
                            background: "#FFFFFF",
                            }}
                        >
                        ยกเลิก
                    </button>
                    <span style={{paddingRight:'15px'}}>

                    </span>
                    <button
                        onClick={handleButtonSave}
                            style={{
                            width:'136px',
                            height:'40px',
                            fontWeight:400,
                            fontSize: "16px",
                            padding: "8px 16px",
                            cursor: "pointer",
                            color: "#FFFFFF",
                            border:"1px solid #222226",
                            borderRadius:'4px',
                            background: "#222226",
                            // paddingLeft: "25px",
                            // paddingRight: "25px",
                            }}
                        >
                        บันทึก
                    </button>
                </div>
            </div>
        </div>
        );

    let modalBody = 
       (<div className={'container-fluid'}>
            <div className={'row p-0'}>
                <div className={'col-lg paddingBottom5'}>
                    <span style={{color:'red'}}>
                        *
                    </span>
                    <span style={{fontSize:'12px',fontWeight:500}}>
                     รหัสผ่านใหม่
                    </span>
                </div>
            </div>
            <div className={'row p-0'}>
                <div className={'col-lg'}>
                    <InputGroup>
                        <Form.Control
                        style={{fontSize:'12px'}}
                        placeholder="ระบุรหัสผ่านใหม่เพื่อเข้าใช้งาน"
                        aria-label="ระบุรหัสผ่านใหม่เพื่อเข้าใช้งาน"
                        aria-describedby="basic-addon1"
                        onChange={handleInputChange}
                        />
                    </InputGroup>
                </div>
            </div>
            <div className={'row paddingTop10'} style={{color:'red'}}>
                {userDetailJson.dataError}
            </div>
            <div className={'row paddingTop10'}>
                {modalFooter}
            </div>
        </div>
        );

        const [modalJson, setModalJson] = useState({
            show : false,
            handleClose : handleButtonClose,
            title : 'รีเซ็ทรหัสผ่านให้ผู้ใช้งาน'
        }); 

    function handleButtonClose()
    {
        setModalJson({
            ...modalJson,
            show : false
        });
    }

    function handleButtonClick()
    {
        setUserDetailJson({
            ...userDetailJson,
            dataError : '',
            password : ''
        });

        setModalJson({
            ...modalJson,
            show : true
        });
    }
    
    function handleButtonSave()
    {
        if(userDetailJson.password === undefined || userDetailJson.password === '')
        {
            setUserDetailJson({
                ...userDetailJson,
                dataError : 'Mandatory fields missing'
            });
            return;
        }
        
        
        const reqParam = {
            id : id,
            password : userDetailJson.password
        };

        updatePassword(reqParam)
        .then((res) => 
          { 
            toast.success("คุณทำรายการสำเร็จ", {
                position: "top-left",
                autoClose: 5000, // Set the duration in milliseconds (e.g., 5000 milliseconds = 5 seconds)
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                style: {
                  width: "1030px",
                  minHeight: "68px",
                  backgroundColor: "#D4F9E7",
                  color: "#009E4B",
                  fontSize: "18px",
                  fontWeight: 600,
                  marginLeft: "250px",
                },
              });
            console.log("Success");        
          })
          .catch((err) => 
          {
            console.log(err);
          });
        
        setModalJson({
            ...modalJson,
            show : false
        });
    }

    function navigateToAccountInfoEdit()
    {
        navigate("/user/system/edit/"+id);
    }
    const handleBreadcrumbClick = () => {
        // Go back to the previous page
        window.history.back();
      };

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <ModalComponent modalJson={modalJson}>
                    {modalBody}
                </ModalComponent>
                <div className='row'>
                <div className="col-2 p-0"  style={{width: "240px"}} >
                    <SideNavbar code="userSystemList" />
                </div> 
                <div className="col-9" style={{width:'1080px'}}>
                    <Row>
                        <Col style={{padding : '15px'}}>
                        <div className='headerTitleDiv headerFont'>
                            รายละเอียดผู้ใช้งานในระบบ
                        </div>
                        <div className='headerActionButtonDiv'>
                            <button
                            onClick={handleButtonClick}
                                style={{
                                width:'170px',
                                fontSize:'16px',
                                height:'40px',
                                background: "#131313", 
                                color: "#FFFFFF",
                                fontSize: "16px",
                                fontWeight: 400,
                                border: "none",
                                borderRadius: "4px",
                                padding: "5px 5px",
                                cursor: "pointer",
                                }}
                            >
                                RESET PASSWORD
                            </button>
                        </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='row paddingBottom20'>
                                <div className='col-lg textAlignLeft font12Light cursor-pointer'  onClick={handleBreadcrumbClick}>
                                <span style={{ opacity:'0.5'}}>รายชื่อผู้ใช้งานในระบบ {'>'} </span>รายละเอียดผู้ใช้งานในระบบ
                                </div>
                            </div>
                            <div className='container-fluid' style={{border : '1px solid #B3B5BD',borderRadius:'4px'}}>
                                <div className={'row textAlignLeft paddingTopBottom10'} style={{borderBottom:'1px solid #B3B5BD'}}>
                                    <div className={'col-lg font16Light'}>
                                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.75 2.25V15.75C19.75 16.5784 19.0784 17.25 18.25 17.25H1.75C0.921573 17.25 0.25 16.5784 0.25 15.75V2.25C0.25 1.42157 0.921573 0.75 1.75 0.75H18.25C19.0784 0.75 19.75 1.42157 19.75 2.25ZM16 8.25C16.4142 8.25 16.75 7.91421 16.75 7.5C16.75 7.08579 16.4142 6.75 16 6.75H12.25C11.8358 6.75 11.5 7.08579 11.5 7.5C11.5 7.91421 11.8358 8.25 12.25 8.25H16ZM12.25 9.75H16C16.4142 9.75 16.75 10.0858 16.75 10.5C16.75 10.9142 16.4142 11.25 16 11.25H12.25C11.8358 11.25 11.5 10.9142 11.5 10.5C11.5 10.0858 11.8358 9.75 12.25 9.75ZM18.25 2.25V15.75H1.75V2.25H18.25ZM10.7256 12.5625C10.8292 12.9638 10.5878 13.373 10.1866 13.4766C9.78529 13.5801 9.37605 13.3388 9.2725 12.9375C9.02594 11.9756 8.04812 11.25 6.99906 11.25C5.95 11.25 4.97312 11.9756 4.72563 12.9375C4.62207 13.3388 4.21283 13.5801 3.81156 13.4766C3.41029 13.373 3.16895 12.9638 3.2725 12.5625C3.51588 11.6566 4.09118 10.8755 4.88406 10.3744C4.02237 9.51779 3.76242 8.22604 4.22572 7.10283C4.68901 5.97961 5.78405 5.24675 6.99906 5.24675C8.21407 5.24675 9.30911 5.97961 9.77241 7.10283C10.2357 8.22604 9.97576 9.51779 9.11406 10.3744C9.90781 10.8748 10.4836 11.6562 10.7266 12.5625H10.7256ZM8.5 8.25C8.5 9.07843 7.82843 9.75 7 9.75C6.17157 9.75 5.5 9.07843 5.5 8.25C5.5 7.42157 6.17157 6.75 7 6.75C7.82843 6.75 8.5 7.42157 8.5 8.25Z" fill="#222226"/>
                                        </svg>
                                    <span style={{paddingLeft:'10px'}}>ข้อมูลบัญชี</span>
                                    </div>
                                </div>
                                <div className={'row textAlignLeft paddingLeftRight10 paddingTopBottom20'}>
                                    <div className={'col-lg '}>
                                        <div className={'row font12Light'} style={{opacity:'0.5'}}>
                                            ชื่อ-นามสกุล
                                        </div>
                                        <div className={'row font16Light'}>
                                            {userDetailJson.name} {userDetailJson.lastname}
                                        </div>
                                    </div>
                                    <div className={'col-lg '}>
                                        <div className={'row font12Light'}  style={{opacity:'0.5'}}>
                                            Username
                                        </div>
                                        <div className={'row font16Light'}>
                                            {userDetailJson.email}
                                        </div>
                                    </div>
                                    <div className={'col-lg '}>
                                        <div className={'row font12Light'}  style={{opacity:'0.5'}}>
                                            กลุ่มผู้ใช้งาน
                                        </div>
                                        <div className={'row font16Light'}>
                                            {userDetailJson.usergroupname}
                                        </div>
                                    </div>
                                    <div className={'col-lg '}>
                                        <div className={'row font12Light'}  style={{opacity:'0.5'}}>
                                            สถานะ
                                        </div>
                                        <div className={'row font16Light'}>
                                        {userDetailJson.status !== undefined && 
                                            StatusUpdate(userDetailJson.status)}
                                        </div>
                                    </div>
                                </div>
                                <div className={'row textAlignLeft paddingTopBottom10'} style={{borderTop:'1px solid #B3B5BD', background: 'rgba(179, 181, 189, 0.1)'}}>
                                    <div className={'col-lg font12Light pointer'} onClick = {()=>navigateToAccountInfoEdit()}>
                                        <img style={{width:'16px', height:'16px'}} src={require('../../../assets/images/edit.png')} alt="sideManuImg" />
                                        <span style={{paddingLeft:'10px'}}>แก้ไขข้อมูล</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div> 
                </div>
            </div>  
        </React.Fragment>
             
    );
  }