import apiInterceptor from "../../../hooks/apiInterceptor";



export function getaccessHistoryList() {
  return apiInterceptor
    .get("user/getUserAccessHstory", {
      // params: queryParams
    })
}
