import React, { useState, useEffect} from 'react';
import SideNavbar from '../../../layouts/SideNavbar';
import {Row, Col, Form, InputGroup} from 'react-bootstrap';
import ButtonComponent from '../../../components/Button';
import {getUserById} from '../../UserSystem/Services/api';
import { useParams } from 'react-router';
import {useNavigate} from "react-router-dom";
import {updateUserDetail} from "../Services/api";
import { toast } from "react-toastify";
import { ExclamationCircleFill, XCircleFill } from "react-bootstrap-icons";
export default function Edit() {
    const navigate = useNavigate();

    const { id } = useParams();

    const [status, setStatus] = useState(true);  

    const [userEditJson, setUserEditJson] = useState({
        userInfo : {
            'birthday' : ''
        }
        
    });

    useEffect(() => {
        getUserByIdWrapper(id);
    }, [id]);
    

    function getUserByIdWrapper(id)
    {
        getUserById(id)
        .then((res) => 
          { 
          
            setUserEditJson(
                {
                    ...userEditJson,
                    userInfo : res.data.response
                }
            );
          })
          .catch((err) => 
          {
            console.log(err);
          });
    }


    function handleButtonClick()
    {
        const reqParam = {
            name : userEditJson.userInfo.name,
            lastname : userEditJson.userInfo.lastname,
            gender : userEditJson.userInfo.gender,
            email : userEditJson.userInfo.email,
            contactnumber : userEditJson.userInfo.contactnumber,
            birthday : userEditJson.userInfo.birthday,
            status : userEditJson.userInfo.status,
            id : id
        };

        updateUserDetail(reqParam)
        .then((res) => 
          { 
            navigate('/user/detail/'+id+'/info');
            toast.success("อัปเดตรายละเอียดผู้ใช้", {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                style: {
                  width: "1030px",
                  minHeight: "68px",
                  backgroundColor: "#D4F9E7",
                  color: "#009E4B",
                  fontSize: "18px",
                  fontWeight: 600,
                  marginLeft: "250px",
                },
              });
              console.log("Successfully updated.");
          })
          .catch((err) => 
          {
            toast.error(err.response.data.error, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                icon: <XCircleFill />,
                style: {
                  width: "1030px",
                  minHeight: "68px",
                  backgroundColor: "#FEE5E4",
                  color: "#EA3348",
                  fontSize: "18px",
                  fontWeight: 600,
                  marginLeft: "250px",
                },
              });
            console.log(err);
          });
    }
    
    function handleInputChange(event) {
        
        const { name, checked, value,type } = event.target;
    
        let newValue = type === 'checkbox' || type === 'switch' ? checked : value;

        let updatedUserInfo = {};
        if(name=== 'status'){
            if (checked){
                 updatedUserInfo = { ...userEditJson.userInfo, [name]: 'true' };
            }else{
                 updatedUserInfo = { ...userEditJson.userInfo, [name]: 'false' };
            }
        }else{
         updatedUserInfo = { ...userEditJson.userInfo, [name]: newValue };
        }
    
        setUserEditJson({
            ...userEditJson,
            userInfo: updatedUserInfo,
        });
    
    }
    

    function navigateToAccountInfoEdit()
    {
        navigate("/user/setting/"+id);
    }
    const handleBreadcrumbClick = () => {
        // Go back to the previous page
        window.history.back();
      };

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className="col-2 p-0"  style={{width: "240px"}} >
                    <SideNavbar code="userList" />
                </div> 
                <div className="col-9" style={{width:'1080px'}}>
                    <Row>
                        <Col style={{padding : '15px'}}>
                        <div className='headerTitleDiv headerFont'>
                            รายละเอียดสมาชิก
                        </div>
                        <div className='headerActionButtonDiv'>
                        <button
                            onClick={handleButtonClick}
                                style={{
                                width:'136px',
                                fontSize:'16px',
                                height:'40px',
                                background: "#131313", 
                                color: "#FFFFFF",
                                fontSize: "14px",
                                fontWeight: "bold",
                                border: "none",
                                borderRadius: "4px",
                                padding: "8px 16px",
                                cursor: "pointer",
                                }}
                            >
                            บันทึก
                        </button>
                        </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className='row paddingBottom20'>
                            <div className='col-lg textAlignLeft font12Light cursor-pointer'  onClick={handleBreadcrumbClick}>
                            <span style={{ opacity:'0.5'}}>รายชื่อสมาชิกผู้ใช้งานเว็บไซต์ {'>'} </span> แก้ไขข้อมูล
                            </div>
                            <div className='row textAlignLeft paddingTopBottom10' style={{borderTop : '1px solid #B3B5BD',marginTop:'20px'}} />
                            <div className='headerActionButtonDiv text-center'>

                                <button className='' style={{borderRadius:'12px',borderWidth:'1px',width:'125px',height:'26px',padding:'1px'}}  onClick = {()=>navigateToAccountInfoEdit()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3538 5.14625L9.85375 1.64625C9.7599 1.55251 9.63265 1.4999 9.5 1.5H3.5C2.94772 1.5 2.5 1.94772 2.5 2.5V13.5C2.5 14.0523 2.94772 14.5 3.5 14.5H12.5C13.0523 14.5 13.5 14.0523 13.5 13.5V5.5C13.5001 5.36735 13.4475 5.2401 13.3538 5.14625ZM10 3.20688L11.7931 5H10V3.20688ZM3.5 13.5H12.5V6H9.5C9.22386 6 9 5.77614 9 5.5V2.5H3.5V13.5ZM10.5 8.5C10.5 8.77614 10.2761 9 10 9H6C5.72386 9 5.5 8.77614 5.5 8.5C5.5 8.22386 5.72386 8 6 8H10C10.2761 8 10.5 8.22386 10.5 8.5ZM10 11C10.2761 11 10.5 10.7761 10.5 10.5C10.5 10.2239 10.2761 10 10 10H6C5.72386 10 5.5 10.2239 5.5 10.5C5.5 10.7761 5.72386 11 6 11H10Z" fill="#222226"/>
                                    </svg>
                                    <span style={{fontSize:'12px',fontWeight:400, color:'#222226',padding:'4px'}}>ข้อมูลผู้ใช้งาน</span>
                                </button>
                            </div>
                        <div className='row textAlignLeft paddingTopBottom10' style={{borderBottom : '1px solid #B3B5BD'}} />
                        </div>
                        <Col lg={12}>
                            <div style={{textAlign: 'left', border: '1px solid #B3B5BD', borderRadius : '4px'}}>
                                <div className={'subHeaderFont padding10'} style={{ borderBottom: '1px solid #B3B5BD'}}>
                                    <img src={require('../../../assets/images/group.png')}></img> 
                                    <span> รายละเอียดผู้ใช้งาน</span>
                                </div>
                                <div className={'subHeaderFont padding10'} style={{ borderBottom: '1px solid #B3B5BD'}}>
                                    <div style={{display: 'inline-block'}}>
                                        เปิด/ปิดการใช้งานบัญชี
                                    </div>
                                    <div style={{paddingLeft:'20px', display: 'inline-block'}}>
                                               <Form>
                                        <Form.Check
                                            name='status'
                                            type='switch'
                                            id='custom-switch'
                                            checked={userEditJson.userInfo.status === "true"}
                                            onChange={handleInputChange}
                                        />
                                        </Form>

                                    </div>
                                </div>
                                <div className={'subHeaderFont padding10'}>
                                    <Row>
                                        <Col lg={6}>
                                            <div style={{paddingBottom: '10px'}} className={'appFont12Light'}>
                                                ชื่อ-นามสกุล
                                            </div>
                                            <div className={'appFont16Regular'}>
                                                <InputGroup>
                                                    <Form.Control
                                                    placeholder="กรณ์"
                                                    aria-label="กรณ์"
                                                    aria-describedby="basic-addon1"
                                                    name='name'
                                                    defaultValue={userEditJson.userInfo.name}
                                                    onChange={handleInputChange}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div style={{paddingBottom: '10px'}} className={'appFont12Light'}>
                                                เพศ
                                            </div>
                                            <div className={'appFont16Regular'}>
                                                <Form.Select 
                                                aria-label="Default select example"
                                                name='gender'
                                                value={userEditJson.userInfo.gender}
                                                defaultValue={userEditJson.userInfo.gender}
                                                onChange={handleInputChange}>
                                                    <option value={'male'}>ผู้ชาย</option>
                                                    <option value={'female'}>ผู้หญิง</option>
                                                </Form.Select>
                                            </div>
                                        </Col>
                                        
                                    </Row>
                                    <Row style={{paddingTop:'20px'}}>
                                        <Col lg={6}>
                                            <div style={{paddingBottom: '10px'}} className={'appFont12Light'}>
                                                วันเกิด
                                            </div>
                                            <div className={'appFont16Regular'}>
                                                <Form.Control
                                                    type="date"
                                                    name="birthday"
                                                    placeholder="DateRange"
                                                    value={userEditJson?.userInfo?.birthday?.split('T')[0]}
                                                    onChange={handleInputChange}
                                                    
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div style={{paddingBottom: '10px'}} className={'appFont12Light'}>
                                                อีเมล์
                                            </div>
                                            <div className={'appFont16Regular'}>
                                                <InputGroup>
                                                    <Form.Control
                                                    placeholder="กรณ์"
                                                    aria-label="กรณ์"
                                                    aria-describedby="basic-addon1"
                                                    name='email'
                                                    defaultValue={userEditJson.userInfo.email}
                                                    onChange={handleInputChange}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{paddingTop:'20px'}}>
                                        <Col lg={6}>
                                            <div style={{paddingBottom: '10px'}} className={'appFont12Light'}>
                                                เบอร์โทรติดต่อ
                                            </div>
                                            <div className={'appFont16Regular'}>
                                                <InputGroup>
                                                    <Form.Control
                                                    placeholder="บุญพาสุข"
                                                    aria-label="บุญพาสุข"
                                                    aria-describedby="basic-addon1"
                                                    name='contactnumber'
                                                    defaultValue={userEditJson.userInfo.contactnumber}
                                                    onChange={handleInputChange}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div> 
            </div>
        </div>               
    );
}