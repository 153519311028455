import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { getFavouriteProduct } from "../../Services/api";
import 
{
    getInputDate
} from '../../../../Utils/DateUtils';

export default function Favourite(props) {
  const [FavouriteProductJson, SetFavouriteProductJson] = useState({});
  useEffect(() => {
    getFavouriteProductWrapper(props.userId);
  }, [props.userId]);

  function getFavouriteProductWrapper(userId) {
    getFavouriteProduct(userId)
      .then((res) => {
        if (
          res.data.response.length > 0
        ) {
          SetFavouriteProductJson({
            ...FavouriteProductJson,
            favouriteList: res.data.response,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function checkIfKeyExistInObj(obj, prop) {
    return obj.hasOwnProperty(prop);
  }
  return (
    <div className="col-12">
      <Row>
        <Col>
          <div style={{ paddingTop: "20px", textAlign: "left" }}>
            <Table>
              <thead>
                <tr style={{fontSize:'12px',fontWeight:500}}>
                  <th>ลำดับ</th>
                  <th>รหัสสินค้า</th>
                  <th>ชื่อสินค้า</th>
                  <th>วันที่เพิ่มเข้าลิสต์</th>
                </tr>
              </thead>
              <tbody>
              {FavouriteProductJson?.favouriteList?.length === undefined ? (
                <tr>
                  <td colSpan="12" className="textAlignCenter">
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="24" cy="24" r="24" fill="#F3F4F8"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 24C14.25 18.6152 18.6152 14.25 24 14.25C29.3824 14.2557 33.7443 18.6176 33.75 24C33.75 29.3848 29.3848 33.75 24 33.75C18.6152 33.75 14.25 29.3848 14.25 24ZM15.75 24C15.75 28.5563 19.4437 32.25 24 32.25C28.5542 32.2448 32.2448 28.5542 32.25 24C32.25 19.4437 28.5563 15.75 24 15.75C19.4437 15.75 15.75 19.4437 15.75 24ZM23.25 24.75V19.5C23.25 19.0858 23.5858 18.75 24 18.75C24.4142 18.75 24.75 19.0858 24.75 19.5V24.75C24.75 25.1642 24.4142 25.5 24 25.5C23.5858 25.5 23.25 25.1642 23.25 24.75ZM24 29.25C24.6213 29.25 25.125 28.7463 25.125 28.125C25.125 27.5037 24.6213 27 24 27C23.3787 27 22.875 27.5037 22.875 28.125C22.875 28.7463 23.3787 29.25 24 29.25Z" fill="#272A31"/>
                    </svg> 
                    
                    <div className="opacity-50 font12">ไม่มีข้อมูลในระบบ</div>
                  </td>
                </tr>
              ): (
                (FavouriteProductJson?.favouriteList?.map((data, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{data.runningno}</td>
                    <td>{data.name}</td>
                    <td>{getInputDate(data.createdon)}</td>
                  </tr>
                )))
              )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
  );
}
