import React, { useState, useEffect } from 'react';

function ImageSizeDisplay({ filepath }) {
  const [imageSize, setImageSize] = useState(null);

  useEffect(() => {
    getImageSize(filepath)
      .then(size => setImageSize(size))
      .catch(error => console.error('Error fetching image size:', error));
  }, [filepath]);

  return <div>{imageSize}</div>;
}

function getImageSize(url) {
  const image = new Image();
  image.src = url;

  return new Promise((resolve, reject) => {
    image.onload = function() {
      const width = image.naturalWidth;
      const height = image.naturalHeight;
      const sizeInPixels = `${width} × ${height} px`;
      resolve(sizeInPixels);
    };

    image.onerror = function(error) {
      reject(error);
    };
  });
}

export default ImageSizeDisplay;
