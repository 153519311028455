import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideNavbar from "../../../layouts/SideNavbar";
import ButtonComponent from "../../../components/Button";
import { Form, InputGroup } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useParams } from "react-router";
import { Table } from "react-bootstrap";
import {
  getMediaFileDetail,
  getProductFileDetail,
  deleteMediaFileInfo,
} from "../Services/api";
import { formatDate } from "../../../Utils/DateUtils";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../../../components/Modal";
import Select from 'react-select';

import apiInterceptor from "../../../hooks/apiInterceptor";
import { ToastContainer, toast } from "react-toastify";


const options = [
  { value: 'O1', label: 'Option1', icon: 'fa-icon', badge: '' },
  { value: 'O2', label: 'Option2', icon: 'fa-icon', badge: '' },
  { value: 'O3', label: 'Option3', icon: 'fa-icon', badge: '' },
  { value: 'O4', label: 'Option4', icon: 'fa-icon', badge: '' },
  { value: 'O5', label: 'Option5', icon: 'fa-icon', badge: '' },
  { value: 'O6', label: 'Option6', icon: 'fa-icon', badge: '' },
  { value: 'O7', label: 'Option7', icon: 'fa-icon', badge: '' },
  { value: 'O8', label: 'Option8', icon: 'fa-icon', badge: '' },
  { value: 'O9', label: 'Option9', icon: 'fa-icon', badge: '' },
  { value: 'O10', label: 'Option10', icon: 'fa-icon', badge: '' },
  { value: 'O11', label: 'Option11', icon: 'fa-icon', badge: '' },
  { value: 'O12', label: 'Option12', icon: 'fa-icon', badge: '' },
  { value: 'O13', label: 'Option13', icon: 'fa-icon', badge: '' },
  // Add other options
];

const customStyles = {
  indicatorsContainer: () => ({
    display: 'none',
  }),
};

const initializeSelect2 = () => {
  const elements = document.querySelectorAll('.js-select2');
  elements.forEach(element => {
    new Select(element, {
      closeOnSelect: false,
      placeholder: "Click to select an option",
      allowHtml: true,
      allowClear: true,
      tags: true
    });
  });
};

// async function deleteAndFetchData(id) {
//   try {
//     const response = await apiInterceptor.delete(`/banner/delete/${id}`);

//     if (response.data.status === "SUCCESS") {
//       toast.success(response.data.status);
//     }
//   } catch (error) {
//     toast.error(error.message);
//   }
// }

export default function Detail() {
  const style = {
    background: "#FFFFFF",
    color: "#222226",
    fontSize: "14px",
    border: "none",
    height: "40px",
    paddingLeft: "25px",
    paddingRight: "25px",
    border: "1px solid #222226",
    width: "190px",
    marginLeft: "20px",
    marginBottom: "10px",
  };
  const dropdownStyle = {
    border: "none",
    padding: "9px",
    width: "100%",
  };

  const svgStyle = {
    marginRight: "30px", // Add some space between SVG and text
  };
  const navigate = useNavigate();

  const { productId } = useParams();

  const [MediaFileJson, setMediaFileJson] = useState({});

  useEffect(() => {
    getMediaFileDetailWrapper(productId);
  }, [productId]);

  function getMediaFileDetailWrapper(id) {
    getMediaFileDetail(id)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setMediaFileJson({
            ...MediaFileJson,
            MediaDetail: res.data.response,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // const options = MediaFileJson?.MediaDetail?.filesize
  // ? MediaFileJson?.MediaDetail?.filesize.map(file => ({
  //     value: file.value,
  //     label: file.label,
  //     icon: 'fa-icon', // Assuming this is a static value
  //     badge: '' // Assuming this is a static value
  //   }))
  // : [];

  // console.log(options);


  const handleButtonClick = (id) => {
     const reqParam = {
       fileId: id,
     };
 
     deleteMediaFileInfo(reqParam)
       .then((result) => {
        navigate("/medialibrary/list")
         console.log("success", result);
       })
       .catch((error) => {
         console.error(error.message);
       });
   };
    

  useEffect(() => {
      initializeSelect2();
  
      // Clean up function
      return () => {
        // Any cleanup logic here
      };
  }, []);

  const handleBreadcrumbClick = () => {
    // Go back to the previous page
    window.history.back();
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 p-0"  style={{width: "240px"}} >
          <SideNavbar code="medialibrary" />
        </div>
        <div className="col-9" style={{width:'1080px'}}>
          <Row>
            <Col style={{ padding: "15px" }}>
              <div className="headerTitleDiv headerFont">Media Library</div>
              <div className="headerActionButtonDiv">
                <Button
                  className="btn btn-danger"
                  label="ลบข้อมูล"
                  onClick={() => handleButtonClick(MediaFileJson?.MediaDetail?.id)}
                >
                ลบข้อมูล
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="row paddingBottom20">
                <div className="col-lg textAlignLeft font12Light cursor-pointer" onClick={handleBreadcrumbClick}>
                  {"รายการ Media  >  แก้ไข Media"}
                </div>
              </div>
              <div
                className="container-fluid"
                style={{ border: "1px solid #B3B5BD" }}
              >
                <div
                  className={"row textAlignLeft paddingTopBottom10"}
                  style={{ borderBottom: "1px solid #B3B5BD" }}
                >
                  <div className={"col-lg font16Light"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20.25 3.75H3.75C2.92157 3.75 2.25 4.42157 2.25 5.25V18.75C2.25 19.5784 2.92157 20.25 3.75 20.25H20.25C21.0784 20.25 21.75 19.5784 21.75 18.75V5.25C21.75 4.42157 21.0784 3.75 20.25 3.75ZM20.25 5.25V14.8828L17.8059 12.4397C17.5246 12.1583 17.143 12.0002 16.7452 12.0002C16.3473 12.0002 15.9657 12.1583 15.6844 12.4397L13.8094 14.3147L9.68437 10.1897C9.09867 9.60436 8.14946 9.60436 7.56375 10.1897L3.75 14.0034V5.25H20.25ZM8.625 11.25L3.75 16.125V18.75H16.125L8.625 11.25ZM20.25 18.75H18.2466L14.8716 15.375L16.7466 13.5L20.25 17.0044V18.75ZM14.625 8.25C14.0037 8.25 13.5 8.75368 13.5 9.375C13.5 9.99632 14.0037 10.5 14.625 10.5C15.2463 10.5 15.75 9.99632 15.75 9.375C15.75 8.75368 15.2463 8.25 14.625 8.25Z"
                        fill="#272A31"
                      />
                    </svg>
                    <span style={{ paddingLeft: "10px" }}>
                      รายละเอียด Media
                    </span>
                  </div>
                </div>
                <div
                  className={
                    "row font14Light paddingLeftRight10 paddingTopBottom20"
                  }
                >
                  <div className={"col-lg-4"}>
                    <div className={"row"}>
                      <div className={"mb-3"}>
                        {MediaFileJson?.MediaDetail?.filetype === "video" ? (
                          <video width="320" height="240" controls>
                            <source
                              src={MediaFileJson?.MediaDetail?.filepath}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            className={"p-0 m-0"}
                            style={{ width: "120px", height: "120px" }}
                            src={MediaFileJson?.MediaDetail?.filepath}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={"col-lg-8 col-md-6"} style={{ textAlign: "left" }}>
                    <div className={"row"}>
                      <div className={"col-lg-6"}>
                        <div className={"paddingTop20"}>
                          <div className="appFontLight">ชื่อไฟล์</div>
                          <div className="subHeaderFont paddingTop5">
                              {MediaFileJson?.MediaDetail?.filename}
                          </div>
                        </div>
                        
                        <div className={"paddingTop20"}>
                          <div className="appFontLight">ประเภทไฟล์</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {MediaFileJson?.MediaDetail?.filetype}
                          </div>
                        </div>
                      </div>
                      <div className={"col-lg-6"}>
                        <div className={"paddingTop20"}>
                          <div className="appFontLight">ขนาด</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {MediaFileJson?.MediaDetail?.filesize}
                          </div>
                        </div>
                        
                        <div className={"paddingTop20"}>
                          <div className="appFontLight">Dimension</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            1080 by 768 Pixels
                            {}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={"row paddingTop20"}>
                      <div className="appFontLight">Tags</div>
                        <div className={"subHeaderFont paddingTop5"}>
                          <Select
                            options={options}
                            isMulti={true}
                            onChange={(selectedOptions) => {
                            console.log(selectedOptions);
                            }}
                            placeholder="Click to select options"
                            styles={customStyles}
                          />
                        </div>
                        <div className={"paddingTop15"}>
                          <Button
                            style={{ width: "136px", height: "35px" }}
                            className="btn btn-dark"
                            label="บันทึก"
                            // onClick={handleButton}
                          >
                            บันทึก
                          </Button>
                        </div>


                    </div>
                  </div>

                
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
