import apiInterceptor from "../../../hooks/apiInterceptor";

  export function getUserList() {
    return apiInterceptor
      .get("/user/list", {
        // params: queryParams
      })
  }

  export function getUserBankDetail(id) {
    return apiInterceptor
      .get("/user/bank/get/"+id, {
        // params: queryParams
      })
  }

  export function getUserAddress(id) {
    return apiInterceptor
      .get("/user/address/list/"+id, {
        // params: queryParams
      })
  }

  export function updateUserDetail(reqParam) {
    return apiInterceptor
      .post("/user/web/update", reqParam)
  }

  export function createUserAddress(reqParam) {
    return apiInterceptor
      .post("/user/address/create", reqParam)
  }

  export function updateUserAddress(reqParam) {
    return apiInterceptor
      .put("/user/address/update", reqParam)
  }

  export function getFavouriteProduct(id) {
    return apiInterceptor
      .get("/user/getUserFavoriteByUserId/"+id, {
        // params: queryParams
       
      })
  }

  export function updatePassword(reqParam) {
    return apiInterceptor
      .post("/user/admin/password/reset", reqParam)
  }

  export function getAuctionHistory(id) {
    return apiInterceptor
    
      .get("/user/getAuctionHistoryByUserId/"+id, {
        // params: queryParams
      })
  }
  export function getAuctionHistoryDetail(id) {

    return apiInterceptor
      .get("/user/getAuctionHistoryDetailByUserId/"+id, {
        // params: queryParams
      })
  }

