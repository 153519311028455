import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Form, InputGroup } from "react-bootstrap";
import { getMediaList } from "./api";

export default function ModalComponent(props) {
  const { modalJson, style, showSearchBar } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const additionalProps = { modalJson, style, showSearchBar, searchTerm, setSearchTerm };
  
  const modalSize = style ? "xl" : "";
  useEffect(() => {
    getMediaListWrapper();
  }, []);

  const [MediaListJson, setMediaListJson] = useState({});

  function getMediaListWrapper() {
    getMediaList()
      .then((result) => {
        if (result.data.status === "SUCCESS") {
          setMediaListJson({
            ...MediaListJson,
            mediaList: result.data.response,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const childrenWithProps = React.Children.map(props.children, child => {
    // Check if child is a valid React element
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { additionalProps });
    }
    return child;
  });

  return (
    <Modal
      show={props.modalJson.show}
      onClose={props.modalJson.handleClose}
      onHide={props.modalJson.handleClose}
      size={modalSize}
    >
      <Modal.Header>
        <div className="row col-12">
          <div className="col-12 d-flex justify-content-between">
            <Modal.Title className="font24Bold">
              {props.modalJson.title}
            </Modal.Title>
            <button
              className="close"
              style={{ backgroundColor: "#222226", color: "#FFFFFF" }}
              onClick={() => { setSearchTerm(''); 
                props.modalJson.handleClose(); }} >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="col">
            {showSearchBar && (
              <InputGroup style={{ width: "420px", padding: "12px" }}>
                <InputGroup.Text
                  style={{ background: "#131313" }}
                  id="basic-addon1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M12.2029 10.7653L15.7811 14.3435C16.0729 14.6354 16.0738 15.1077 15.7785 15.403V15.403C15.4852 15.6963 15.0114 15.6979 14.7161 15.4026L11.1826 11.8691C9.99708 12.8963 8.45056 13.5178 6.75888 13.5178C3.02605 13.5178 0 10.4917 0 6.75888C0 3.02605 3.02605 0 6.75888 0C10.4917 0 13.5178 3.02605 13.5178 6.75888C13.5178 8.25856 13.0293 9.64417 12.2029 10.7653ZM12.0158 6.75888C12.0158 3.85557 9.66218 1.50197 6.75888 1.50197C3.85557 1.50197 1.50197 3.85557 1.50197 6.75888C1.50197 9.66218 3.85557 12.0158 6.75888 12.0158C9.66218 12.0158 12.0158 9.66218 12.0158 6.75888Z"
                      fill="#F3F4F8"
                    />
                  </svg>
                </InputGroup.Text>
                <Form.Control
                  placeholder="ค้นหาด้วยรหัสสินค้าหรือชื่อสินค้า"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </InputGroup>
            )}
          </div>
        </div>
      </Modal.Header>

      <Modal.Body>{childrenWithProps}</Modal.Body>
    </Modal>
  );
}
