import React, { useState, useEffect} from 'react';
import {Row, Col} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import {getUserById} from '../../../UserSystem/Services/api';
import {getUserBankDetail} from '../../Services/api';


export default function Info(props) {   
    const navigate = useNavigate();

    const [userInfoJson, setUserInfoJson] = useState({
        'bankInfo' : {
            'accountnumber' : ''
        }
    });

    useEffect(() => {
        getUserByIdWrapper(props.userId);
    }, [props.userId]);

    function getUserByIdWrapper(id)
    {
        getUserById(id)
        .then((res) => 
          { 
            setUserInfoJson(
                {
                    ...userInfoJson,
                    userInfoJson :res.data.response
                }
            )
            getUserBankDetailWrapper(props.userId, res.data.response);
    
          })
          .catch((err) => 
          {
            console.log(err);
          });
    }

    function getUserBankDetailWrapper(id, userData)
    {
        getUserBankDetail(id)
        .then((res) => 
          { 
            let bankObj = {};
            if(res.data.status === 'SUCCESS')
            {
                bankObj = res.data.response;
            }

            setUserInfoJson(
                {
                    ...userData,
                    'userinfo ': userData,
                    'birthday' : userData.birthday.split('T')[0],
                    'bankInfo' : bankObj
                }
            );
          })
          .catch((err) => 
          {
            console.log(err);
          });
    }

    function navigateToEdit()
    {
        navigate('/user/edit/'+props.userId);        
    }
    function StatusUpdate(statusData, emailverify, emailotp){
        if ((emailverify === "Y" && emailotp !== null) && statusData === "true") {
            return (
              <div
                className={"subHeaderFont paddingTop5"}
                style={{ backgroundColor: "#009E4B1A" ,width:'fit-content',height:'28px',padding:'5px',borderRadius:'3px',color:'#009E4B',fontWeight:500,fontSize:'12px'}}
              >
                ACTIVATED
              </div>
            );
        }
        else if (statusData === "true") {
            return (
                <div
                    className={"subHeaderFont paddingTop5"}
                    style={{
                        backgroundColor: "#009E4B1A",
                        width: 'fit-content',
                        height: '28px',
                        padding: '5px',
                        borderRadius: '3px',
                        color: '#009E4B',
                        fontWeight: 500,
                        fontSize: '12px'
                    }}
                >
                    ACTIVATED
                </div>
            );
        } else if (emailverify !== 'Y') {
            return (
                <div
                    className={"subHeaderFont paddingTop5"}
                    style={{
                        backgroundColor: "#FFE2D3",
                        width: 'fit-content',
                        height: '28px',
                        padding: '5px',
                        borderRadius: '3px',
                        color: '#F57500',
                        fontWeight: 500,
                        fontSize: '12px'
                    }}
                >
                    Waiting for email verification
                </div>
            );
        }else if(emailotp === null){
          return (
            <div
              className={"subHeaderFont paddingTop5"}
              style={{ backgroundColor: "#FFE2D3" ,width:'fit-content',height:'28px',padding:'5px',borderRadius:'3px',color:'#F57500',fontWeight:500,fontSize:'12px'}}
            >
              Waiting for email OTP
            </div>
          );
    
        }
        else {
            return (
                <div
                  className={"subHeaderFont paddingTop5"}
                  style={{ backgroundColor: "#FFD2DA" ,width:'fit-content',height:'28px',padding:'5px',borderRadius:'3px',color:'#F80042',fontWeight:500,fontSize:'12px'}}
                >
                  BANNED
                </div>
              );
        }
       }
    return (
            <div className='col-12'>
                <div style={{textAlign: 'left', border: '1px solid #B3B5BD', borderRadius : '4px'}}>
                    <div className={'subHeaderFont padding10'} style={{ borderBottom: '1px solid #B3B5BD'}}>
                        <img src={require('../../../../assets/images/user.png')}></img> 
                        <span className='ms-2'> ข้อมูลบัญชีใช้งานและการติดต่อ</span>
                    </div>
                    <div className={'subHeaderFont padding10 text-wrap'}>
                        <Row>
                            <Col lg={3}>
                                <div className={'appFont12Light'}>
                                    ชื่อ-นามสกุล
                                </div>
                                <div className={'appFont16Regular text-break'}>
                                    {userInfoJson.name}
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className={'appFont12Light'}>
                                    เพศ
                                </div>
                                <div className={'appFont16Regular'}>
                                    {userInfoJson.gender}
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className={'appFont12Light'}>
                                    วันเกิด
                                </div>
                                <div className={'appFont16Regular'}>
                                    {userInfoJson.birthday}
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className={'appFont12Light'}>
                                    สถานะ
                                </div>
                                <div className={'appFont16Regular'}>
                                {userInfoJson.status !== undefined && 
                                    StatusUpdate(userInfoJson.status, userInfoJson.emailverified, userInfoJson.emailotp)}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className={'subHeaderFont padding10'} style={{marginBottom: '15px'}}>
                        <Row>
                            <Col lg={3}>
                                <div className={'appFont12Light'}>
                                    อีเมล์
                                </div>
                                <div className={'appFont16Regular text-break'}>
                                {userInfoJson.email}
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className={'appFont12Light'}>
                                    เบอร์ติดต่อ
                                </div>
                                <div className={'appFont16Regular'}>
                                    {userInfoJson.contactnumber}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div onClick={() => navigateToEdit()}
                    style={{borderRadius: '0px 0px 4px 4px', background : 'rgba(179, 181, 189, 0.10)',height:'32px', borderTop: '1px solid #B3B5BD',}} 
                    className={'subHeaderFont padding10 pointer font12'}>
                        <img src={require('../../../../assets/images/edit.png')}>
                        </img> 
                        <span className='ms-2'> แก้ไขข้อมูล</span>
                    </div>
                </div>
                <div style={{marginTop:'20px', textAlign: 'left', border: '1px solid #B3B5BD', borderRadius : '4px'}}>
                    <div className={'subHeaderFont padding10'} style={{ borderBottom: '1px solid #B3B5BD'}}>
                        <img src={require('../../../../assets/images/bank.png')}></img> 
                        <span  className='ms-2'> ข้อมูลบัญชีสำหรับคืนเงินมัดจำ</span>
                    </div>
                    <div className={'subHeaderFont padding10'}>
                        <Row>
                            <Col lg={3}>
                                <div className={'appFont12Light'}>
                                    เลขที่บัญชี
                                </div>
                                <div className={'appFont16Regular'}>
                                    {userInfoJson.bankInfo.accountnumber}
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className={'appFont12Light'}>
                                    ชื่อบัญชี
                                </div>
                                <div className={'appFont16Regular'}>
                                    {userInfoJson.bankInfo.accountname}
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className={'appFont12Light'}>
                                    ธนาคาร
                                </div>
                                <div className={'appFont16Regular'}>
                                    {userInfoJson.bankInfo.bankname}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
    );
}