import apiInterceptor from "../../../hooks/apiInterceptor";


export function getRefundList() {
  return apiInterceptor
    .get("/refund/list", {
    
    })
}

export function getRefundDetail(Id) {
  return apiInterceptor
    .get("/refund/detail/"+ Id, {
    })
}

export function updateReferenceNumber(reqParam) {
  return apiInterceptor
    .put("/refund/payment/refupdate", reqParam)
}