import React, { useState, useEffect } from 'react';
import SideNavbar from '../../../layouts/SideNavbar';
import {Row, Col} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import { useParams } from 'react-router';
import InfoLayout from './Info';
import {Form, InputGroup} from 'react-bootstrap';
import AddressLayout from './Address';
import AuctionHistoryLayout from './AuctionHistory';
import FavouriteLayout from './Favourite';
import { toast } from 'react-toastify';
import {updatePassword} from '../Services/api';
import ModalComponent from '../../../components/Modal';

export default function Detail() {
    const navigate = useNavigate();
    const { id, action } = useParams();

    const [userDetailJson, setUserDetailJson] = useState({
        'activeSubMenu' : 'info'
    });

    useEffect(() => {
        setUserDetailJson(
            {
                ...userDetailJson,
                activeSubMenu : action
            }
        );

    }, [action]);

    const [subMenuList, setSubMenuList] = useState([
        {
            'title' : 'ข้อมูล',
            'itemCode' : 'info'
        },
        {
            'title' : 'ที่อยู่จัดส่งและใบเสร็จ',
            'itemCode' : 'address'
        },
        {
            'title' : 'ประวัติการเข้าประมูล',
            'itemCode' : 'history'
        },
        {
            'title' : 'สินค้าที่กด Favourite',
            'itemCode' : 'fav'
        },
    ]);

    const [modalJson, setModalJson] = useState({
        show : false,
        handleClose : handleButtonClose,
        title : 'รีเซ็ทรหัสผ่านให้ผู้ใช้งาน'
    }); 

    function handleButtonClose()
    {
        setModalJson({
            ...modalJson,
            show : false
        });
    }

    function handleInputChange(event)
    {
            setUserDetailJson({
                ...userDetailJson,
                'password' : event.target.value
            });
    }



    function handleButtonClick()
    {
        setUserDetailJson({
            ...userDetailJson,
            dataError : '',
            password : ''
        });

        setModalJson({
            ...modalJson,
            show : true
        });
    }

    function handleButtonSave()
    {
        if(userDetailJson.password === undefined || userDetailJson.password === '')
        {
            setUserDetailJson({
                ...userDetailJson,
                dataError : 'Mandatory fields missing'
            });
            return;
        }
        
        
        const reqParam = {
            id : id,
            password : userDetailJson.password
        };

        updatePassword(reqParam)
        .then((res) => 
          { 
            toast.success("คุณทำรายการสำเร็จ", {
                position: "top-left",
                autoClose: 5000, // Set the duration in milliseconds (e.g., 5000 milliseconds = 5 seconds)
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                style: {
                  width: "1030px",
                  minHeight: "68px",
                  backgroundColor: "#D4F9E7",
                  color: "#009E4B",
                  fontSize: "18px",
                  fontWeight: 600,
                  marginLeft: "250px",
                },
              });
          })
          .catch((err) => 
          {
            console.log(err);
          });
        
        setModalJson({
            ...modalJson,
            show : false
        });
    }


    function navigateToLink(actionCode)
    {
        if(actionCode === '')
        {
            return;
        }
        navigate('/user/detail/'+id+'/'+actionCode);

        setUserDetailJson(
            {
                ...userDetailJson,
                activeSubMenu : actionCode
            }
        );
    }  const handleBreadcrumbClick = () => {
        // Go back to the previous page
        window.history.back();
      };

        let modalFooter = 
        (<div className={'container-fluid'}>
            <div style={{textAlign:'center'}} className={'row p-0'}>
                <div className={'col-lg paddingBottom5'} style={{marginTop:'34px',marginBottom:'51px'}}>
                    <button
                        onClick={handleButtonClose}
                            style={{
                            width:'136px',
                            height:'40px',
                            fontWeight:400,
                            fontSize: "16px",
                            padding: "8px 16px",
                            cursor: "pointer",
                            color: "#222226",
                            border:"1px solid #222226",
                            borderRadius:'4px',
                            background: "#FFFFFF",
                            }}
                        >
                        ยกเลิก
                    </button>
                    <span style={{paddingRight:'15px'}}>

                    </span>
                    <button
                        onClick={handleButtonSave}
                            style={{
                            width:'136px',
                            height:'40px',
                            fontWeight:400,
                            fontSize: "16px",
                            padding: "8px 16px",
                            cursor: "pointer",
                            color: "#FFFFFF",
                            border:"1px solid #222226",
                            borderRadius:'4px',
                            background: "#222226",
                            // paddingLeft: "25px",
                            // paddingRight: "25px",
                            }}
                        >
                        บันทึก
                    </button>
                </div>
            </div>
        </div>
        );

        let modalBody = 
        (<div className={'container-fluid'}>
            <div className={'row p-0'}>
                <div className={'col-lg paddingBottom5'}>
                    <span style={{color:'red'}}>
                        *
                    </span>
                    <span style={{fontSize:'12px',fontWeight:500}}>
                        รหัสผ่านใหม่
                    </span>
                </div>
            </div>
            <div className={'row p-0'}>
                <div className={'col-lg'}>
                    <InputGroup>
                        <Form.Control
                        style={{fontSize:'12px'}}
                        placeholder="ระบุรหัสผ่านใหม่เพื่อเข้าใช้งาน"
                        aria-label="ระบุรหัสผ่านใหม่เพื่อเข้าใช้งาน"
                        aria-describedby="basic-addon1"
                        onChange={handleInputChange}
                        />
                    </InputGroup>
                </div>
            </div>
            <div className={'row paddingTop10'} style={{color:'red'}}>
                {userDetailJson.dataError}
            </div>
            <div className={'row paddingTop10'}>
                {modalFooter}
            </div>
        </div>
        );

    return (
        <div className='container-fluid'>
            <div className='row'>
            <ModalComponent modalJson={modalJson}>
            {modalBody}
        </ModalComponent>
                <div className="col-2 p-0"  style={{width: "240px"}} >
                    <SideNavbar code="userList" />
                </div> 
                <div className="col-9" style={{width:'1080px'}}>
                    <Row>
                        <Col style={{padding : '15px'}}>
                            <div className='headerTitleDiv headerFont'>
                                รายละเอียดสมาชิก
                            </div>
                            {action === 'info' &&(
                            <div className='headerActionButtonDiv'>
                                <button
                                onClick={handleButtonClick}
                                    style={{
                                    width:'170px',
                                    fontSize:'16px',
                                    height:'40px',
                                    background: "#131313", 
                                    color: "#FFFFFF",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    border: "none",
                                    borderRadius: "4px",
                                    padding: "5px 5px",
                                    cursor: "pointer",
                                    }}
                                >
                                    RESET PASSWORD
                                </button>
                            </div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                   
                        <div className='row paddingBottom20'>
                            <div className='col-lg textAlignLeft font12Light cursor-pointer'>
                                <span onClick={handleBreadcrumbClick} style={{ opacity:'0.5'}}>รายชื่อสมาชิกผู้ใช้งานเว็บไซต์ {'>'}</span>รายละเอียดสมาชิก
                            </div>
                        </div>
                        <Col lg={2} className='p-0' style={{ width: '169px',overflow:'hidden' }}>
                        <div style={{ height: '100vh', textAlign: 'left', background: '#EFF0F1', display: 'flex', flexDirection: 'column' }}>
                          {subMenuList.map((data, idx) => (
                            <div key={idx} className={'pointer'} style={{ marginTop:'20px',marginLeft:'20px',width: '131px'}} onClick={() => navigateToLink(data.itemCode)}>
                              <div 
                                style={{ 
                                    height: "24px", 
                                    flexWrap: 'nowrap', 
                                    overflow: 'hidden', 
                                    textOverflow: 'ellipsis', 
                                    whiteSpace: 'nowrap',
                                }} 
                                className={(data.itemCode === userDetailJson.activeSubMenu ? 'menuActive' : '')}>
                                <div 
                                    style={{
                                    fontSize: '12px', 
                                    fontWeight: 600, 
                                    marginTop:'4px',
                                    marginLeft:'11px',
                                    marginRight:'10px'
                                    }}
                                >
                                {data.title}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ` </Col>
                        <Col lg={10}>
                        {action === 'info'  &&
                            <InfoLayout userId={id} />
                        }
                        {action === 'address'  &&
                            <AddressLayout userId={id} />
                        }  
                        {action === 'history'  &&
                            <AuctionHistoryLayout userId={id} />
                        } 
                        {action === 'fav'  &&
                            <FavouriteLayout userId={id} />
                        }   
                        </Col>
                    </Row>
                </div> 
            </div>
        </div>               
    );
}