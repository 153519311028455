import React, { useState, useEffect, useRef } from "react";
import SideNavbar from "../../../layouts/SideNavbar";
import { Row, Col } from "react-bootstrap";
import { Form, InputGroup, Table, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../components/Button";
import { getRefundList } from "../Services/api";
import { CSVLink, CSVDownload } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 
{
    getInputDate
} from '../../../Utils/DateUtils';
import apiInterceptor from "../../..//hooks/apiInterceptor";

export default function List() {
  const navigate = useNavigate();
  const dropdownStyle = {
    border: "none",
    padding: "2px",
    background: "transparent",
    border: "0.5px solid #B3B5BD",
    width: "200px",
    height:'32px',
    fontSize:'12px',
    borderRadius:'4px',
    opacity:'0.5',
    marginLeft:'10px'
  };
  const style = {
    background: "transparent",
    color: "#222226",
    border: "none",
    border: "1px solid #222226",
    marginLeft: "15px",
    width: "10%",
    marginBottom: "10px",
  };
  const paginatorPage = {
    background: "transparent",
    border: "none",
    margin: "10px",
  };

  useEffect(() => {
    getRefundListWrapper();
  }, []);

  const [refundListJson, setRefundListJson] = useState({
    productList: [],
  });
  function handleButtonClick() {
    navigate("/bidding/create");
  }

  function getRefundListWrapper() {
    getRefundList()
      .then((result) => {
        if (result.data.status === "SUCCESS") {
          setRefundListJson({
            ...refundListJson,
            productList: result.data.response,
          });
          setFilteredData(result.data.response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function statusupdate(status) {
    if (status === null || status === undefined) {
      return null;
    }
    const lowercaseStatus = status.toLowerCase();
    if (lowercaseStatus === "publish") {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{ backgroundColor: "#009E4B1A",textAlign:"center",fontSize:"12px",fontWeight:500 ,color:'#009E4B'}}
        >
          {status}
        </div>
      );
    } else if (lowercaseStatus === "unpublish") {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{ backgroundColor: "#F36D2733" ,textAlign:"center",fontSize:"12px",color:'#EC7405',fontWeight:500  }}
        >
          {status}
        </div>
      );
    } else {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{ backgroundColor: "#FDD34333",textAlign:"center",fontSize:"12px"  }}
        >
          {status}
        </div>
      );
    }
  }

  const [paymentType, setpaymentType] = useState({});
  function navigateToProductDetail(Id, paymentMode, status) {
    // Convert paymentMode and status to lowercase
    const lowerCasePaymentMode = paymentMode ? paymentMode.toLowerCase() : '';
    const lowerCaseStatus = status ? status.toLowerCase() : '';

    if (!lowerCasePaymentMode) {
      console.error("Payment type is empty or falsy.");
      return;
    }

    if (lowerCasePaymentMode === "internet banking" && lowerCaseStatus === "pending") {
      navigate("/refund/qrdetail/" + Id);
    } else if (lowerCasePaymentMode === "internet banking" && lowerCaseStatus === "successful") {
      navigate("/refund/qrview/" + Id);
    } else if (lowerCasePaymentMode === "card") {
      navigate("/refund/creditdetail/" + Id);
    }
  }

  function AsyncUsernameFetcher({ userId }) {
    const [username, setUsername] = useState(null);
  
    useEffect(() => {
      async function fetchUsername() {
        try {
          const response = await apiInterceptor.get(`/user/userid/userName/${userId}`);
          setUsername(response.data);
        } catch (error) {
          console.error("Error fetching username:", error);
        }
      }
  
      fetchUsername();
  
      // Cleanup function to prevent memory leaks
      return () => {
        setUsername(null); // Reset username state
      };
    }, [userId]);
  
    return username;
  }


  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const perPage = 10; // Set the number of items per page

  // Pagination logic
  const totalFilteredItems = filteredData.length;
  const totalPages = Math.ceil(totalFilteredItems / perPage);

  // Update currentPage if it exceeds the total number of pages after filtering
  useEffect(() => {
    if (totalPages > 0 && currentPage >= totalPages) {
      setCurrentPage(totalPages - 1);
    }
  }, [totalPages]);

  // Update startIndex and endIndex based on currentPage
  const startIndex = currentPage * perPage;
  const endIndex = Math.min(startIndex + perPage, totalFilteredItems);
  const slicedCardData = filteredData.slice(startIndex, endIndex);

   // Function to filter by search term
  const filterBySearchTerm = () => {
    return refundListJson.productList.filter(
      (item) =>
        (item.productname?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          item.runningno?.toLowerCase().includes(searchTerm?.toLowerCase()))
    );
  };

  // Function to filter by selected status
  const filterBySelectedStatus = () => {
    return refundListJson.productList.filter((item) => {
      // Check if selectedStatus is null or undefined, set it to false
      const statusToMatch = selectedStatus !== null && selectedStatus !== undefined ? selectedStatus : false;
      // Filter based on the adjusted status
      return statusToMatch ? item.status === statusToMatch : true;
    });
  };


  // Function to apply filters and update filtered data
  const filteredResults = () => {
    const searchData = filterBySearchTerm();
    const statusData = filterBySelectedStatus();
    
    // Combine filtered results
    let combinedResults = searchTerm ? searchData : statusData;

    // Apply date filter if startDate and endDate are defined
    if (startDate && endDate) {
      combinedResults = combinedResults.filter((item) => {
        // Extract the item date
        const itemDate = getInputDate(item.createdon)?.split(' ')[0];
        // Extract the start and end dates
        const startdate = getInputDate(startDate)?.split(' ')[0];
        const endate = getInputDate(endDate)?.split(' ')[0];
        // Check if the item date is within the specified range
        return startdate <= itemDate && itemDate <= endate;
      });
    }
    
    setFilteredData(combinedResults);
    setCurrentPage(0); // Reset page to first page after filtering
  };

    // Effect to apply filters when search term, selected status, or dates change
    useEffect(() => {
      filteredResults();
    }, [searchTerm, selectedStatus, startDate, endDate, refundListJson]);
  
    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
      setStartDate("");
      setEndDate("");
      setSelectedStatus("");
    };
  
    const handleStatusChange = (e) => {
      setSelectedStatus(e.target.value);
      setSearchTerm(""); 
      setStartDate("");
      setEndDate("");
    };
  
    const handleDateChange = (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      setSearchTerm(""); 
      setSelectedStatus("");
    };

  const csvLinkRef = useRef(null);

  const downloadCSV = () => {
    // Programmatically click the CSVLink component
    if (csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
  };

  const CustomDatePickerInput = ({ value, onClick }) => {
    // Get today's date in the format YYYY-MM-DD
    const today = new Date().toISOString().slice(0, 10);
    
    return (
      <div className="custom-datepicker-input " onClick={onClick}>
      <svg
        className="calsvg me-2"
        width="18"
        height="18"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.5 2H14.25V1.25C14.25 0.835786 13.9142 0.5 13.5 0.5C13.0858 0.5 12.75 0.835786 12.75 1.25V2H5.25V1.25C5.25 0.835786 4.91421 0.5 4.5 0.5C4.08579 0.5 3.75 0.835786 3.75 1.25V2H1.5C0.671573 2 0 2.67157 0 3.5V18.5C0 19.3284 0.671573 20 1.5 20H16.5C17.3284 20 18 19.3284 18 18.5V3.5C18 2.67157 17.3284 2 16.5 2ZM3.75 3.5V4.25C3.75 4.66421 4.08579 5 4.5 5C4.91421 5 5.25 4.66421 5.25 4.25V3.5H12.75V4.25C12.75 4.66421 13.0858 5 13.5 5C13.9142 5 14.25 4.66421 14.25 4.25V3.5H16.5V6.5H1.5V3.5H3.75ZM16.5 18.5H1.5V8H16.5V18.5Z"
          fill="#222226"
        />
      </svg>
        {value || today} {/* Display value if not null, otherwise display today's date */}
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 p-0"  style={{width: "240px"}} >
          <SideNavbar code="refundlist" />
        </div>
        <div className="col-9" style={{width:'1080px'}}>
          <Row>
            <Col style={{ padding: "15px" }}>
              <div className="headerTitleDiv headerFont">
                รายการสินค้าประมูล
              </div>
              <div className="headerActionButtonDiv"></div>
            </Col>
          </Row>
          <Row>
            <Col style={{ paddingLeft: "20px" }}>
              <div className="row " style={{ paddingTop: "10px"}}>
                  <InputGroup style={{ width: "532px",height:'32px' }}>
                    <InputGroup.Text
                      style={{ background: "#131313" }}
                      id="basic-addon1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M12.2029 10.7653L15.7811 14.3435C16.0729 14.6354 16.0738 15.1077 15.7785 15.403V15.403C15.4852 15.6963 15.0114 15.6979 14.7161 15.4026L11.1826 11.8691C9.99708 12.8963 8.45056 13.5178 6.75888 13.5178C3.02605 13.5178 0 10.4917 0 6.75888C0 3.02605 3.02605 0 6.75888 0C10.4917 0 13.5178 3.02605 13.5178 6.75888C13.5178 8.25856 13.0293 9.64417 12.2029 10.7653ZM12.0158 6.75888C12.0158 3.85557 9.66218 1.50197 6.75888 1.50197C3.85557 1.50197 1.50197 3.85557 1.50197 6.75888C1.50197 9.66218 3.85557 12.0158 6.75888 12.0158C9.66218 12.0158 12.0158 9.66218 12.0158 6.75888Z"
                          fill="#F3F4F8"
                        />
                      </svg>
                    </InputGroup.Text>
                    <Form.Control
                      style={{fontSize:'12px'}}
                      placeholder="ค้นหาด้วยรหัสสินค้าหรือชื่อสินค้า"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </InputGroup>
                <select
                  className="rounded font12Light" 
                  style={dropdownStyle}
                  value={selectedStatus}
                  onChange={handleStatusChange}
                >
                  <option value="" disabled selected hidden>
                    เลือกดูตามสถานะ
                  </option>
                  <option value="">All Status </option>
                  <option value="SUCCESSFULL">SUCCESSFULL </option>
                  <option value="PENDING">REFUND_INTIATE</option>
                </select>{" "}
                <button className="p-0 opacity-100" style={dropdownStyle}>
                  <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    customInput={<CustomDatePickerInput />}
                  />
                </button>

                <Button
                  className="rounded "
                  style={{
                    textWrap:'nowrap',
                    background: "transparent",
                    height:'32px',
                    color: "#222226",
                    borderRadius:'2px',
                    border: "1px solid #B3B5BD",
                    width:'108px',
                    fontSize:'12px',
                    fontWeight:400,
                    marginLeft:'12px',
                  }}
                  onClick={downloadCSV}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 26 26"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.3172 2.05781L16.6922 6.43281C16.8094 6.55012 16.8751 6.70919 16.875 6.875V16.875C16.875 17.5654 16.3154 18.125 15.625 18.125H4.375C3.68464 18.125 3.125 17.5654 3.125 16.875V3.125C3.125 2.43464 3.68464 1.875 4.375 1.875H11.875C12.0408 1.87487 12.1999 1.94063 12.3172 2.05781ZM14.7414 6.25L12.5 4.00859V6.25H14.7414ZM15.625 7.5V16.875H4.375V3.125H11.25V6.875C11.25 7.22018 11.5298 7.5 11.875 7.5H15.625ZM12.5005 12.5C12.5005 12.3341 12.4345 12.175 12.3172 12.0578C12.2 11.9405 12.0409 11.8745 11.875 11.8745C11.7091 11.8745 11.55 11.9405 11.4328 12.0578L10.625 12.8664V9.375C10.625 9.02982 10.3452 8.75 10 8.75C9.65482 8.75 9.375 9.02982 9.375 9.375V12.8664L8.56719 12.0578C8.32297 11.8136 7.92703 11.8136 7.68281 12.0578C7.4386 12.302 7.4386 12.698 7.68281 12.9422L9.55781 14.8172C9.67504 14.9345 9.83412 15.0005 10 15.0005C10.1659 15.0005 10.325 14.9345 10.4422 14.8172L12.3172 12.9422C12.4345 12.825 12.5005 12.6659 12.5005 12.5Z"
                      fill="#222226"
                    />
                  </svg>
                   Export CSV
                </Button>
                <CSVLink
                  data={refundListJson.productList}
                  filename="sample.csv"
                  className="csv-link"
                  ref={csvLinkRef}
                  target="_blank"
                />

                </div>
            </Col>
          </Row>
          
          <Row>
            <Col>
              <div style={{ paddingTop: "20px",overflowX: "auto",overflowY:'hidden',whiteSpace:'nowrap' }}>
                <Table>
                  <thead>
                    <tr style={{fontSize:'12px',fontWeight:500}}>
                      <th>ลำดับ</th>
                      <th>รหัสสินค้า</th>
                      <th>ชื่อสินค้า</th>
                      <th>ชื่อ-นามสกุล</th>
                      <th>อีเมล์</th>
                      <th>เบอร์ติดต่อ</th>
                      <th>ยอดคืน</th>
                      <th>วิธีการชำระเงิน</th>
                      <th>สถานะ</th>
                      <th>Ref No.</th>
                      <th>ผู้ทำรายการ</th>
                      <th>วันที่อัพเดต</th>
                    </tr>
                  </thead>
                  <tbody>
                  {slicedCardData?.length === 0 ? (
                    <tr>
                      <td colSpan="12" className="textAlignCenter">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="24" cy="24" r="24" fill="#F3F4F8"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 24C14.25 18.6152 18.6152 14.25 24 14.25C29.3824 14.2557 33.7443 18.6176 33.75 24C33.75 29.3848 29.3848 33.75 24 33.75C18.6152 33.75 14.25 29.3848 14.25 24ZM15.75 24C15.75 28.5563 19.4437 32.25 24 32.25C28.5542 32.2448 32.2448 28.5542 32.25 24C32.25 19.4437 28.5563 15.75 24 15.75C19.4437 15.75 15.75 19.4437 15.75 24ZM23.25 24.75V19.5C23.25 19.0858 23.5858 18.75 24 18.75C24.4142 18.75 24.75 19.0858 24.75 19.5V24.75C24.75 25.1642 24.4142 25.5 24 25.5C23.5858 25.5 23.25 25.1642 23.25 24.75ZM24 29.25C24.6213 29.25 25.125 28.7463 25.125 28.125C25.125 27.5037 24.6213 27 24 27C23.3787 27 22.875 27.5037 22.875 28.125C22.875 28.7463 23.3787 29.25 24 29.25Z" fill="#272A31"/>
                        </svg> 
                        
                        <div className="opacity-50 font12">ไม่มีข้อมูลในระบบ</div>
                      </td>
                    </tr>
                  ): (
                    (slicedCardData.map((data, idx) => (
                      <tr key={idx} className="font14Light">
                        <td>{idx + 1}</td>
                        <td
                          className="pointer"
                          onClick={() =>
                            navigateToProductDetail(
                              data.id,
                              data.payment_mode,
                              data.status
                            )
                          }
                        >
                          {data.product_id}
                        </td>
                        <td>{data.productname}</td>
                        <td>{data.name}</td>
                        <td>{data.email}</td>
                        <td>{data.contactnumber}</td>
                        <td>{data.amount}</td>
                        <td>{data.payment_mode}</td>
                        <td>
                        {data.status && (
                        statusupdate(data.status.toUpperCase())
                        )}
                        </td>
                        <td>{data.reference_id}</td>
                        <td> {data.createdby ? (
                            <AsyncUsernameFetcher userId={data.createdby} />
                          ) : null}</td>
                        <td>{getInputDate(data.createdon)}</td>
                      </tr>
                    )))
                  )}
                  </tbody>
                </Table>
              </div>
            </Col>
            {filteredData.length > perPage && (
              <div className="flex text-center gap-8 mt-20">
                {Array.from(
                  { length: Math.ceil(filteredData.length / perPage) },
                  (_, index) => (
                    <button
                      key={index}
                      className={`paginator-page text-black ${currentPage === index && "active"}`}
                      onClick={() => setCurrentPage(index)}
                      style={{
                        ...paginatorPage,
                        fontWeight: currentPage === index ? "bold" : "normal"
                      }}
                    >
                      {index + 1}
                    </button>
                  )
                )}
              </div>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}
