import moment from 'moment-timezone';

export function formatDate(date) {
    return date;
}

// export function getInputDate(date) {
//     if (date !== undefined) {
//         const [datePart, timePart] = date.split('T');
//         return `${datePart} ${timePart.split('.')[0]}`; // Concatenating date and time parts
//     } else {
//         return date;
//     }
// }

export function getInputDate(date) {
    if (date !== undefined) {
        
        const dateTime = moment(date).format('DD/MM/YYYY HH:mm');
        // Convert to Thailand time
        // const thailandTime = moment(date).format('DD/MM/YYYY HH:mm');
        
        // const thailandTime = new Date(dateTime.getTime() + (7 * 60 * 60 * 1000));
        // const formattedTime = thailandTime.toLocaleTimeString('en-GB', { hour12: false, hour: '2-digit', minute: '2-digit' });
        // const formattedDate = thailandTime.toLocaleDateString('en-GB');
        // return `${formattedDate} ${formattedTime}`;

        return `${dateTime}`;
    } else {
        return date;
    }
}

