import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideNavbar from "../../../layouts/SideNavbar";
import ButtonComponent from "../../../components/Button";
import { Form, InputGroup } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useParams } from "react-router";
import { Table } from "react-bootstrap";
import {
  getOrderProductDetail,
  updateTrackingProduct,
  updateUserAddress,
} from "../Services/api";
import { formatDate } from "../../../Utils/DateUtils";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../../../components/Modal";
import { toast } from "react-toastify";
import { ExclamationCircleFill, XCircleFill } from "react-bootstrap-icons";

export default function Detail() {
  const style = {
    background: "#FFFFFF",
    color: "#222226",
    fontSize: "12px",
    borderRadius:'4px',
    height: "32px",
    paddingLeft: "3px",
    paddingRight: "3px",
    border: "1px solid #B3B5BD",
    width: "108px",
    marginLeft: "20px",
    marginBottom: "10px",
    overflow:'hidden',
    fontWeight:400
  };
  const dropdownStyle = {
    marginLeft:'5px',
    height:'36px',
    fontSize:'14px',
    border: "none",
    padding: "2px",
    background: "transparent",
    border: "0.5px solid #B3B5BD",
    borderRadius:'4px',
    width: "200px",
  };

  const svgStyle = {
    marginRight: "5px", // Add some space between SVG and text
  };

  const navigate = useNavigate();

  const { orderId } = useParams();

  const [OrderProductJson, setOrderProductJson] = useState({
    productDetail: {
      fileList: [],
    },
  });
  const [AddressOrderProductJson, SetAddressOrderProductJson] = useState({
    productDetail: {
      fileList: [],
    },
  });

  useEffect(() => {
    getOrderProductDetailWrapper(orderId);
  }, [orderId]);
  function getOrderProductDetailWrapper(Id) {
    getOrderProductDetail(Id)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setOrderProductJson({
            ...OrderProductJson,
            productDetail: res.data.response,
          });
          SetAddressOrderProductJson({
            ...AddressOrderProductJson,
            productDetail: res.data.response,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const [formDatatracking, setFormDatatracking] = useState({});

  const [showModalOne, setShowModalOne] = useState(false);
  const [showModalTwo, setShowModalTwo] = useState(false);

  const handleButtonOneClick = () => {
    setShowModalOne(true);
  };

  const handleButtonTwoClick = () => {
    setShowModalTwo(true);
  };

  const handleCloseModalOne = () => {
    setShowModalOne(false);
  };

  const handleCloseModalTwo = () => {
    setShowModalTwo(false);
  };

  function handleInputChange(event) {
    const key = event.target.name;
    let value = event.target.value;
    const type = event.target.type;
    if (type === "checkbox") {
      value = event.target.checked;
    }

    let userInfo = OrderProductJson.productDetail;
    userInfo[key] = value;

    setOrderProductJson({
      ...OrderProductJson,
      productDetail: userInfo,
    });
  }

  function handleInputChangeaddress(event) {
    const key = event.target.name;
    let value = event.target.value;
    const type = event.target.type;
    if (type === "checkbox") {
      value = event.target.checked;
    }

    let userInfo = AddressOrderProductJson.productDetail;
    userInfo[key] = value;

    SetAddressOrderProductJson({
      ...AddressOrderProductJson,
      productDetail: userInfo,
    });
  }

  const handleButtonClicktracking = () => {
    const reqParam = {
      tracking_number: OrderProductJson.productDetail.tracking_number,
      transport_type: OrderProductJson.productDetail.transport_type,
      id: OrderProductJson.productDetail.id,
    };

    updateTrackingProduct(reqParam)
      .then((result) => {
        navigate("/order/view/" + orderId);
        handleCloseModalTwo();
        toast.success("คุณทำรายการสำเร็จ", {
          position: "top-left",
          autoClose: 5000, // Set the duration in milliseconds (e.g., 5000 milliseconds = 5 seconds)
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          style: {
            width: "1030px",
            minHeight: "68px",
            backgroundColor: "#D4F9E7",
            color: "#009E4B",
            fontSize: "18px",
            fontWeight: 600,
            marginLeft: "250px",
          },
        });  
      })
      .catch((error) => {
        console.error(error.message);
        toast.error("เกิดข้อผิดพลาด ไม่สามารถทำรายการได้", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          icon: <XCircleFill />,
          style: {
            width: "1030px",
            minHeight: "68px",
            backgroundColor: "#FEE5E4",
            color: "#EA3348",
            fontSize: "18px",
            fontWeight: 600,
            marginLeft: "250px",
          },
        });
      });
  };

  const handleBreadcrumbClick = () => {
    // Go back to the previous page
    window.history.back();
  };

  const handleButtonClickaddress = () => {
    const reqParam = {
      address1: OrderProductJson.productDetail.shipping_address1,
      address2: OrderProductJson.productDetail.shipping_address2,
      province: OrderProductJson.productDetail.shipping_province,
      city: OrderProductJson.productDetail.shipping_city,
      country: OrderProductJson.productDetail.shipping_country,
      pincode: OrderProductJson.productDetail.shipping_pincode,
      // firstname: OrderProductJson.productDetail.shippinglastname,
      // lastname: OrderProductJson.productDetail.shippinglastname,
      isdeliveryaddress: OrderProductJson.productDetail.isdeliveryaddress,
      isreceiptaddress: OrderProductJson.productDetail.isreceiptaddress,
      addressId: OrderProductJson.productDetail.user_address_id,
      modifiedby: OrderProductJson.productDetail.user_id,
    };

    updateUserAddress(reqParam)
      .then((result) => {
        handleCloseModalOne();
        toast.success("คุณทำรายการสำเร็จ", {
          position: "top-left",
          autoClose: 5000, // Set the duration in milliseconds (e.g., 5000 milliseconds = 5 seconds)
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          style: {
            width: "1030px",
            minHeight: "68px",
            backgroundColor: "#D4F9E7",
            color: "#009E4B",
            fontSize: "18px",
            fontWeight: 600,
            marginLeft: "250px",
          },
        });    
      })
      .catch((error) => {
        toast.error("เกิดข้อผิดพลาด ไม่สามารถทำรายการได้", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          icon: <XCircleFill />,
          style: {
            width: "1030px",
            minHeight: "68px",
            backgroundColor: "#FEE5E4",
            color: "#EA3348",
            fontSize: "18px",
            fontWeight: 600,
            marginLeft: "250px",
          },
        });
        console.error(error.message);
      });
  };
  const depositAmount =
    Number(OrderProductJson?.productDetail?.productdepositamount) || 0;
  const biddingAmount =
    Number(
      OrderProductJson?.productDetail?.last_auction_total
    ) || 0;

  const TotalAmount = Math.floor(Math.abs(biddingAmount - depositAmount));
  
  let modalBodytracking = (
    <div className={"container-fluid"}>
      <>
      <div className={"row sidePadding0 paddingTop20"}>
        <div className={"col-lg paddingBottom5 font12"}>
          <span style={{color:'red'}}>*</span>
          <span>รายชื่อขนส่ง</span>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-lg"}>
          <select
            className="col-2 me-5 rounded "
            style={{
              width:'440px',
              marginLeft:'5px',
              height:'36px',
              fontSize:'14px',
              border: "none",
              padding: "2px",
              background: "transparent",
              border: "0.5px solid #B3B5BD",
              borderRadius:'4px',
            }}
            name="transport_type"
            value={OrderProductJson?.productDetail.transport_type}
            defaultValue={OrderProductJson?.productDetail.transport_type}
            onChange={handleInputChange}
          >
            <option value="" disabled selected hidden>
              เลือกขนส่ง
            </option>
            <option value="Transport-1">Transport 1</option>
            <option value="Transport-2">Transport 2</option>
            <option value="TransPort-3">Transport 3</option>
          </select>
        </div>
      </div>
      <div className={"row sidePadding0 paddingTop20"}>
        <div className={"col-lg paddingBottom5 font12"}>
          <span style={{color:'red'}}>*</span>
          <span>Tracking No.</span>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-lg"}>
          <InputGroup>
            <Form.Control
              style={{fontSize:'12px'}}
              type="number"
              placeholder="ระบุเลข Tracking No."
              aria-label="ระบุรหัสผ่านเข้าใช้งานใหม่"
              aria-describedby="basic-addon1"
              name="tracking_number"
              value={OrderProductJson?.productDetail.tracking_number}
              defaultValue={OrderProductJson?.productDetail.tracking_number}
              onChange={handleInputChange}
            />
          </InputGroup>
        </div>
      </div>
    {/*<div className="d-flex col justify-content-center gap-3 mt-5 ">
        <ButtonComponent
          buttonJson={{
            label: "ยกเลิก",
            handleClick: handleCloseModalTwo,
            style: "edit",
          }}
        ></ButtonComponent>
        <ButtonComponent
          buttonJson={{
            label: "บันทึก",
            handleClick: handleButtonClicktracking,
          }}
        ></ButtonComponent>
        </div> */}
      <div className='d-flex col justify-content-center gap-1 mt-5' style={{marginTop:'34px',marginBottom:'51px'}}>
      <button
          onClick={handleCloseModalTwo}
              style={{
              width:'136px',
              height:'40px',
              fontWeight:400,
              fontSize: "16px",
              padding: "8px 16px",
              cursor: "pointer",
              color: "#222226",
              border:"1px solid #222226",
              borderRadius:'4px',
              background: "#FFFFFF",
              }}
          >
          ยกเลิก
      </button>
      <span style={{paddingRight:'15px'}}>

      </span>
      <button
              onClick={handleButtonClicktracking}                            
              style={{
              width:'136px',
              height:'40px',
              fontWeight:400,
              fontSize: "16px",
              padding: "8px 16px",
              cursor: "pointer",
              color: "#FFFFFF",
              border:"1px solid #222226",
              borderRadius:'4px',
              background: "#222226",
              }}
          >
          บันทึก
      </button>
      </div>

      <div className={"row paddingTop10"} style={{ color: "red" }}>
        {}
      </div>
      <div className={"row paddingTop10"}>{}</div>
      </>
    </div>
  );

  let modalBodyaddress = (
    <div className={"container-fluid"}>
    <>
      <div className={"row sidePadding0 paddingTop20"}>
        <div className={"col-lg paddingBottom5 font12"}>
          ที่อยู่ : บ้านเลขที่ ซอย หมู่ ถนน
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-lg"}>
          <InputGroup>
            <Form.Control
              placeholder=""
              aria-label=""
              aria-describedby="basic-addon1"
              value={OrderProductJson.productDetail.shipping_address1}
              defaultValue={OrderProductJson.productDetail.shipping_address1}
              name="shipping_address1"
              onChange={handleInputChange}
            />
          </InputGroup>
        </div>
      </div>

      <div className={"row sidePadding0 paddingTop20"}>
        <div className={"col-lg paddingBottom5 font12"}>
          <span></span>
          <span>อาคาร ชั้น </span>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-lg"}>
          <InputGroup>
            <Form.Control
              placeholder=""
              aria-label="ระบุรหัสผ่านเข้าใช้งานใหม่"
              aria-describedby="basic-addon1"
              value={OrderProductJson.productDetail.shipping_address2}
              defaultValue={OrderProductJson.productDetail.shipping_address2}
              name="shipping_address2"
              onChange={handleInputChange}
            />
          </InputGroup>
        </div>
      </div>

      <div className={"row sidePadding0 paddingTop20"}>
        <div className=" col-lg w-50">
          <div className={"col-lg paddingBottom5 font12"}>
            <span style={{color:'red'}}>*</span>
            <span>จังหวัด </span>
          </div>
          <select
            className="row me-5 rounded "
            style={dropdownStyle}
            name="shipping_province"
            value={OrderProductJson?.productDetail.shipping_province}
            defaultValue={OrderProductJson?.productDetail.shipping_province}
            onChange={handleInputChange}
          >
            <option value={OrderProductJson?.productDetail.shipping_province}>
              {OrderProductJson?.productDetail.shipping_province}
            </option>
            <option value="Phayao">Phayao</option>
            <option value="Bangkok">Bangkok</option>
          </select>
        </div>
        <div className=" col-lg w-50">
        <div className={"col-lg paddingBottom5 font12"}>
          <span style={{color:'red'}}>*</span>
          <span>เขต/อำเภอ </span>
        </div>
          <select
            className="row me-5 rounded "
            style={dropdownStyle}
            name="shipping_country"
            value={OrderProductJson?.productDetail.shipping_country}
            defaultValue={OrderProductJson?.productDetail.shipping_country}
            onChange={handleInputChange}
          >
            <option value={OrderProductJson?.productDetail.shipping_country}>
              {OrderProductJson?.productDetail.shipping_country}
            </option>
            <option value="Kamphaeng Phet">
              Kamphaeng Phet{" "}
            </option>
            <option value="Sa Kaew">Sa Kaew</option>
          </select>
        </div>
      </div>
      <></>

      <div className={"row sidePadding0 paddingTop20"}>
        <div className=" col-lg w-50">
        <div className={"col-lg paddingBottom5 font12"}>
          <span style={{color:'red'}}>*</span>
          <span>แขวง/ตำบล</span>
        </div>
          <select
            className="row me-5 rounded "
            style={dropdownStyle}
            name="shipping_city"
            value={OrderProductJson?.productDetail.shipping_city}
            defaultValue={OrderProductJson?.productDetail.shipping_city}
            onChange={handleInputChange}
          >
            <option value={OrderProductJson?.productDetail.shipping_city}>
              {OrderProductJson?.productDetail.shipping_city}
            </option>
            <option value="Kamphaeng Phet">Kamphaeng Phet </option>
            <option value="action-2">Action 2</option>
          </select>
        </div>
        <div className=" col-lg w-50">
        <div className={"col-lg paddingBottom5 font12"}>
          <span style={{color:'red'}}>*</span>
          <span>รหัสไปรษณีย์</span>
        </div>
          <select
            className="row me-5 rounded "
            style={dropdownStyle}
            name="shipping_pincode"
            value={OrderProductJson?.productDetail.shipping_pincode}
            defaultValue={OrderProductJson?.productDetail.shipping_pincode}
            onChange={handleInputChange}
          >
            <option value={OrderProductJson?.productDetail.shipping_pincode}>
              {OrderProductJson?.productDetail.shipping_pincode}
            </option>
            <option value="620000">620000</option>
            <option value="630000">620000</option>
          </select>
        </div>
      </div>

   {/*   <div className="d-flex col justify-content-center gap-3 mt-5 ">
        <ButtonComponent
          buttonJson={{
            label: "ยกเลิก",
            handleClick: handleCloseModalOne,
            style: "edit",
          }}
        ></ButtonComponent>
        <ButtonComponent
          buttonJson={{
            label: "บันทึก",
            handleClick: handleButtonClickaddress,
          }}
        ></ButtonComponent>
        </div>*/}

      <div className='d-flex col justify-content-center gap-1 mt-5' style={{marginTop:'34px',marginBottom:'51px'}}>
      <button
          onClick={handleCloseModalOne}
              style={{
              width:'136px',
              height:'40px',
              fontWeight:400,
              fontSize: "16px",
              padding: "8px 16px",
              cursor: "pointer",
              color: "#222226",
              border:"1px solid #222226",
              borderRadius:'4px',
              background: "#FFFFFF",
              }}
          >
          ยกเลิก
      </button>
      <span style={{paddingRight:'15px'}}>

      </span>
      <button
              onClick={handleButtonClickaddress}                            
              style={{
              width:'136px',
              height:'40px',
              fontWeight:400,
              fontSize: "16px",
              padding: "8px 16px",
              cursor: "pointer",
              color: "#FFFFFF",
              border:"1px solid #222226",
              borderRadius:'4px',
              background: "#222226",
              }}
          >
          บันทึก
      </button>
      </div>

      <div className={"row paddingTop10"} style={{ color: "red" }}>
        {}
      </div>
      <div className={"row paddingTop10"}>{}</div>
      </>
    </div>
  );


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 p-0"  style={{width: "240px"}} >
          <SideNavbar code="orderShipping" />
        </div>
        <div className="col-9" style={{width:'1080px'}}>
          <Row>
            <Col style={{ padding: "15px" }}>
              <div className="headerTitleDiv headerFont">รายละเอียดออเดอร์</div>
              <div className="headerActionButtonDiv"></div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="row paddingBottom20">
                <div className="col-lg textAlignLeft font12Light cursor-pointer"  onClick={handleBreadcrumbClick}>
                <span style={{ opacity:'0.5'}}>รายการออเดอร์และสถานะการจัดส่ง {"> "} </span>
                  รายละเอียดออเดอร์
                </div>
              </div>
              <div
                className="container-fluid"
                style={{ border: "1px solid #B3B5BD",borderRadius:'3px' }}
              >
                <div
                  className={"row textAlignLeft paddingTopBottom10"}
                  style={{ borderBottom: "1px solid #B3B5BD" }}
                >
                  <div className={"col-lg font16Light"} style={{marginLeft:'17px'}}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M21.75 5.25V19.5C21.7499 19.7599 21.6152 20.0012 21.394 20.1378C21.1729 20.2743 20.8968 20.2866 20.6644 20.1703L18 18.8381L15.3356 20.1703C15.1244 20.276 14.8756 20.276 14.6644 20.1703L12 18.8381L9.33563 20.1703C9.12436 20.276 8.87564 20.276 8.66437 20.1703L6 18.8381L3.33563 20.1703C3.1032 20.2866 2.82712 20.2743 2.60597 20.1378C2.38483 20.0012 2.25014 19.7599 2.25 19.5V5.25C2.25 4.42157 2.92157 3.75 3.75 3.75H20.25C21.0784 3.75 21.75 4.42157 21.75 5.25ZM7.5 9C7.08579 9 6.75 9.33579 6.75 9.75C6.75 10.1642 7.08579 10.5 7.5 10.5H16.5C16.9142 10.5 17.25 10.1642 17.25 9.75C17.25 9.33579 16.9142 9 16.5 9H7.5ZM16.5 13.5H7.5C7.08579 13.5 6.75 13.1642 6.75 12.75C6.75 12.3358 7.08579 12 7.5 12H16.5C16.9142 12 17.25 12.3358 17.25 12.75C17.25 13.1642 16.9142 13.5 16.5 13.5ZM3.75 5.25H20.25V18.2869L18.3356 17.3287C18.1244 17.223 17.8756 17.223 17.6644 17.3287L15 18.6619L12.3356 17.3287C12.1244 17.223 11.8756 17.223 11.6644 17.3287L9 18.6619L6.33562 17.3287C6.12436 17.223 5.87564 17.223 5.66437 17.3287L3.75 18.2869V5.25Z"
                        fill="#222226"
                      />
                    </svg>
                    <span style={{ paddingLeft: "10px",fontWeight:400}}>
                      รายการละเอียดการประมูล
                    </span>
                  </div>
                </div>
                <div
                  className={
                    "row font14Light paddingLeftRight10 paddingTopBottom20"
                  }
                >
                  <div className={"col-lg-2"}>
                    <div className={"row"}>
                      <div style={{width:'120px',height:'120px',border:'1px solid #B3B5BD',padding:'0px',borderRadius:'4px',marginBottom:'8px',marginLeft:'17px'}}>
                        <img
                          className={"p-0 m-0"}
                          style={{width: "100%", height: "100%" }}
                          src={

                            OrderProductJson?.productDetail
                              ?.auctionProductFileDetail?.[0]?.filepath 
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className={"col-lg-2"} style={{ textAlign: "left" }}>
                    <div className={"row"}>
                      <div className="appFontLight font12">รหัสสินค้า</div>
                      <div className="subHeaderFont paddingTop5">
                        {OrderProductJson?.productDetail.product_id}
                      </div>
                    </div>

                    <div className={"row paddingTop15"}>
                      <div className="appFontLight font12">ยอดประมูล</div>
                      <div className={"subHeaderFont paddingTop5"}>
                      
                        {
                         OrderProductJson?.productDetail?.last_auction_total
                        }
                      </div>
                    </div>
                  </div>
                  <div className={"col-lg-2"} style={{ textAlign: "left" }}>
                    <div className={"row"}>
                      <div className="appFontLight font12">Running No</div>
                      <div className={"subHeaderFont paddingTop5"}>
                        {OrderProductJson?.productDetail.runningno}
                      </div>
                    </div>

                    <div className={"row paddingTop15"}>
                      <div className="appFontLight font12">ยอดมัดจำ</div>
                      <div className={"subHeaderFont paddingTop5"}>
                        {OrderProductJson?.productDetail?.productdepositamount}
                      </div>
                    </div>
                  </div>
                  <div className={"col-lg "} style={{ textAlign: "left" }}>
                    <div className={"row"}>
                      <div className="appFontLight font12">ชื่อสินค้า</div>
                      <div className={"subHeaderFont paddingTop5"}>
                        {OrderProductJson?.productDetail?.productname}
                      </div>
                    </div>

                    <div className={"row paddingTop15"}>
                      <div className={"col"}>
                        <div className={"row"}>
                          <div className="appFontLight font12">
                            ยอดชำระหลังหักมัดจำ
                          </div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {TotalAmount}
                          </div>
                        </div>
                      </div>
                      <div className={"col"}>
                        <div className={"row"}>
                          <div className="appFontLight font12">ช่องทางการชำระเงิน</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {
                              OrderProductJson?.productDetail
                                ?.auctionPaymentDetail?.[0]?.payment_mode
                            }
                          </div>
                        </div>
                      </div>
                      <div className={"col"}>
                        <div className={"row"}>
                          <div className="appFontLight font12">Ref No.</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {
                              OrderProductJson?.productDetail
                                ?.auctionPaymentDetail?.[0]?.reference_id
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid col-lg-12 paddingTop15">
                <div className="row">
                  <div className="boxBorder col">
                    <div
                      className={"row textAlignLeft paddingTopBottom10"}
                      style={{ borderBottom: "1px solid #B3B5BD" }}
                    >
                      <div className={"col-lg font16Light"} style={{marginLeft:'17px'}}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M23.1956 10.9688L21.8831 7.6875C21.6553 7.11944 21.1039 6.74789 20.4919 6.75H17.25V6C17.25 5.58579 16.9142 5.25 16.5 5.25H2.25C1.42157 5.25 0.75 5.92157 0.75 6.75V17.25C0.75 18.0784 1.42157 18.75 2.25 18.75H3.84375C4.18363 20.0774 5.37974 21.0059 6.75 21.0059C8.12026 21.0059 9.31637 20.0774 9.65625 18.75H14.3438C14.6836 20.0774 15.8797 21.0059 17.25 21.0059C18.6203 21.0059 19.8164 20.0774 20.1562 18.75H21.75C22.5784 18.75 23.25 18.0784 23.25 17.25V11.25C23.2503 11.1536 23.2318 11.0581 23.1956 10.9688ZM17.25 8.25H20.4919L21.3919 10.5H17.25V8.25ZM2.25 6.75H15.75V12.75H2.25V6.75ZM6.75 19.5C5.92157 19.5 5.25 18.8284 5.25 18C5.25 17.1716 5.92157 16.5 6.75 16.5C7.57843 16.5 8.25 17.1716 8.25 18C8.25 18.8284 7.57843 19.5 6.75 19.5ZM14.3438 17.25H9.65625C9.31637 15.9226 8.12026 14.9941 6.75 14.9941C5.37974 14.9941 4.18363 15.9226 3.84375 17.25H2.25V14.25H15.75V15.4041C15.0534 15.8067 14.547 16.4715 14.3438 17.25ZM17.25 19.5C16.4216 19.5 15.75 18.8284 15.75 18C15.75 17.1716 16.4216 16.5 17.25 16.5C18.0784 16.5 18.75 17.1716 18.75 18C18.75 18.8284 18.0784 19.5 17.25 19.5ZM21.75 17.25H20.1562C19.8124 15.9261 18.6179 15.0013 17.25 15V12H21.75V17.25Z"
                            fill="#222226"
                          />
                        </svg>
                        <span style={{ paddingLeft: "10px" }}>การจัดส่ง</span>
                      </div>
                    </div>

                    <div className={"row textAlignLeft paddingTopBottom10"}>
                      <div className={"col-2"} style={{ textAlign: "left" }}>
                        <div className={"row paddingTop15"}>
                          <span
                            style={{  
                              backgroundColor: "#1313131A",
                              width:'fit-content',
                              height:'24px',
                              fontSize:'12px',
                              fontWeight:400 ,
                              marginLeft:'20px',
                              borderRadius:'3px'
                            }}
                          >
                            ที่อยู่จัดส่งตั้งต้น
                          </span>
                        </div>
                        <div className={"row paddingTop15"}>
                          <Button style={style} onClick={handleButtonOneClick}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              style={svgStyle}
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M14.2075 4.58572L11.4144 1.79322C11.2268 1.60563 10.9725 1.50024 10.7072 1.50024C10.4419 1.50024 10.1876 1.60563 10 1.79322L2.29314 9.50009C2.10475 9.68698 1.99916 9.94161 2.00001 10.207V13.0001C2.00001 13.5524 2.44773 14.0001 3.00001 14.0001H13.5C13.7762 14.0001 14 13.7762 14 13.5001C14 13.224 13.7762 13.0001 13.5 13.0001H7.20751L14.2075 6.00009C14.3951 5.81255 14.5005 5.55816 14.5005 5.29291C14.5005 5.02765 14.3951 4.77326 14.2075 4.58572ZM5.79313 13.0001H3.00001V10.207L8.50001 4.70697L11.2931 7.50009L5.79313 13.0001ZM9.20751 4.00009L12 6.79322L13.5 5.29322L10.7075 2.50009L9.20751 4.00009Z"
                                fill="#131313"
                              />
                            </svg>
                            แก้ไขที่อยู่
                          </Button>
                          <ModalComponent
                            modalJson={{
                              show: showModalOne,
                              handleClose: handleCloseModalOne,
                              title: "แก้ไขที่อยู่จัดส่ง",
                            }}
                          >
                            {modalBodyaddress}
                          </ModalComponent>
                        </div>
                      </div>

                      <div className={"col"} style={{ textAlign: "left" }}>
                        <div className={"row paddingTop15"}>
                          <div className="appFontLight font12">ชื่อ-นามสกุล</div>
                          <div className={"subHeaderFont paddingTop5 text-break"}>
                            {OrderProductJson?.productDetail?.shippingfirstnameandlastname}
                          </div>
                        </div>

                        <div className={"row paddingTop15"}>
                          <div className="appFontLight font12">ที่อยู่</div>
                          <div className={"subHeaderFont paddingTop5"}>
                          <div className="appFont16Regular" style={{ flexDirection: 'row', gap: '5px'}}>
                            <div>{OrderProductJson?.productDetail?.shipping_address1}{OrderProductJson?.productDetail?.shipping_address1 && ','}</div>
                            <div>{OrderProductJson?.productDetail?.shipping_address2}{OrderProductJson?.productDetail?.shipping_address2 && ','}</div>
                            <div>{OrderProductJson?.productDetail?.shipping_province}{OrderProductJson?.productDetail?.shipping_province && ','}</div>
                            <div>{OrderProductJson?.productDetail?.shipping_country}{OrderProductJson?.productDetail?.shipping_country && ','}</div>
                            <div>{OrderProductJson?.productDetail?.shipping_city}{OrderProductJson?.productDetail?.shipping_city && ''}</div>
                            <div>{OrderProductJson?.productDetail?.shipping_pincode}</div>
                          </div>
                          </div>
                        </div>
                      </div>

                      <div className={"col"} style={{ textAlign: "left" }}>
                        <div className={"row paddingTop15"}>
                          <div className="appFontLight font12">เบอร์ติดต่อ</div>
                          <div className={"subHeaderFont paddingTop5"}>
                            {OrderProductJson?.productDetail?.shippingcontactnumber}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={"row textAlignLeft paddingTopBottom10"}
                      style={{ borderTop: "3px solid #B3B5BD" }}
                    >
                      <div className={"row textAlignLeft paddingTopBottom10"}>
                        <div className={"col-2"} style={{ textAlign: "left" }}>
                          <div className={"row paddingTop15"}>
                            <Button
                              style={style}
                              onClick={handleButtonTwoClick}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                style={svgStyle}
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.2075 4.58572L11.4144 1.79322C11.2268 1.60563 10.9725 1.50024 10.7072 1.50024C10.4419 1.50024 10.1876 1.60563 10 1.79322L2.29314 9.50009C2.10475 9.68698 1.99916 9.94161 2.00001 10.207V13.0001C2.00001 13.5524 2.44773 14.0001 3.00001 14.0001H13.5C13.7762 14.0001 14 13.7762 14 13.5001C14 13.224 13.7762 13.0001 13.5 13.0001H7.20751L14.2075 6.00009C14.3951 5.81255 14.5005 5.55816 14.5005 5.29291C14.5005 5.02765 14.3951 4.77326 14.2075 4.58572ZM5.79313 13.0001H3.00001V10.207L8.50001 4.70697L11.2931 7.50009L5.79313 13.0001ZM9.20751 4.00009L12 6.79322L13.5 5.29322L10.7075 2.50009L9.20751 4.00009Z"
                                  fill="#131313"
                                />
                              </svg>
                              ข้อมูลขนส่ง
                            </Button>
                            <ModalComponent
                              modalJson={{
                                show: showModalTwo,
                                handleClose: handleCloseModalTwo,
                                title: "การจัดส่ง",
                              }}
                            >
                              {modalBodytracking}
                            </ModalComponent>
                          </div>
                        </div>

                        <div className={"col"} style={{ textAlign: "left" }}>
                          <div className={"row paddingTop15"}>
                            <div className="appFontLight font12">ชื่อขนส่ง</div>
                            <div className={"subHeaderFont paddingTop5"}>
                              {OrderProductJson?.productDetail?.transport_type}
                            </div>
                          </div>
                        </div>

                        <div className={"col"} style={{ textAlign: "left" }}>
                          <div className={"row paddingTop15"}>
                            <div className="appFontLight font12">เลข Tracking No.</div>
                            <div className={"subHeaderFont paddingTop5"}>
                              {OrderProductJson?.productDetail.tracking_number}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
