import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import SideNavbar from '../../../layouts/SideNavbar';
import {Row, Col} from 'react-bootstrap';
import ButtonComponent from '../../../components/Button';
import './../Style/index.css';
import {
    getBannerDetail
} from '../../Banner/Services/api';
import 
{
    getInputDate
} from '../../../Utils/DateUtils';

export default function Detail() {
    const navigate = useNavigate();
    const { bannerId } = useParams();
    const action = "highlight";

    const [bannerDetailJson, setBannerDetailJson] = useState({
        dataError : 'Error.. Try again',
        bannerJson : {
            filelist : []
        }
    });

    useEffect(() => {
        getBannerDetailWrapper(bannerId);
    }, [bannerId]);

    function getBannerDetailWrapper(bannerId)
    {
        var reqParam = {};
        reqParam.bannerId = bannerId;
        getBannerDetail(reqParam)
        .then((res) => 
        {   
            if(res.data.status === 'SUCCESS')
            {
                setBannerDetailJson({
                    ...bannerDetailJson,
                    bannerJson : res.data.response
                });       
            }
        })
        .catch((err) => 
        {
            console.log("Error");               
        });
    }

    function handleButtonClick()
    {
        navigate("/highlight/create/"+bannerId);
    }    

    function handleButtonClose()
    {

    } 
    const handleBreadcrumbClick = () => {
        // Go back to the previous page
        window.history.back();
      };

    return (
        <div className='container-fluid' style={{marginBottom : '20px'}}>
            <div className='row'>
                <div className="col-2 p-0"  style={{width: "240px"}} >
                    <SideNavbar code="bannerList" />
                </div> 
                <div className="col-9" style={{width:'1080px'}}>
                    <Row>
                        <Col lg={9} sm={12} style={{padding : '15px'}}>
                            <div className='headerTitleDiv headerFont'>
                            ข้อมูลวิดีโอหน้าแรก
                            </div>
                        </Col>
                        <Col lg={3} sm={12} style={{padding : '15px'}}>
                            <div className='headerActionButtonDiv'>
                                <button
                                style={{
                                    width:'136px',
                                    fontWeight:400,
                                    height: '40px',
                                    borderRadius:'4px', 
                                    background: '#222226', 
                                    color: '#FFFFFF', 
                                    fontSize: '16px', 
                                    border: 'none',
                                }}
                                onClick={handleButtonClick}
                            >
                            แก้ไขข้อมูล
                            </button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className='row paddingBottom20'>
                            <div className='textAlignLeft font12Light cursor-pointer' onClick={handleBreadcrumbClick}>
                            <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>รายการวิดีโอหน้าแรก {'>'} </span> รายละเอียด
                            </div>
                        </div>
                    </Row>
                    <Row className='paddingTop20 textAlignLeft'>
                        <Col lg={10} className={'contentBox marginAuto'}>
                            <div className={'row borderBottomForm'}>
                                <div className='col appFont16Regular padding10'>
                                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 0.75H18.25C19.0784 0.75 19.75 1.42157 19.75 2.25V12.75C19.75 13.5784 19.0784 14.25 18.25 14.25H1.75C0.921573 14.25 0.25 13.5784 0.25 12.75V2.25C0.25 1.42157 0.921573 0.75 1.75 0.75ZM13.4163 6.87562L8.91625 3.87562C8.68605 3.72204 8.38998 3.70769 8.14601 3.83831C7.90204 3.96892 7.74982 4.22327 7.75 4.5V10.5C7.74982 10.7767 7.90204 11.0311 8.14601 11.1617C8.38998 11.2923 8.68605 11.278 8.91625 11.1244L13.4163 8.12438C13.6252 7.98533 13.7507 7.75098 13.7507 7.5C13.7507 7.24902 13.6252 7.01467 13.4163 6.87562ZM9.25 9.09844V5.90625L11.6481 7.5L9.25 9.09844ZM18.25 12.75H1.75V2.25H18.25V12.75ZM19 17.25C19.4142 17.25 19.75 16.9142 19.75 16.5C19.75 16.0858 19.4142 15.75 19 15.75H1C0.585786 15.75 0.25 16.0858 0.25 16.5C0.25 16.9142 0.585786 17.25 1 17.25H19Z" fill="#222226"/>
                                    </svg>
                                    <span className='p-2'>ข้อมูลมีเดีย</span>
                                </div>
                            </div>
                            <div className={'row borderBottomForm'}>
                                <div className={'col padding10'}>
                                    <div style={{float: 'left',backgroundColor:'#EFF0F1',width:'66px',height:'32px',marginLeft:'29px',textAlign:'center',borderRadius:'4px'}}>
                                        <span style={{fontSize:'12px',marginTop:'6px',display:'block'}}>{bannerDetailJson.bannerJson.bannerstatus}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className='col-lg-12 appFontInputHeader font16 padding10'>
                                    <Row>
                                        <Col lg={6} sm={6}>
                                            <div style={{
                                                    width : '371px', 
                                                    backgroundColor : '#EFF0F1',
                                                    height:'206px', 
                                                    textAlign : 'center',
                                                    lineHeight : '180px',
                                                    justifyContent:'center',
                                                    borderRadius:'4px',
                                                    margin: '26px 0px 26px 21px'  
                                                }}>
                                                
                                                {(!bannerDetailJson.bannerJson.hasOwnProperty('filelist') || bannerDetailJson.bannerJson.filelist.length === 0) ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9525 7.03224C10.5641 6.59448 9.9704 6.39933 9.39807 6.52135C8.82573 6.64337 8.36321 7.06371 8.18717 7.6218C8.01113 8.17989 8.14876 8.78954 8.5475 9.21787L10.5077 11.3751H6.5C4.70507 11.3751 3.25 12.8301 3.25 14.6251V37.3751C3.25 39.17 4.70507 40.6251 6.5 40.6251H37.0988L41.0475 44.9679C41.4359 45.4056 42.0296 45.6008 42.6019 45.4788C43.1743 45.3567 43.6368 44.9364 43.8128 44.3783C43.9889 43.8202 43.8512 43.2106 43.4525 42.7822L10.9525 7.03224ZM49.4731 14.9074C49.9719 14.5756 50.6128 14.5452 51.1408 14.8282C51.6661 15.1089 51.9958 15.6544 52 16.2501V35.7501C52 36.6475 51.2725 37.3751 50.375 37.3751C50.0551 37.3784 49.7414 37.2872 49.4731 37.113L42.25 32.2969V32.4249C42.25 33.3224 41.5225 34.0499 40.625 34.0499C39.7275 34.0499 39 33.3224 39 32.4249V14.6251H22.9653C22.0679 14.6251 21.3403 13.8975 21.3403 13.0001C21.3403 12.1026 22.0679 11.3751 22.9653 11.3751H39C40.7949 11.3751 42.25 12.8301 42.25 14.6251V19.7133L49.4731 14.9074ZM42.25 28.3807L48.75 32.7133V19.2969L42.25 23.6194V28.3807ZM6.5 14.6251V37.3751H34.1453L13.4631 14.6251H6.5Z" fill="#222226"/>
                                                    </svg>
                                                ) : (
                                                    <div>
                                                       <div>
  {(() => {
    const desktopImages = bannerDetailJson.bannerJson.filelist.filter(file => file.filetype === 'image' && file.filecat === 'desktop');
    if (desktopImages.length > 0) {
      const firstDesktopImage = desktopImages[0];
      return (
        <img 
          key={0} 
          style={{width:'100%', height:'205px',objectFit:'cover'}} 
          className={'p-0 m-0'} 
          src={firstDesktopImage.filepath} 
          type={firstDesktopImage.contenttype}
        />
      );
    } else {
      // Handle case where there are no desktop images
      return <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9525 7.03224C10.5641 6.59448 9.9704 6.39933 9.39807 6.52135C8.82573 6.64337 8.36321 7.06371 8.18717 7.6218C8.01113 8.17989 8.14876 8.78954 8.5475 9.21787L10.5077 11.3751H6.5C4.70507 11.3751 3.25 12.8301 3.25 14.6251V37.3751C3.25 39.17 4.70507 40.6251 6.5 40.6251H37.0988L41.0475 44.9679C41.4359 45.4056 42.0296 45.6008 42.6019 45.4788C43.1743 45.3567 43.6368 44.9364 43.8128 44.3783C43.9889 43.8202 43.8512 43.2106 43.4525 42.7822L10.9525 7.03224ZM49.4731 14.9074C49.9719 14.5756 50.6128 14.5452 51.1408 14.8282C51.6661 15.1089 51.9958 15.6544 52 16.2501V35.7501C52 36.6475 51.2725 37.3751 50.375 37.3751C50.0551 37.3784 49.7414 37.2872 49.4731 37.113L42.25 32.2969V32.4249C42.25 33.3224 41.5225 34.0499 40.625 34.0499C39.7275 34.0499 39 33.3224 39 32.4249V14.6251H22.9653C22.0679 14.6251 21.3403 13.8975 21.3403 13.0001C21.3403 12.1026 22.0679 11.3751 22.9653 11.3751H39C40.7949 11.3751 42.25 12.8301 42.25 14.6251V19.7133L49.4731 14.9074ZM42.25 28.3807L48.75 32.7133V19.2969L42.25 23.6194V28.3807ZM6.5 14.6251V37.3751H34.1453L13.4631 14.6251H6.5Z" fill="#222226"/>
  </svg>;
    }
  })()}
</div>

                                                    </div>
                                                 )} 
                                             </div>
                                        </Col>
                                        <Col lg={6} sm={12} className='padding10 mt-3 font12'>
                                            <div className='appFontLight'>
                                                หัวข้อ
                                            </div>
                                            <div className='paddingTop10'>
                                                <span style={{fontWeight:400,fontSize:'16px'}}>{bannerDetailJson.bannerJson.bannertopic}</span>
                                            </div>
                                            <div className='appFontLight paddingTop20'>
                                                URL
                                            </div>
                                            <div className='paddingTop10'>
                                                <span style={{fontWeight:400,fontSize:'16px'}}>{bannerDetailJson.bannerJson.bannerurl}</span>
                                            </div>
                                            <div className='row paddingTop20'>
                                                <div className='col-lg-6'>
                                                    <div className='appFontLight'>
                                                        ผู้ทำรายการ
                                                    </div>
                                                    <div className='paddingTop10'>
                                                        <span style={{fontWeight:400,fontSize:'16px'}}> Admin</span>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className='appFontLight'>
                                                        วันที่อัพเดต
                                                    </div>
                                                    <div className='paddingTop10'>
                                                        <span style={{fontWeight:400,fontSize:'16px'}}>{getInputDate(bannerDetailJson.bannerJson.createdon)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div> 
            </div>
        </div>               
    );
}
