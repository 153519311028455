import React, { useState, useEffect } from "react";
import SideNavbar from "../../../layouts/SideNavbar";
import { Row, Col } from "react-bootstrap";
import { Form, InputGroup, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../components/Button";
import { getBiddingProductList } from "../Services/api";
import 
{
    getInputDate
} from '../../../Utils/DateUtils';
import apiInterceptor from "../../..//hooks/apiInterceptor";

export default function List() {
  const dropdownStyle = {
    border: "none",
    padding: "2px",
    background: "transparent",
    border: "0.5px solid #B3B5BD",
    width: "200px",
    height:'32px',
    fontSize:'12px',
    borderRadius:'4px',
    opacity:'0.5'
  };
  const paginatorPage = {
    background: "transparent",
    border: "none",
    font:"bold",
    margin: "10px",
  };
  const navigate = useNavigate();

  useEffect(() => {
    getBiddingProductListWrapper();
  }, []);

  const [biddingListJson, setBiddingListJson] = useState({
    productList: [],
  });

  function handleButtonClick() {
    navigate("/bidding/create");
  }

  function getBiddingProductListWrapper() {
    getBiddingProductList()
      .then((result) => {
        if (result.data.status === "SUCCESS") {
          setBiddingListJson({
            ...biddingListJson,
            productList: result.data.response,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function navigateToProductDetail(productId) {
    navigate("/bidding/detail/" + productId);
  }
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const perPage = 10; // Set the number of items per page

  // Pagination logic
  const totalFilteredItems = filteredData.length;
  const totalPages = Math.ceil(totalFilteredItems / perPage);

  // Update currentPage if it exceeds the total number of pages after filtering
  useEffect(() => {
    if (totalPages > 0 && currentPage >= totalPages) {
      setCurrentPage(totalPages - 1);
    }
  }, [totalPages]);

  // Update startIndex and endIndex based on currentPage
  const startIndex = currentPage * perPage;
  const endIndex = Math.min(startIndex + perPage, totalFilteredItems);
  const slicedCardData = filteredData.slice(startIndex, endIndex);

    // Function to filter by search term
    const filterBySearchTerm = () => {
      return biddingListJson.productList.filter(
        (item) =>
          (item.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            item.runningno?.toLowerCase().includes(searchTerm?.toLowerCase()))
      );
    };

    // Function to filter by selected status
    const filterBySelectedStatus = () => {
      return biddingListJson.productList.filter((item) => selectedStatus ? item.auctionstatus === selectedStatus : true);
    };

    // Function to apply filters and update filtered data
    const filteredResults = () => {
      const searchData = filterBySearchTerm();
      const statusData = filterBySelectedStatus();
      
      // Combine filtered results
      const filteredResults = searchTerm ? searchData : statusData;
      
      setFilteredData(filteredResults);
      setCurrentPage(0); // Reset page to first page after filtering
    };

    // Effect to apply filters when search term changes
    useEffect(() => {
      filteredResults();
    }, [searchTerm, biddingListJson]);

    // Effect to apply filters when selected status changes
    useEffect(() => {
      filteredResults();
    }, [selectedStatus, biddingListJson]);

    const handleSearchChange = (e) => {
      setSelectedStatus("")
      setSearchTerm(e.target.value);
    };
    const handleStatusChange = (e) => {
      setSearchTerm(""); 
      setSelectedStatus(e.target.value);
    };

  function statusupdate(status) {
    if (status === null || status === undefined) {
      return null;
    }
    const lowercaseStatus = status.toLowerCase();
    if (lowercaseStatus === "publish") {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{ backgroundColor: "#009E4B1A",textAlign:"center",fontSize:"12px",fontWeight:500,color:'#009E4B'}}
        >
          {status}
        </div>
      );
    } else if (lowercaseStatus === "unpublish") {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{ backgroundColor: "#F36D2733" ,textAlign:"center",fontSize:"12px",color:'#EC7405' }}
        >
          {status}
        </div>
      );
    } else {
      return (
        <div
          className={"subHeaderFont paddingTop5"}
          style={{ backgroundColor: "#EFF0F1",textAlign:"center",fontSize:"12px"  }}
        >
          {status}
        </div>
      );
    }
  }

  function AuctionUpdate(
    status, performanceStatus, participant_count
   ) {
     
     const lowercaseAuctionStatus = status ? status.toLowerCase() : null;
     if (performanceStatus !== 'DRAFT') {
       if (lowercaseAuctionStatus === 'started') {
         return (
           <div
             className={"subHeaderFont paddingTop5"}
             style={{ backgroundColor: "#FDD34333",height: '24px', borderRadius: '2px', fontSize: '12px', fontWeight: 600 ,textAlign:'center'}}
           >
              Auction Ongoing
           </div>
         );
       } else if (lowercaseAuctionStatus === 'closed') {
         return (
           <div
             className={"subHeaderFont paddingTop5"}
             style={{ backgroundColor: "#009E4B1A",height: '24px', borderRadius: '2px', fontSize: '12px', fontWeight: 600,textAlign:'center' }}
           >
             จบการประมูล
           </div>
         );
       } else  if (parseInt(participant_count) === 0 ){
         return (
           <div
             className={"subHeaderFont paddingTop5"}
             style={{ backgroundColor: "#FDD34333",height: '24px', borderRadius: '2px', fontSize: '12px', fontWeight: 600,textAlign:'center' }}
           >
             รอประมูล
           </div>
         );
       }
     } else {
       return (
         <div
           className={"subHeaderFont paddingTop5"}
           style={{ backgroundColor: "#EFF0F1",height: '24px', borderRadius: '2px', fontSize: '12px', fontWeight: 600,textAlign:'center' }}
         >
           DRAFT
         </div>
       );
     }
   }
  
   
   function AsyncUsernameFetcher({ userId }) {
    const [username, setUsername] = useState(null);
  
    useEffect(() => {
      async function fetchUsername() {
        try {
          const response = await apiInterceptor.get(`/user/userid/userName/${userId}`);
          setUsername(response.data);
        } catch (error) {
          console.error("Error fetching username:", error);
        }
      }
  
      fetchUsername();
  
      // Cleanup function to prevent memory leaks
      return () => {
        setUsername(null); // Reset username state
      };
    }, [userId]);
  
    return username;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 p-0"  style={{width: "240px"}} >
          <SideNavbar code="biddingList" />
        </div>
        <div className="col-9" style={{width:'1080px'}}>
          <Row>
            <Col style={{ padding: "15px" }}>
              <div className="headerTitleDiv headerFont">
                รายการสินค้าประมูล
              </div>
              <div className="headerActionButtonDiv">
                <button
                  style={{
                    float:'right',
                    width:'136px',
                    fontWeight:400,
                    height: '40px',
                    borderRadius:'4px', 
                    background: '#222226', 
                    color: '#FFFFFF', 
                    fontSize: '16px', 
                    border: 'none',
                    overflow:'hidden'
                  }}
                  onClick={handleButtonClick}
                >
                  เพิ่มสินค้าประมูล
                </button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col style={{ paddingLeft: "20px" }}>
              <div className=" row" style={{ paddingTop: "10px" }}>
                <InputGroup style={{ width: "532px",height:'32px',borderRadius:'4px' }}>
                  <InputGroup.Text
                    style={{ background: "#131313" }}
                    id="basic-addon1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M12.2029 10.7653L15.7811 14.3435C16.0729 14.6354 16.0738 15.1077 15.7785 15.403V15.403C15.4852 15.6963 15.0114 15.6979 14.7161 15.4026L11.1826 11.8691C9.99708 12.8963 8.45056 13.5178 6.75888 13.5178C3.02605 13.5178 0 10.4917 0 6.75888C0 3.02605 3.02605 0 6.75888 0C10.4917 0 13.5178 3.02605 13.5178 6.75888C13.5178 8.25856 13.0293 9.64417 12.2029 10.7653ZM12.0158 6.75888C12.0158 3.85557 9.66218 1.50197 6.75888 1.50197C3.85557 1.50197 1.50197 3.85557 1.50197 6.75888C1.50197 9.66218 3.85557 12.0158 6.75888 12.0158C9.66218 12.0158 12.0158 9.66218 12.0158 6.75888Z"
                        fill="#F3F4F8"
                      />
                    </svg>
                  </InputGroup.Text>
                  <Form.Control
                    style={{fontSize:'12px'}}
                    placeholder="ค้นหาด้วยรหัสสินค้าหรือชื่อสินค้า"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </InputGroup>
                <select
                  className="col-2 me-3 rounded "
                  style={dropdownStyle}
                  value={selectedStatus}
                  onChange={handleStatusChange}
                >
                  <option value="" disabled selected hidden>
                    เลือกดูตามสถานะ
                  </option>
                  <option value="">All Status </option>
                  <option value="CREATED">CREATED </option>
                  <option value="STARTED">STARTED</option>
                  <option value="CLOSED">CLOSED</option>
                </select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ paddingTop: "20px",overflowX: "auto",overflowY:'hidden',whiteSpace:'nowrap'}}>
                <Table>
                  <thead>
                    <tr style={{fontSize:'12px',fontWeight:500}}>
                      <th>ลำดับ</th>
                      <th>รหัสสินค้า</th>
                      <th>ชื่อสินค้า</th>
                      <th>สถานะการแสดง</th>
                      <th>สถานะการประมูล</th>
                      <th>ขั้นต่ำประมูล</th>
                      <th>ราคาเริ่มประมูล</th>
                      <th>ราคาปิดประมูล</th>
                      <th>วันที่เริ่มประมูล</th>
                      <th>วันที่ปิดประมูล</th>
                      <th>ผู้ทำรายการ</th>
                      <th>วันที่อัพเดต</th>
                    </tr>
                  </thead>
                  <tbody>
                    {slicedCardData?.length === 0 ? (
                      <tr>
                        <td colSpan="12" className="textAlignCenter">
                          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24" cy="24" r="24" fill="#F3F4F8"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 24C14.25 18.6152 18.6152 14.25 24 14.25C29.3824 14.2557 33.7443 18.6176 33.75 24C33.75 29.3848 29.3848 33.75 24 33.75C18.6152 33.75 14.25 29.3848 14.25 24ZM15.75 24C15.75 28.5563 19.4437 32.25 24 32.25C28.5542 32.2448 32.2448 28.5542 32.25 24C32.25 19.4437 28.5563 15.75 24 15.75C19.4437 15.75 15.75 19.4437 15.75 24ZM23.25 24.75V19.5C23.25 19.0858 23.5858 18.75 24 18.75C24.4142 18.75 24.75 19.0858 24.75 19.5V24.75C24.75 25.1642 24.4142 25.5 24 25.5C23.5858 25.5 23.25 25.1642 23.25 24.75ZM24 29.25C24.6213 29.25 25.125 28.7463 25.125 28.125C25.125 27.5037 24.6213 27 24 27C23.3787 27 22.875 27.5037 22.875 28.125C22.875 28.7463 23.3787 29.25 24 29.25Z" fill="#272A31"/>
                          </svg> 
                          
                          <div className="opacity-50 font12">ไม่มีข้อมูลในระบบ</div>
                        </td>
                      </tr>
                    ) : (
                      slicedCardData?.map((data, idx) => (
                        <tr key={idx} className="font14Light">
                          <td>{idx + 1}</td>
                          <td
                            className="pointer"
                            onClick={() => navigateToProductDetail(data.product_id)}
                          >
                            {data.product_id}
                          </td>
                          <td>{data.name}</td>
                          <td style={{ height: '24px', borderRadius: '4px', fontSize: '12px', fontWeight: 600 }}>{statusupdate(data.performancestatus)}</td>
                          <td style={{ height: '24px', borderRadius: '4px', fontSize: '12px', fontWeight: 600 }}>{AuctionUpdate(data.auctionstatus, data.performancestatus, data.participant_count)}</td>
                          <td>{Math.round(data.minbidamount)}</td>
                          <td>{Math.round(data.openingprice)}</td>
                          <td>{data.auctioncloseingprice}</td>
                          <td>{data.auctionstartdate?.split("T")[0]}</td>
                          <td>{data.auctionclosedate?.split("T")[0]}</td>
                          <td  className="font12"> {data.createdby ? (
                            <AsyncUsernameFetcher userId={data.createdby} />
                          ) : null}</td>
                          <td>{getInputDate(data.createdon)}</td>
                        </tr>
                      ))
                    )}
                    <></>
                  </tbody>
                </Table>
              </div>
            </Col>
            {filteredData.length > perPage && (
              <div className="flex text-center gap-8 mt-20">
                {Array.from(
                  { length: Math.ceil(filteredData.length / perPage) },
                  (_, index) => (
                    <button
                      key={index}
                      className={`paginator-page text-black ${currentPage === index && "active"}`}
                      onClick={() => setCurrentPage(index)}
                      style={{
                        ...paginatorPage,
                        fontWeight: currentPage === index ? "bold" : "normal"
                      }}
                    >
                      {index + 1}
                    </button>
                  )
                )}
              </div>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}
