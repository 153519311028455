import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideNavbar from "../../../layouts/SideNavbar";
import ButtonComponent from "../../../components/Button";
import { Form, InputGroup } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useParams } from "react-router";
import { Table } from "react-bootstrap";
import { getRefundDetail, updateReferenceNumber } from "../Services/api";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../../../components/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isAfter, isBefore, parse, startOfDay } from "date-fns";
import { toast } from "react-toastify";
import { XCircleFill } from "react-bootstrap-icons";
import { formatDate, getInputDate} from "../../../Utils/DateUtils";

export default function Detail() {
  const style = {
    background: "#FFFFFF",
    color: "#222226",
    fontSize: "14px",
    border: "none",
    height: "40px",
    paddingLeft: "25px",
    paddingRight: "25px",
    border: "1px solid #222226",
    width: "190px",
    marginLeft: "20px",
    marginBottom: "10px",
  };
  const dropdownStyle = {
    border: "none",
    padding: "9px",
    width: "100%",
  };

  const svgStyle = {
    marginRight: "30px", // Add some space between SVG and text
  };
  const navigate = useNavigate();

  const { productId } = useParams();

  const [refunDetailJson, setRefundDetailJson] = useState({
    productDetail: {
      fileList: [],
    },
  });
  const [showModalOne, setShowModalOne] = useState(false);
  const refundDetail = refunDetailJson?.productDetail?.[0];


  const handleButtonOneClick = () => {
    setShowModalOne(true);
  };

  const handleCloseModalOne = () => {
    setShowModalOne(false);
  };

  useEffect(() => {
    getRefundDetailWrapper(productId);
  }, [productId]);

  function getRefundDetailWrapper(id) {
    getRefundDetail(id)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setRefundDetailJson({
            ...refunDetailJson,
            productDetail: res.data.response,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleInputChange(event) {
    const key = event.target.name;
    let value = event.target.value;
    const type = event.target.type;
    if (type === "checkbox") {
      value = event.target.checked;
    }

    let userInfo = refunDetailJson.productDetail;
    userInfo[key] = value;

    setRefundDetailJson({
      ...refunDetailJson,
      productDetail: userInfo,
    });
  }

  const handleButtonClicktracking = () => {
    const reqParam = {
      reference_id: refunDetailJson?.productDetail?.reference_id,
      refund_date: refunDetailJson?.productDetail?.refund_date,
      id: refunDetailJson?.productDetail?.[0]?.id,
      modifiedby: refunDetailJson.productDetail.user_id,
    };

    updateReferenceNumber(reqParam)
      .then((result) => {
        handleCloseModalOne();
        toast.success("คุณทำรายการสำเร็จ", {
          position: "top-left",
          autoClose: 2000, // Set the duration in milliseconds (e.g., 5000 milliseconds = 5 seconds)
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          style: {
            width: "1030px",
            minHeight: "68px",
            backgroundColor: "#D4F9E7",
            color: "#009E4B",
            fontSize: "18px",
            fontWeight: 600,
            marginLeft: "250px",
          },
        });   
      })
      .catch((error) => {
        toast.error("เกิดข้อผิดพลาด ไม่สามารถทำรายการได้", {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          icon: <XCircleFill />,
          style: {
            width: "1030px",
            minHeight: "68px",
            backgroundColor: "#FEE5E4",
            color: "#EA3348",
            fontSize: "18px",
            fontWeight: 600,
            marginLeft: "250px",
          },
        });
        console.error(error.message);
      });
  };

  function handleDateChange(selectedDate) {
    let userInfo = refunDetailJson.productDetail;
    userInfo.refund_date = selectedDate;

    setRefundDetailJson({
      ...refunDetailJson,
      productDetail: userInfo,
    });
  }

  const CustomDatePickerInput = ({ value, onClick }) => (
    <div
      className="custom-datepicker-input"
      onClick={onClick}
      style={{ width: "200px", height: "40px", cursor: "pointer" }}
    >
      <svg
        className="calsvg me-2"
        width="30"
        height="30"
        viewBox="0 0 18 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.5 2H14.25V1.25C14.25 0.835786 13.9142 0.5 13.5 0.5C13.0858 0.5 12.75 0.835786 12.75 1.25V2H5.25V1.25C5.25 0.835786 4.91421 0.5 4.5 0.5C4.08579 0.5 3.75 0.835786 3.75 1.25V2H1.5C0.671573 2 0 2.67157 0 3.5V18.5C0 19.3284 0.671573 20 1.5 20H16.5C17.3284 20 18 19.3284 18 18.5V3.5C18 2.67157 17.3284 2 16.5 2ZM3.75 3.5V4.25C3.75 4.66421 4.08579 5 4.5 5C4.91421 5 5.25 4.66421 5.25 4.25V3.5H12.75V4.25C12.75 4.66421 13.0858 5 13.5 5C13.9142 5 14.25 4.66421 14.25 4.25V3.5H16.5V6.5H1.5V3.5H3.75ZM16.5 18.5H1.5V8H16.5V18.5Z"
          fill="#222226"
        />
      </svg>
      <span>เลือกวันที่ทำการคืนเงิน</span>
    </div>
  );
  let modalBodytracking = (
    <div className={"container-fluid"}>
      <>
      <div className={"row sidePadding0 paddingTop20"}>
        <div className={"col-lg paddingBottom5"}>
          <span style={{color:'red'}}>*</span>
          <span>วันที่</span>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-lg"}>
          <form
            className="form"
            style={{
              border: "1px solid #ddd",
              padding: "3px",
              borderRadius: "10px",
            }}
          >
            <DatePicker
              selected={""}
              onChange={handleDateChange}
              dateFormat="MM/dd/yyyy"
              isClearable
              showTimeSelect={false}
              customInput={<CustomDatePickerInput />}
            />
          </form>
        </div>
      </div>
      <div className={"row sidePadding0 paddingTop20"}>
        <div className={"col-lg paddingBottom5"}>
          <span style={{color:'red'}}>*</span>
          <span>Ref No.</span>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-lg"}>
          <InputGroup>
            <Form.Control
              placeholder=""
              aria-label="ระบุเลชอ้างอิง"
              aria-describedby="basic-addon1"
              name="reference_id"
              v
              defaultValue={refunDetailJson?.productDetail?.[0]?.reference_id}
              onChange={handleInputChange}
            />
          </InputGroup>
        </div>
      </div>
{/*      <div className="d-flex col justify-content-center gap-3 mt-5 ">
        <ButtonComponent
          buttonJson={{
            label: "ยกเลิก",
            handleClick: handleCloseModalOne,
            style: "edit",
          }}
        ></ButtonComponent>
        <ButtonComponent
          buttonJson={{
            label: "บันทึก",
            handleClick: handleButtonClicktracking,
          }}
        ></ButtonComponent>
        </div>*/}
      <div className='d-flex col justify-content-center gap-1 mt-5' style={{marginTop:'34px',marginBottom:'51px'}}>
      <button
          onClick={handleCloseModalOne}
              style={{
              width:'136px',
              height:'40px',
              fontWeight:400,
              fontSize: "16px",
              padding: "8px 16px",
              cursor: "pointer",
              color: "#222226",
              border:"1px solid #222226",
              borderRadius:'4px',
              background: "#FFFFFF",
              }}
          >
          ยกเลิก
      </button>
      <span style={{paddingRight:'15px'}}>

      </span>
      <button
              onClick={handleButtonClicktracking}                            
              style={{
              width:'136px',
              height:'40px',
              fontWeight:400,
              fontSize: "16px",
              padding: "8px 16px",
              cursor: "pointer",
              color: "#FFFFFF",
              border:"1px solid #222226",
              borderRadius:'4px',
              background: "#222226",
              }}
          >
          บันทึก
      </button>
      </div>

      <div className={"row paddingTop10"} style={{ color: "red" }}>
        {}
      </div>
      <div className={"row paddingTop10"}>{}</div>
      </>
    </div>
  );
  const handleBreadcrumbClick = () => {
    // Go back to the previous page
    window.history.back();
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 p-0"  style={{width: "240px"}} >
          <SideNavbar code="refundlist" />
        </div>
        <div className="col-9" style={{width:'1080px'}}>
          <Row>
            <Col style={{ padding: "15px" }}>
              <div className="headerTitleDiv headerFont">
                รายละเอียดการคืนเงิน
              </div>
              <div className="headerActionButtonDiv">
                {/* <ButtonComponent
                  buttonJson={{
                    label: "เปลี่ยนสถานะการคืนเงิน",
                    handleClick: handleButtonOneClick,
                  }}
                ></ButtonComponent> */}
                <ModalComponent
                  modalJson={{
                    show: showModalOne,
                    handleClose: handleCloseModalOne,
                    title: "ข้อมูลการคืนเงิน",
                  }}
                >
                  {modalBodytracking}
                </ModalComponent>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="row paddingBottom20">
                <div className="col-lg textAlignLeft font12Light cursor-pointer"  onClick={handleBreadcrumbClick}>
                  <span style={{ opacity:'0.5'}}>รายชื่อสมาชิกผู้ใช้งานเว็บไซต์ {">"} </span> รายละเอียดการคืนเงิน
                </div>
              </div>
              <div
                className="container-fluid"
                style={{ border: "1px solid #B3B5BD" }}
              >
                <div
                  className={"row textAlignLeft paddingTopBottom10"}
                  style={{ borderBottom: "1px solid #B3B5BD" }}
                >
                  <div className={"col-lg font16Light"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M21.75 5.25V19.5C21.7499 19.7599 21.6152 20.0012 21.394 20.1378C21.1729 20.2743 20.8968 20.2866 20.6644 20.1703L18 18.8381L15.3356 20.1703C15.1244 20.276 14.8756 20.276 14.6644 20.1703L12 18.8381L9.33563 20.1703C9.12436 20.276 8.87564 20.276 8.66437 20.1703L6 18.8381L3.33563 20.1703C3.1032 20.2866 2.82712 20.2743 2.60597 20.1378C2.38483 20.0012 2.25014 19.7599 2.25 19.5V5.25C2.25 4.42157 2.92157 3.75 3.75 3.75H20.25C21.0784 3.75 21.75 4.42157 21.75 5.25ZM7.5 9C7.08579 9 6.75 9.33579 6.75 9.75C6.75 10.1642 7.08579 10.5 7.5 10.5H16.5C16.9142 10.5 17.25 10.1642 17.25 9.75C17.25 9.33579 16.9142 9 16.5 9H7.5ZM16.5 13.5H7.5C7.08579 13.5 6.75 13.1642 6.75 12.75C6.75 12.3358 7.08579 12 7.5 12H16.5C16.9142 12 17.25 12.3358 17.25 12.75C17.25 13.1642 16.9142 13.5 16.5 13.5ZM3.75 5.25H20.25V18.2869L18.3356 17.3287C18.1244 17.223 17.8756 17.223 17.6644 17.3287L15 18.6619L12.3356 17.3287C12.1244 17.223 11.8756 17.223 11.6644 17.3287L9 18.6619L6.33562 17.3287C6.12436 17.223 5.87564 17.223 5.66437 17.3287L3.75 18.2869V5.25Z"
                        fill="#222226"
                      />
                    </svg>
                    <span style={{ paddingLeft: "10px" ,fontSize:'16px',fontWeight:400}}>
                      รายการละเอียดการประมูล
                    </span>
                  </div>
                </div>
                <div
                  className={
                    "row font14Light paddingLeftRight10 paddingTopBottom20"
                  }
                >
                  <div className={"col-lg-2"}>
                    <div className={"row"}>
                      <div style={{width:'120px',height:'120px',border:'1px solid #B3B5BD',padding:'0px',borderRadius:'4px',marginBottom:'8px',marginLeft:'17px'}}>
                        <img
                            className={"p-0 m-0"}
                            style={{ width: "100%", height: "100%" }}
                            src={refundDetail &&
                                refundDetail?.filepath
                            }
                          />
                      </div>
                    </div>
                  </div>

                  <div className={"col-lg-10"} style={{ textAlign: "left" }}>
                  <div className={"row"}>
                    <div className="col-2">
                      <div className="appFontLight font12">รหัสสินค้า</div>
                        <div className="subHeaderFont paddingTop5">
                          {
                            refundDetail?.product_id
                          }
                        </div>
                      </div>
                    <div className="col-3">
                      <div className="appFontLight font12">Running No</div>
                        <div className={"subHeaderFont paddingTop5"}>
                          {
                            refunDetailJson.productDetail[0]?.runningno
                          }
                        </div>
                      </div>
                    <div className="col-7">
                      <div className="appFontLight font12">ชื่อสินค้า</div>
                        <div className={"subHeaderFont paddingTop5"}>
                        {
                          refundDetail?.productname
                        }
                        </div>
                      </div>
                  </div>

                  <div className={"row paddingTop15"}>
                    <div className="col-2">
                      <div className="appFontLight font12">ยอดมัดจำ</div>
                        <div className={"subHeaderFont paddingTop5"}>
                          {
                            refundDetail?.productdepositamount
                          }
                        </div>
                    </div>
                    <div className="col-3">
                      <div className={"row"}>
                        <div className="appFontLight font12">ช่องทางการชำระเงิน</div>
                        <div className={"subHeaderFont paddingTop5"}>
                          {
                            refundDetail?.payment_mode
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                    <div className="appFontLight font12">Ref No.</div>
                      <div className={"subHeaderFont paddingTop5"}>
                      {
                        refundDetail?.reference_id
                      }
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="appFontLight font12">วันที่</div>
                        <div className={"subHeaderFont paddingTop5"}>
                          {refunDetailJson?.productDetail?.[0]?.refund_date ?(
                            getInputDate(refunDetailJson?.productDetail?.[0]?.refund_date)):
                            (refunDetailJson?.productDetail?.[0]?.refund_date)}
                        </div>
                      </div>
                    <div className="col-2">
                      <div className="appFontLight font12">การคืนเงิน</div>
                        <div
                          className={"subHeaderFont paddingTop5 font12 text-center"}
                          style={{ backgroundColor: "#009E4B1A",fontWeight:500,width:'fit-content',borderRadius:'3px',padding:'5px',color:"#009E4B" }}
                        >
                        {
                          refundDetail?.status === "SUCCESSFUL" ?
                              "การคืนเงิน" :
                              ""
                        }
                        </div>
                      </div>
                    </div>
                  </div>

                <div
                  className={"row textAlignLeft paddingTopBottom10"}
                  style={{ borderTop: "3px solid #B3B5BD"}}
                >
                  <div className="col-2">
                    <div className="appFontLight font12">สถานะ</div>
                    <div className={"subHeaderFont paddingTop5"} style={{fontSize:'12px',fontWeight:500,backgroundColor:'#EFF0F1',width:'fit-content',borderRadius:'3px',padding:'5px'}}
                    >
                      {
                        refundDetail?.status
                      }
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="appFontLight font12">
                      ชื่อ-นามสกุลผู้เข้าร่วมประมูล
                    </div>
                    <div className={"subHeaderFont paddingTop5"}>
                    {
                      (refundDetail?.name +
                      refundDetail?.lastname)
                    }                  
                    </div>                 
                  </div>
                  <div className="col-3">
                    <div className="appFontLight font12">เบอร์ติดต่อ</div>
                      <div className={"subHeaderFont paddingTop5"}>
                      {
                        refundDetail?.contactnumber
                      }
                      </div>
                  </div>
                  <div className="col-4">
                    <div className="appFontLight font12">อีเมล์</div>
                    <div className={"subHeaderFont paddingTop5"}>
                      {refundDetail?.email}
                    </div>
                  </div>

                </div>

                  <div className="row paddingTopBottom10">
                    <div className="col-2"></div>
                    <div className="col-3">
                      <div className="appFontLight font12">
                      ชื่อ-นามสกุลบัญชีคืนเงิน
                    </div>
                    <div className={"subHeaderFont paddingTop5"}>
                    {refundDetail &&(
                      refundDetail?.account_name
                      )
                    }
                    </div>                 
                    </div>
                    <div className="col-3">
                      <div className="appFontLight font12">ธนาคาร</div>
                        <div className={"subHeaderFont paddingTop5"}>
                          {refundDetail &&(
                            refundDetail?.bank_name
                          )
                          }
                        </div>
                      </div>
                    <div className="col-4">
                      <div className="appFontLight font12">เลขที่บัญชี</div>
                      <div className={"subHeaderFont paddingTop5"}>
                        {refundDetail &&(
                          refundDetail?.account_number
                        )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
